import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);
  const [recencyIndex, setRecencyIndex] = useState(0); // 0 = oldest, 1 = newest

  const processedData = groupData.processed;

  const userIds = Object.keys(processedData.averageReleaseYear).sort(
    (a, b) =>
      processedData.averageReleaseYear[a] - processedData.averageReleaseYear[b]
  );

  const clickUser = (index: number) => {
    if (index !== userIndex) {
      setUserIndex(index);
      setTrackIndex(0);
      setRecencyIndex(0);
    }
  };

  const clickTrack = (recency: number, index: number) => {
    if (index !== trackIndex || recency !== recencyIndex) {
      setTrackIndex(index);
      setRecencyIndex(recency);
    }
  };

  const getMonths = (date1: Date, date2: Date) => {
    // Ensure date1 is the earlier date
    if (date1 > date2) {
      [date1, date2] = [date2, date1];
    }

    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    // Calculate the difference in months
    let diff = (year2 - year1) * 12 + (month2 - month1);
    if (!diff) {
      // Calculate the difference in days
      const day1 = date1.getDate();
      const day2 = date2.getDate();
      const daysInMonth = new Date(year2, month2 + 1, 0).getDate();
      diff = (day2 - day1) / daysInMonth;
    }

    return Math.round(diff * 100) / 100;
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">
        Most nostalgic listeners
      </div>
      <div className="text-base md:text-lg text-center">
        Timeline shows your songs' average release years
      </div>

      <ol className="items-center flex flex-wrap">
        {userIds.map((userId, i) => {
          return (
            <li
              key={i}
              className={`grow cursor-pointer transition-all ${
                i === userIndex ? "text-primary" : ""
              }`}
              onClick={() => clickUser(i)}
            >
              <div className="text-sm md:text-base text-left sm:mb-1 -ml-1">
                {processedData.averageReleaseYear[userId]}
              </div>
              <div className="flex items-center">
                <div className="z-10 flex items-center justify-center w-6 h-6 rounded-full bg-background-200 shrink-0">
                  <svg
                    className="w-2.5 h-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <div
                  className={`flex w-full h-0.5 ${
                    i === userIndex ? "bg-primary/50" : "bg-background-200"
                  }`}
                ></div>
              </div>
              <div className="mt-3 px-1 md:px-5">
                <UserIndicator
                  url={groupData.userInfo[userId].profileImage}
                  name={groupData.userInfo[userId].name}
                  playing={i === userIndex}
                />
              </div>
            </li>
          );
        })}
      </ol>
      <div>
        Oldest songs from{" "}
        <span className="text-primary">
          {groupData.userInfo[userIds[userIndex]]?.name}
        </span>
        :
      </div>
      <div className="bg-background-100 p-2 rounded-lg grid grid-cols-2 md:grid-cols-3 gap-2">
        {processedData.oldestTracks[userIds[userIndex]].map((track, j) => {
          return (
            <div
              key={j}
              className={`flex items-center gap-x-1 cursor-pointer border-b-4 ${
                j === trackIndex && recencyIndex === 0
                  ? "text-primary border-primary animate-zoom"
                  : "border-transparent"
              }`}
              onClick={() => clickTrack(0, j)}
            >
              <div className="w-2/5">
                <AlbumCover track={track} />
              </div>
              <div className="w-3/5">
                <div className={`text-sm md:text-base font-bold line-clamp-2`}>
                  {track.name}
                </div>
                <div className={`text-xs md:text-sm font-thin line-clamp-1`}>
                  {track.artists[0].name}
                </div>
                <div
                  className={`text-xs md:text-sm text-primary font-bold line-clamp-1`}
                >
                  {track.album.release_date.split("-")[0]}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        Most recent releases from{" "}
        <span className="text-primary">
          {groupData.userInfo[userIds[userIndex]]?.name}
        </span>
        :
      </div>
      <div className="bg-background-100 p-2 rounded-lg grid grid-cols-2 md:grid-cols-3 gap-2">
        {processedData.newestTracks[userIds[userIndex]].map((track, j) => {
          return (
            <div
              key={j}
              className={`flex items-center gap-x-1 cursor-pointer border-b-4 ${
                j === trackIndex && recencyIndex === 1
                  ? "text-primary border-primary animate-zoom"
                  : "border-transparent"
              }`}
              onClick={() => clickTrack(1, j)}
            >
              <div className="w-2/5">
                <AlbumCover track={track} />
              </div>
              <div className="w-3/5">
                <div className={`text-sm md:text-base font-bold line-clamp-2`}>
                  {track.name}
                </div>
                <div className={`text-xs md:text-sm font-thin line-clamp-1`}>
                  {track.artists[0].name}
                </div>
                <div
                  className={`text-xs md:text-sm text-primary font-bold line-clamp-1`}
                >
                  {getMonths(new Date(), new Date(track.album.release_date))}{" "}
                  months ago
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <TrackPlayer
        track={
          recencyIndex === 1
            ? processedData.newestTracks[userIds[userIndex]][trackIndex]
            : processedData.oldestTracks[userIds[userIndex]][trackIndex]
        }
      />
    </div>
  );
}
