import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [trackIndex, setTrackIndex] = useState(0);

  const processedData = groupData.processed;

  const topTracks = Object.keys(processedData.topTracks).map((userId) => {
    return processedData.topTracks[userId][0];
  });

  const clickTrack = (index: number) => {
    if (index !== trackIndex) {
      setTrackIndex(index);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold animate-fadeInUp">
        Your top songs were
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
        {Object.keys(processedData.topTracks).map((userId, index) => {
          return (
            <div
              className={`w-full cursor-pointer border-b-4 animate-fadeInUp delay-entrance ${
                trackIndex === index
                  ? "text-primary border-primary"
                  : "border-transparent"
              }`}
              style={{
                animationDelay: `${0.5 + index * 0.1}s`,
              }}
              key={index}
              onClick={() => clickTrack(index)}
            >
              <UserIndicator
                url={groupData.userInfo[userId].profileImage}
                name={groupData.userInfo[userId].name}
                playing={trackIndex === index}
              />
              <div className="flex gap-x-1 items-center">
                <div className="w-2/5">
                  <AlbumCover track={topTracks[index]} />
                </div>
                <div className="w-3/5 text-sm md:text-base">
                  <div className="font-bold line-clamp-2">
                    {topTracks[index].name}
                  </div>
                  <div className="font-thin line-clamp-1">
                    {topTracks[index].artists[0].name}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <TrackPlayer track={topTracks[trackIndex]} />
    </div>
  );
}
