import React from "react";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../helpers/login";
import "../App.css";

const PageNotFound = () => {
  if (isLoggedIn()) {
    return <Navigate to="/Dashboard" />;
  } else {
    return <Navigate to="/" />;
  }
};

export default PageNotFound;
