import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, Navigate } from "react-router-dom";
import { isLoggedIn, logIn, logOut } from "../helpers/login";
import { fetchTokens } from "../helpers/tokens";
import { setCookie, getCookie } from "../helpers/helper";
import "../App.css";
import ErrorPage from "./Error";
import Loading from "../components/Loading";

const LoginRedirect = () => {
  const [error, setError] = useState<Error>();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // authenticate user login is successful / previously logged in
    async function authenticateLogin() {
      try {
        // check if user is already logged in
        if (isLoggedIn()) {
          navigate("/");
        }

        // check if authentication state generated before Spotify login matches the post-login response
        if (searchParams.get("state") != getCookie("spotify_auth_state")) {
          throw new Error("Invalid state");
        }

        // check if code is provided in post-login response
        const code = searchParams.get("code");
        if (!code) {
          throw new Error("No code provided");
        }

        // fetch and store Spotify API tokens
        await fetchTokens(code);

        logIn();
        navigate(`${getCookie("redirectPath")}`);
      } catch (err) {
        setError(err as Error);
      }
    }
    authenticateLogin();
  }, []);

  if (error) {
    return <ErrorPage error={error} />;
  } else if (isLoggedIn()) {
    return <Navigate to="/" />;
  } else {
    return <Loading />;
  }
};

export default LoginRedirect;
