import { checkAndRefreshTokens } from "./tokens";
import { ApiResponse } from "../../types/Data";

const APP_URL = process.env.REACT_APP_URL;
const API_URL = process.env.REACT_APP_URL + "/api";

/**
 * Get the URL to invite friends
 * @param userId - Spotify user ID
 * @returns - The URL to invite friends
 */
export async function getUrl(userId: string): Promise<string> {
  let url = `${APP_URL}/invite/${userId}`;
  await navigator.clipboard.writeText(url);
  return url;
}

/**
 * Get the friends of a user
 * @param userId - Spotify user ID
 * @returns - The friends of the user
 */
export async function getFriends(
  userId: string,
  suggestFriends?: boolean,
  getFollowers?: boolean
): Promise<ApiResponse.Friend[]> {
  const url = new URL(`${API_URL}/follow/${userId}`);
  if (suggestFriends) url.searchParams.set("suggestFriends", "true");
  if (getFollowers) url.searchParams.set("getFollowers", "true");

  const data: ApiResponse.Friend[] = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

/**
 * Add a friend to a user
 * @param userId - Spotify user ID
 * @param friendId - Spotify friend ID
 * @returns - The response from the server
 */
export async function addFriend(
  userId: string,
  friendId: string
): Promise<ApiResponse.SimpleResponse> {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  const data: ApiResponse.SimpleResponse = await fetch(
    `${API_URL}/follow/${userId}?friendId=${friendId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

/**
 * Remove a friend from a user
 * @param userId - Spotify user ID
 * @param friendId - Spotify friend ID
 * @returns - The response from the server
 */
export async function removeFriend(
  userId: string,
  friendId: string
): Promise<ApiResponse.SimpleResponse> {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  const data: ApiResponse.SimpleResponse = await fetch(
    `${API_URL}/follow/${userId}?friendId=${friendId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ friendId: friendId }),
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function searchUsers(
  userId: string,
  query: string,
  pageNumber?: number
) {
  const url = new URL(`${API_URL}/search/${userId}`);
  url.searchParams.set("query", query);
  if (pageNumber) url.searchParams.set("page", pageNumber.toString());

  const data: ApiResponse.SearchUsers = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}
