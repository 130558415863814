import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);

  const clickTrack = (index: number) => {
    if (index !== userIndex) {
      setUserIndex(index);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold animate-fadeInUp">
        Your top songs were
      </div>
      <div className="grid grid-cols-2 gap-x-1 max-w-lg">
        <div
          className="col-start-1 col-end-2 animate-fadeInRight cursor-pointer"
          onClick={() => clickTrack(0)}
        >
          <UserIndicator
            url={comparisonData.friend.profileImage}
            name={comparisonData.friend.name}
            playing={userIndex === 0 ? true : false}
          />
          <div
            className={`w-full border-b-8 ${
              userIndex === 0 ? "border-primary" : "border-transparent"
            }`}
          >
            <AlbumCover track={comparisonData.friend.topTracks[0]} />
          </div>
          <div
            className="text-xl md:text-2xl font-bold mb-1 animate-fadeInRight delay-entrance"
            style={{ animationDelay: "0.5s" }}
          >
            {comparisonData.friend.topTracks[0].name}
          </div>
          <div
            className="text-lg md:text-xl animate-fadeInRight delay-entrance"
            style={{ animationDelay: "1s" }}
          >
            {comparisonData.friend.topTracks[0].artists[0].name}
          </div>
        </div>
        <div
          className="col-start-2 col-end-3 animate-fadeInLeft cursor-pointer"
          onClick={() => clickTrack(1)}
        >
          <UserIndicator
            url={comparisonData.me.profileImage}
            name={"You"}
            playing={userIndex === 1 ? true : false}
          />
          <div
            className={`w-full border-b-8 ${
              userIndex === 1 ? "border-primary" : "border-transparent"
            }`}
          >
            <AlbumCover track={comparisonData.me.topTracks[0]} />
          </div>
          <div
            className="text-xl md:text-2xl font-bold animate-fadeInLeft delay-entrance"
            style={{ animationDelay: "0.5s" }}
          >
            {comparisonData.me.topTracks[0].name}
          </div>
          <div
            className="text-lg md:text-xl animate-fadeInLeft delay-entrance"
            style={{ animationDelay: "1s" }}
          >
            {comparisonData.me.topTracks[0].artists[0].name}
          </div>
        </div>
      </div>
      <TrackPlayer
        track={
          userIndex === 0
            ? comparisonData.friend.topTracks[0]
            : comparisonData.me.topTracks[0]
        }
      />
    </div>
  );
}
