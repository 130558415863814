import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import ProfileImage from "../ProfileImage";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);
  const [yearIndex, setYearIndex] = useState(0);

  const processedData = groupData.processed;

  const years = [
    ...new Set(
      Object.values(processedData.timeline)
        .map((timelineObj) => Object.keys(timelineObj))
        .flat()
    ),
  ].sort();

  const clickYear = (year: number) => {
    if (yearIndex !== year) {
      setYearIndex(year);
      setUserIndex(0);
    }
  };

  const clickUser = (user: number) => {
    if (userIndex !== user) {
      setUserIndex(user);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">
        Timeline of your group's music
      </div>
      <div className="w-full max-w-lg flex justify-evenly gap-x-2 mt-2">
        <ol className="relative border-l border-white">
          {years.map((year, i) => {
            return (
              <li key={i} onClick={() => clickYear(i)}>
                <div
                  className={`absolute rounded-full ${
                    yearIndex === i
                      ? "bg-primary w-3 h-3 mt-3 -left-1.5"
                      : "bg-white w-2 h-2 mt-3 -left-1"
                  }`}
                ></div>
                <div
                  className={`cursor-pointer animate-expand my-2 ${
                    yearIndex === i
                      ? "text-primary"
                      : "text-white/75 md:hover:text-white"
                  }`}
                >
                  <div
                    className={`transition-all ${
                      yearIndex === i
                        ? "text-2xl md:text-3xl font-bold"
                        : "text-lg md:text-xl lg:text-2xl"
                    }`}
                  >
                    {year}s
                  </div>
                  {Object.keys(processedData.favouriteDecade).includes(
                    year
                  ) && (
                    <div className="flex flex-wrap items-center justify-center gap-x-1 animate-fadeInUp">
                      <div>Favourite decade of: </div>
                      <div className="flex items-center justify-center gap-x-1 flex-wrap">
                        {processedData.favouriteDecade[year].map((userId) => (
                          <UserIndicator
                            url={groupData.userInfo[userId].profileImage}
                            name={groupData.userInfo[userId].name}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`w-full grid grid-cols-2 gap-1 transition-all ${
                    yearIndex === i
                      ? "opacity-1 h-auto pointer-events-auto"
                      : "opacity-0 h-0 pointer-events-none"
                  }`}
                >
                  {Object.keys(processedData.timeline)
                    .filter(
                      (userId) =>
                        processedData.timeline[userId][year]?.length > 0
                    )
                    .map((userId, j) => {
                      const track = processedData.timeline[userId][year]?.at(0);
                      if (track) {
                        return (
                          <div>
                            <UserIndicator
                              url={groupData.userInfo[userId].profileImage}
                              name={groupData.userInfo[userId].name}
                              playing={i === yearIndex && j === userIndex}
                            />
                            <div
                              key={j}
                              className={`flex items-center gap-x-1 cursor-pointer p-2 rounded-lg ${
                                i === yearIndex && j === userIndex
                                  ? "bg-background-200 md:animate-zoom"
                                  : "md:hover:bg-background-200"
                              }`}
                              onClick={() => clickUser(j)}
                            >
                              <div className="w-2/5">
                                <AlbumCover track={track} />
                              </div>
                              <div className="w-3/5">
                                <div
                                  className={`text-sm md:text-base font-bold line-clamp-2`}
                                >
                                  {track.name}
                                </div>
                                <div
                                  className={`text-xs md:text-sm font-thin line-clamp-1`}
                                >
                                  {track.artists[0].name}
                                </div>
                                <div
                                  className={`text-xs md:text-sm text-primary font-bold line-clamp-1`}
                                >
                                  {track.album.release_date.split("-")[0]}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                </div>
              </li>
            );
          })}
        </ol>
      </div>
      <TrackPlayer
        track={processedData.timeline[
          Object.keys(processedData.timeline)?.filter(
            (userId) =>
              processedData.timeline[userId][years[yearIndex]]?.length > 0
          )[userIndex]
        ][years[yearIndex]]?.at(0)}
      />
    </div>
  );
}
