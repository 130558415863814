import {
  generateRandomString,
  getCookie,
  setCookie,
  clearAllCookies,
} from "./helper";
import * as Sentry from "@sentry/react";

const URL = process.env.REACT_APP_URL;

export function logInSpotify(redirectPath: string = "/dashboard") {
  setCookie("redirectPath", redirectPath);

  const state = generateRandomString(16);
  setCookie("spotify_auth_state", state);
  const scope =
    "user-read-private user-read-email user-read-recently-played user-top-read user-follow-read user-library-read playlist-read-private playlist-modify-public playlist-modify-private";
  const redirectUri = `${URL}/redirect`;

  const urlParams = new URLSearchParams();
  urlParams.append("response_type", "code");
  urlParams.append("client_id", process.env.REACT_APP_SPOTIFY_CLIENT_ID || "");
  urlParams.append("scope", scope);
  urlParams.append("redirect_uri", redirectUri);
  urlParams.append("state", state);

  window.open(
    "https://accounts.spotify.com/authorize?" + urlParams.toString(),
    "_self"
  );
}

/**
 * Check if user is logged in
 * @param {boolean} logOutExpired - log out if tokens have expired
 * @returns {boolean} - true if logged in, false otherwise
 */
export function isLoggedIn(logOutExpired: boolean = false): boolean {
  if (logOutExpired) {
    // get time the tokens expire
    let expiresAt = new Date(getCookie("expires_at")).getTime();
    let now = Date.now();

    // log out if tokens have expired
    if (now >= expiresAt) {
      logOut();
    }
  }

  const isUserLoggedIn =
    getCookie("logged_in") === "true" &&
    getCookie("access_token") &&
    getCookie("refresh_token") &&
    getCookie("expires_at") &&
    getCookie("expires_at") != "Invalid Date" &&
    getCookie("userId");

  if (isUserLoggedIn) {
    Sentry.setUser({ id: getCookie("userId") });
    return true;
  } else {
    return false;
  }
}

export function logIn() {
  // console.log("User logged in");
  setCookie("logged_in", String(true));
}

export function logOut() {
  localStorage.clear();
  clearAllCookies();
  setCookie("logged_in", String(false));
  window.location.href = "/";
}
