import React, { useState, useEffect } from "react";
import { logInSpotify } from "../helpers/login";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../helpers/login";
import "../App.css";
import spotifyLogo from "../images/spotifyLogoWhite.png";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";
import config from "../config";

const Login = () => {
  const isMobileScreen = useCheckMobileScreen();
  const [landingPageData, setLandingPageData] = useState(null);

  if (isLoggedIn()) {
    return <Navigate to="/Dashboard" />;
  } else {
    return (
      <div className="h-screen">
        <div className="flex flex-wrap items-center justify-center w-full h-full mx-auto">
          <div className="min-w-max">
            <div className="text-xl sm:text-2xl font-bold mx-auto">
              <div>Compile your Spotify data</div>
              {/* <div>
                <span className="text-primary">Compile</span> your Spotify data
              </div>
              <div>
                <span className="text-primary">Compare</span> your music taste
              </div> */}
            </div>
            <button
              className="bg-primary text-white text-sm md:text-base rounded-full p-4 flex items-center justify-center gap-x-2 mx-auto my-2"
              onClick={() => {
                logInSpotify("/dashboard");
              }}
            >
              <img src={spotifyLogo} className="h-8"></img>
              Log in with Spotify
            </button>
          </div>
        </div>
        <div className="relative right-36"></div>
      </div>
    );
  }
};

export default Login;
