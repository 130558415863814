import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);
  const [genreIndex, setGenreIndex] = useState(0);
  const [track, setTrack] = useState<SpotifyApi.TrackObjectFull>();

  const processedData = groupData.processed;

  const clickUser = (index: number) => {
    setUserIndex(index);
    setGenreIndex((prev) => {
      return index === userIndex
        ? (prev + 1) % Object.values(processedData.topGenres)[index].length
        : 0;
    });
  };

  useEffect(() => {
    setTrack((prevTrack) => {
      for (let track of Object.values(
        Object.values(processedData.genreTracks)[userIndex]
      )[genreIndex]) {
        if (track.id != prevTrack?.id) {
          return track;
        }
      }
      return prevTrack;
    });
  }, [userIndex, genreIndex]);

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">
        Your favourite genres were
      </div>
      <div className="flex flex-wrap justify-center gap-2">
        {Object.keys(processedData.topGenres).map((userId, i) => (
          <div
            key={i}
            className={`text-lg md:text-xl font-bold flex gap-x-4 cursor-pointer p-2 rounded-lg ${
              i == userIndex
                ? "text-primary bg-background-100"
                : "hover:bg-background-100"
            }`}
            onClick={() => clickUser(i)}
          >
            <div className="w-[100px] min-w-[100px]">
              <ProfileImage
                url={groupData.userInfo[userId].profileImage}
                round={true}
              />
              <div className="line-clamp-2">
                {groupData.userInfo[userId].name}
              </div>
            </div>
            <div className="my-auto text-left">
              {processedData.topGenres[userId].slice(0, 5).map((genre, j) => {
                return (
                  <div
                    key={j}
                    className={`line-clamp-2 text-base md:text-2xl animate-fadeInUp delay-entrance select-none ${
                      genreIndex === j && userIndex === i
                        ? "text-primary"
                        : j === 0
                        ? "text-white"
                        : j === 1
                        ? "text-white/80"
                        : j === 2
                        ? "text-white/60"
                        : j === 3
                        ? "text-white/40"
                        : "text-white/20"
                    }`}
                    style={{
                      animationDelay: `${0.5 * i + j * 0.1}s`,
                    }}
                  >
                    <span>#{j + 1}. </span>
                    <span
                      className={`${
                        j === 0
                          ? "text-3xl"
                          : j === 1
                          ? "text-xl"
                          : j === 2
                          ? "text-base"
                          : j === 3
                          ? "text-xs"
                          : "text-xs"
                      }`}
                    >
                      {genre}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <TrackPlayer track={track} />
    </div>
  );
}
