import React, { ReactNode, MouseEvent } from "react";
import "../App.css";
import { X } from "lucide-react";

export default function Modal({
  open,
  onClose,
  hideCloseButton = false,
  children,
}: {
  open: boolean;
  onClose: () => void;
  hideCloseButton?: boolean;
  children: ReactNode;
}) {
  if (open) {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 animate-fadeInUp"
        onClick={onClose}
      >
        <div
          className="bg-background-100 rounded-lg p-4 md:p-8 m-4 md:m-8 max-w-full max-h-full text-center overflow-auto relative"
          onClick={(e: MouseEvent) => e.stopPropagation()}
        >
          <div
            className="absolute right-2 top-2 w-5 cursor-pointer"
            onClick={onClose}
          >
            <X />
          </div>
          {children}
          {!hideCloseButton ? (
            <button
              className={`text-sm md:text-base font-bold bg-alert text-white p-2 rounded-lg w-1/2 min-w-max mt-2`}
              onClick={onClose}
            >
              Close
            </button>
          ) : null}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
