import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import SoundWave from "../SoundWave";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  // let timeRangeText = "in the past 6 months";
  let timeRangeText = "this year so far";
  if (searchParams.get("time_range") === "shortTerm") {
    timeRangeText = "in the last month";
  } else if (searchParams.get("time_range") === "longTerm") {
    timeRangeText = "since you started using Spotify";
  }

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="opacity-0 animate-fadeInUpOut">
        <div className="text-xl md:text-2xl font-bold">
          Hi {summaryData.name}!
        </div>
      </div>
      <div
        className="opacity-0 animate-fadeInUpOut"
        style={{ animationDelay: "1s" }}
      >
        <div className="text-xl md:text-2xl font-bold">
          Here's a summary of the music you've listened to{" "}
          <span className="font-bold text-primary">{timeRangeText}</span>
        </div>
      </div>
      <div
        className={`animate-fadeIn delay-entrance`}
        style={{ animationDelay: "5s", animationDuration: "5s" }}
      >
        <div className="text-xl md:text-2xl font-bold">
          Turn up your volume for the best experience!
        </div>
        <SoundWave />
      </div>
      <div
        className={`animate-fadeIn delay-entrance`}
        style={{ animationDelay: "6s", animationDuration: "5s" }}
      >
        <div className="text-xl md:text-2xl font-bold">
          Navigate with the buttons below
        </div>
      </div>
      <TrackPlayer track={summaryData.processed.randomTracks[0]} />
    </div>
  );
}
