import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [userIndex, setUserIndex] = useState<number>();
  const processedData = groupData.processed;

  const maxScore = Math.max(
    ...Object.keys(processedData.similarity).map(
      (userId) => processedData.similarity[userId].score
    )
  );

  const clickUser = (index: number) => {
    // if (userIndex !== index) {
    //   setUserIndex(index);
    // }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">
        Your music tastes are most similar to:
      </div>
      <div>
        {Object.keys(processedData.similarity)
          .sort(
            (a, b) =>
              processedData.similarity[b].score -
              processedData.similarity[a].score
          )
          .map((userId, i) => {
            const similarity = processedData.similarity[userId];
            return (
              <div
                key={i}
                className="cursor-pointer mb-4 animate-fadeInUp delay-entrance"
                style={{ animationDelay: `${i * 0.1}s` }}
                onClick={() => clickUser(i)}
              >
                <div
                  className={`flex justify-center items-center gap-2 ${
                    userIndex === i ? "text-primary" : ""
                  }`}
                >
                  <div className="text-3xl font-bold">#{i + 1}</div>
                  <div className="w-20">
                    <ProfileImage
                      url={groupData.userInfo[userId].profileImage}
                    />
                    <div>{groupData.userInfo[userId].name}</div>
                  </div>
                  <div className="grow">
                    <span className="text-3xl font-bold">
                      {`${Math.round((similarity.score / maxScore) * 100)}%`}
                    </span>
                    <div>relative similarity</div>
                  </div>
                </div>
                <div>
                  You have{" "}
                  <span className="font-boldm text-primary">
                    {similarity.numArtists}{" "}
                  </span>
                  artist(s),{" "}
                  <span className="font-boldm text-primary">
                    {similarity.numTracks}
                  </span>{" "}
                  song(s), and{" "}
                  <span className="font-boldm text-primary">
                    {similarity.numGenres}
                  </span>{" "}
                  genre(s) in common
                </div>
              </div>
            );
          })}
      </div>
      <TrackPlayer track={groupData.processed.randomTracks[1]} />
    </div>
  );
}
