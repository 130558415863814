import { SpotifyApi } from "../../types/SpotifyApi";

/**
 * Generate a random string
 * @param {number} length - length of string
 * @returns {string} text - random string
 */
export function generateRandomString(length: number): string {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

/**
 * Set a cookie
 * @param {string} name
 * @param {string} value
 * @returns {void}
 */
export function setCookie(name: string, value: string): void {
  // localStorage.setItem(name, value);
  document.cookie = `${name}=${value}; path=/;`;
}

/**
 * Get a cookie
 * @param {string} name
 * @returns {string} value
 */
export function getCookie(name: string): string {
  // return localStorage.getItem(name);
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));
  if (cookie) {
    return cookie.split("=")[1];
  } else {
    return "";
  }
}

/**
 * Clear cookies
 * @param {string} name
 * @returns {void}
 */
export function clearCookie(name: string): void {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

/**
 * Clear all cookies
 * @returns {void}
 */
export function clearAllCookies(): void {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

/**
 * Get hash params from URL
 * @returns {object} hashParams
 */
export function getHashParams(): { [key: string]: string } {
  let hashParams: { [key: string]: string } = {};
  let e,
    r = /([^&;=]+)=?([^&;]*)/g,
    q = window.location.hash.substring(1);
  while ((e = r.exec(q))) {
    hashParams[e[1]] = decodeURIComponent(e[2]);
  }
  return hashParams;
}

/**
 * Get the average of an array of numbers
 * @param {array} arr - array of numbers
 * @returns {number} average
 */
export function getAverage(arr: Array<number>): number {
  const sum = arr.reduce((a, b) => a + b, 0);
  const average = sum / arr.length || 0;
  return average;
}

/**
 * Get the median of an array of numbers
 * @param {array} arr - array of numbers
 * @returns {number} median
 */
export function getMedian(arr: Array<number>): number {
  arr.sort((a, b) => a - b);
  const median =
    arr.length % 2 === 0 ? arr[arr.length / 2] : arr[(arr.length - 1) / 2];
  return median;
}

/**
 * Get the top genres from an array of artist objects
 * @param {array} topArtists - array of artist objects
 * @returns {array} topGenres - array of top genres
 */
export function getTopGenres(
  topArtists: Array<SpotifyApi.ArtistObjectFull>
): string[] {
  let genreScores: { [genre: string]: number } = {};
  for (let i = 0; i < topArtists.length; i++) {
    topArtists[i].genres.forEach((genre) => {
      if (genre !== "pop") {
        if (!genreScores[genre]) {
          genreScores[genre] = 0;
        }
        // genreScores[genre] += (1 / (i + 1)) * 100;
        genreScores[genre] += 1;
      }
    });
  }
  genreScores = Object.fromEntries(
    Object.entries(genreScores).sort(([, a], [, b]) => b - a)
  );
  let topGenres = Object.keys(genreScores).filter(
    (genre) => genreScores[genre] > 1
  );
  return topGenres;
}
