import { checkAndRefreshTokens } from "./tokens";

const API_URL = process.env.REACT_APP_URL + "/api";

export async function generateOrAddToPlaylist(
  trackUri: string,
  userId?: string
) {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  return fetch(`${API_URL}/playlist/${userId ? userId : ""}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ trackUri }),
  });
}
