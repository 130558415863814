import React from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

export default function NavigationButtons({
  index,
  setIndex,
  numPages,
  exitPath,
}: {
  index: number;
  setIndex: (index: number) => void;
  numPages: number;
  exitPath?: string;
}) {
  const navigate = useNavigate();
  return (
    <div className="flex gap-x-2 mt-2 mb-12 color-white text-3xl md:text-4xl z-10">
      {index == 0 ? (
        <div
          className="transition-all w-20 h-20 border border-white text-white md:hover:bg-white/80 md:hover:text-background-100 md:active:bg-white/80 md:active:text-background-100 rounded-full flex justify-center items-center cursor-pointer"
          onClick={() => {
            if (exitPath) {
              navigate(exitPath);
            } else {
              navigate("/dashboard");
            }
          }}
        >
          <div>&#10005;</div>
        </div>
      ) : (
        <div
          className="transition-all w-20 h-20 border border-white text-white md:hover:bg-white/80 md:hover:text-background-100 md:active:bg-white/80 md:active:text-background-100 rounded-full flex justify-center items-center cursor-pointer"
          onClick={() => {
            setIndex(index - 1);
          }}
        >
          <div>&#8592;</div>
        </div>
      )}
      {index == numPages - 1 ? (
        <div
          className="transition-all w-20 h-20 border border-white text-white md:hover:bg-white/80 md:hover:text-background-100 md:active:bg-white/80 md:active:text-background-100 rounded-full flex justify-center items-center cursor-pointer"
          onClick={() => {
            if (exitPath) {
              navigate(exitPath);
            } else {
              navigate("/dashboard");
            }
          }}
        >
          <div>&#10005;</div>
        </div>
      ) : (
        <div
          className="transition-all w-20 h-20 border border-white text-white md:hover:bg-white/80 md:hover:text-background-100 md:active:bg-white/80 md:active:text-background-100 rounded-full flex justify-center items-center cursor-pointer"
          onClick={() => {
            setIndex(index + 1);
          }}
        >
          <div>&#8594;</div>
        </div>
      )}
    </div>
  );
}
