import { checkAndRefreshTokens } from "./tokens";
import { ApiResponse } from "../../types/Data";
import { SpotifyApi } from "../../types/SpotifyApi";

const API_URL = process.env.REACT_APP_URL + "/api";

/**
 * Get Spotify user data from database / Spotify API
 * @param {string} userId - Spotify user ID
 * @param {boolean} updateUser - Whether to update user data
 * @returns {Promise<ApiResponse.User>} data - user data
 */
export async function getUserData(
  userId: string,
  updateUser: boolean = true
): Promise<ApiResponse.User> {
  let accessToken: string | undefined;
  if (updateUser) {
    const tokens = await checkAndRefreshTokens();
    accessToken = tokens.access_token;
  }

  // Check database for user
  const data: ApiResponse.User = await fetch(`${API_URL}/user/${userId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

/**
 * Get Spotify user data from database / Spotify API
 * @param {string} userId - Spotify user ID
 * @param {string} timeRange - Time range for data
 * @returns {Promise<ApiResponse.SummaryPageData>} data - user data
 */
export async function getSpotifyData(
  userId: string,
  timeRange: string = "mediumTerm",
  friendId?: string
): Promise<ApiResponse.SummaryPageData> {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  const url = new URL(`${API_URL}/data/${userId}`);
  url.searchParams.append("timeRange", timeRange);
  if (friendId) {
    url.searchParams.append("friendId", friendId);
  }

  const data: ApiResponse.SummaryPageData = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function getProfileData(
  userId: string
): Promise<ApiResponse.ProfilePageData> {
  const data: ApiResponse.ProfilePageData = await fetch(
    `${API_URL}/profile/${userId}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

/**
 * Get Spotify song recommendations
 * @param {array} artistIds - Array of Spotify artist IDs
 * @param {array} genres - Array of Spotify genres
 * @param {array} trackIds - Array of Spotify track IDs
 * @param {number} limit - Number of recommendations to return
 * @returns {Promise<SpotifyApi.RecommendationsFromSeedsResponse>} data - Spotify recommendations
 */
export async function getSpotifyRecommendations(
  artistIds: string[],
  genres: string[],
  trackIds: string[],
  limit: number = 20
): Promise<SpotifyApi.RecommendationsFromSeedsResponse> {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  let urlParams = new URLSearchParams({
    limit: limit.toString(),
    // target_popularity: 50,
    seed_artists: artistIds ? artistIds.join(",") : "",
    seed_genres: genres ? genres.join(",") : "",
    seed_tracks: trackIds ? trackIds.join(",") : "",
  }).toString();

  const url = `https://api.spotify.com/v1/recommendations?${urlParams}`;
  const data: SpotifyApi.RecommendationsFromSeedsResponse = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

/**
 * Get comparison data
 * @param {string} userId - Spotify user ID
 * @param {string} friendId - Spotify friend ID
 * @param {string} timeRange - Time range for data
 * @returns {Promise<ApiResponse.ComparisonPageData>} data - comparison data
 */
export async function getComparisonData(
  userId: string,
  friendId: string,
  timeRange: string = "mediumTerm"
): Promise<ApiResponse.ComparisonPageData> {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  const data: ApiResponse.ComparisonPageData = await fetch(
    `${API_URL}/compare/${userId}?friendId=${friendId}&timeRange=${timeRange}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}
