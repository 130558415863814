import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import "./App.css";
import { PrivateRoute } from "./routes/PrivateRoute";
import { PublicRoute } from "./routes/PublicRoute";
import {
  Login,
  LoginRedirect,
  Dashboard,
  Summary,
  Invite,
  GroupInvite,
  GroupInsight,
  Comparison,
  PageNotFound,
  Settings,
  Concerts,
  Friends,
  Groups,
  Search,
  Profile,
} from "./routes/Routes";
import spotifyLogo from "./images/spotifyLogoWhiteSmall.png";
import PageTitle from "./components/PageTitle";
import NavigationBar from "./components/NavigationBar";

function App() {
  return (
    <BrowserRouter>
      <Analytics />
      <Routes>
        {/* Public routes */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <PageTitle title="Log In | compile.fm" />
              <NavigationBar>
                <Login />
              </NavigationBar>
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/redirect"
          element={
            <PublicRoute>
              <PageTitle title="Redirecting..." />
              <NavigationBar>
                <LoginRedirect />
              </NavigationBar>
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/invite/:userId"
          element={
            <PublicRoute>
              <PageTitle title="Invite | compile.fm" />
              <NavigationBar>
                <Invite />
              </NavigationBar>
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/groupinvite/:groupId"
          element={
            <PublicRoute>
              <PageTitle title="Group Invite | compile.fm" />
              <NavigationBar>
                <GroupInvite />
              </NavigationBar>
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/user/:userId"
          element={
            <PublicRoute>
              <PageTitle title="Profile | compile.fm" />
              <NavigationBar>
                <Profile />
              </NavigationBar>
            </PublicRoute>
          }
        ></Route>

        {/* Private routes */}
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <PageTitle title="Settings | compile.fm" />
              <NavigationBar>
                <Settings />
              </NavigationBar>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/summary"
          element={
            <PrivateRoute>
              <PageTitle title="Summary | compile.fm" />
              <Summary />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/summary/:userId"
          element={
            <PrivateRoute>
              <PageTitle title="Summary | compile.fm" />
              <Summary />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <PageTitle title="Dashboard | compile.fm" />
              <NavigationBar>
                <Dashboard />
              </NavigationBar>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/friends"
          element={
            <PrivateRoute>
              <PageTitle title="Friends | compile.fm" />
              <NavigationBar>
                <Friends />
              </NavigationBar>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/groups"
          element={
            <PrivateRoute>
              <PageTitle title="Groups | compile.fm" />
              <NavigationBar>
                <Groups />
              </NavigationBar>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/comparison/:friendId"
          element={
            <PrivateRoute>
              <PageTitle title="Comparison | compile.fm" />
              <Comparison />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/groupinsight/:groupId"
          element={
            <PrivateRoute>
              <PageTitle title="Group Insight | compile.fm" />
              <GroupInsight />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/concerts"
          element={
            <PrivateRoute>
              <PageTitle title="Concerts | compile.fm" />
              <NavigationBar>
                <Concerts />
              </NavigationBar>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/search"
          element={
            <PrivateRoute>
              <PageTitle title="Search | compile.fm" />
              <NavigationBar>
                <Search />
              </NavigationBar>
            </PrivateRoute>
          }
        ></Route>
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
