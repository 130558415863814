import React, { useEffect, useRef } from "react";
import { getCookie } from "../helpers/helper";
import "../App.css";
import spotifyLogo from "../images/spotifyLogoWhite.png";
import { SpotifyApi } from "../../types/SpotifyApi";
import config from "../config";
import AlbumCover from "./AlbumCover";

export default function TrackPlayer({
  track,
}: {
  track?: SpotifyApi.TrackObjectSimplified | SpotifyApi.TrackObjectFull;
}) {
  if (!track) {
    return <></>;
  }

  const audio = useRef<HTMLAudioElement | null>(null);
  const audioSource = useRef<HTMLSourceElement | null>(null);

  const country = getCookie("country");

  useEffect(() => {
    if (audio.current) {
      if (
        !config.featureFlags.copyright ||
        (track.preview_url && track.available_markets?.includes(country))
      ) {
        audio.current.load();
        audio.current.volume = 0.2;

        if (audioSource.current) {
          audioSource.current.src = track.preview_url || "";
          audio.current
            .play()
            .then()
            .catch((err) => {});
        }
      }
    }
  }, [track, audio, audioSource]);

  return (
    <div
      className={`hidden lg:block fixed bottom-0 right-0 max-w-xs m-8 p-2 bg-background-200 rounded-lg`}
    >
      <audio ref={audio} loop autoPlay>
        <source ref={audioSource} type="audio/mpeg"></source>
      </audio>

      <div className="text-sm md:text-base text-primary bold">
        Currently Playing:
      </div>
      <div className="flex justify-center items-center gap-x-1">
        <div className="w-1/3 h-1/3">
          <AlbumCover track={track} />
        </div>
        <div className="w-2/3">
          <div className="text-base md:text-lg font-bold line-clamp-2">
            {track.name}
          </div>
          <div className="text-sm md:text-base font-thin line-clamp-1">
            {track.artists[0].name}
          </div>
          {track.preview_url ? (
            !config.featureFlags.copyright ||
            track.available_markets?.includes(country) ? null : (
              <div className="text-2xs text-alert font-thin line-clamp-2">
                Preview not available in your country
              </div>
            )
          ) : (
            <div className="text-2xs text-alert font-thin line-clamp-2">
              Preview not available
            </div>
          )}
        </div>
      </div>
      <a href={track.external_urls.spotify} target="_blank">
        <button className="flex justify-center items-center mx-auto mt-1 bg-primary p-1 rounded-full">
          <img src={spotifyLogo} className="w-6 h-6"></img>
          <div className="my-auto mx-1 text-sm md:text-base">
            Open in Spotify
          </div>
        </button>
      </a>
    </div>
  );
}
