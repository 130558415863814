import React, { useState } from "react";
import { Users, UserCheck, User, Music, Guitar, Search } from "lucide-react";
import TimeRangeSelector from "./TimeRangeSelector";

export default function DashboardButton({
  text,
  url,
}: {
  text: string;
  url: string;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="w-1/2 md:w-1/3 p-0.5 md:p-1">
        <a href={url !== "data" ? `/${url}` : undefined}>
          <div
            className={`${
              url === "data"
                ? "bg-[#e8115b]"
                : url === "friends"
                ? "bg-[#006450]"
                : url === "groups"
                ? "bg-[#283ea3]"
                : url === "concerts"
                ? "bg-[#8400e7]"
                : url === "search"
                ? "bg-[#e04404]"
                : url.split("/")[0] === "user"
                ? "bg-[#2596be]"
                : "bg-background-100"
            } overflow-hidden pt-8 md:pt-16 rounded-lg p-1 flex items-center hover:bg-opacity-75 cursor-pointer transition-colors space-x-2`}
            onClick={
              url === "data"
                ? () => {
                    setOpen(true);
                  }
                : undefined
            }
          >
            {url === "data" ? (
              <Music className="w-12 h-12 p-2" />
            ) : url === "friends" ? (
              <UserCheck className="w-12 h-12 p-2" />
            ) : url === "groups" ? (
              <Users className="w-12 h-12 p-2" />
            ) : url === "concerts" ? (
              <Guitar className="w-12 h-12 p-2" />
            ) : url === "search" ? (
              <Search className="w-12 h-12 p-2" />
            ) : url.split("/")[0] === "user" ? (
              <User className="w-12 h-12 p-2" />
            ) : null}
            <div className="text-base font-bold">{text}</div>
          </div>
        </a>
      </div>
      <TimeRangeSelector
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}
