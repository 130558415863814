import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import ArtistImage from "../ArtistImage";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [artistIndex, setArtistIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const processedData = groupData.processed;
  processedData.commonArtists = processedData.commonArtists.slice(0, 20);

  const clickArtist = (index: number) => {
    if (index != artistIndex) {
      setArtistIndex(index);
      setTrackIndex(
        Math.floor(
          Math.random() *
            processedData.artistTracks[
              processedData.commonArtists[index].artist.id
            ]?.length
        ) || 0
      );
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">
        Artists listened to by everyone/some of you
      </div>
      <div className="flex flex-wrap items-stretch justify-center gap-2">
        {processedData.commonArtists.map((artistInfo, i) => {
          const artist = artistInfo.artist;
          const userIds = artistInfo.users.map((user) => user.userId);
          return (
            <div
              key={i}
              className={`justify-between transition-all overflow-hidden ${
                i == artistIndex
                  ? "bg-background-200"
                  : "bg-background-100 hover:bg-background-200"
              } p-2 rounded-lg cursor-pointer`}
              onClick={() => clickArtist(i)}
            >
              <div
                key={i}
                className={`flex justify-start gap-x-1 items-center ${
                  i == artistIndex ? "text-primary" : ""
                }`}
              >
                <div className="text-2xl sm:text-3xl font-bold">#{i + 1}</div>
                <div className="w-24">
                  <ArtistImage artist={artist} />
                </div>
                <div className="text-xl sm:text-base">
                  <div className="font-bold line-clamp-2">{artist.name}</div>
                </div>
              </div>
              <div className="max-w-72">
                <div>Loved most by:</div>
                <div className="flex flex-wrap justify-center items-center gap-2 max-w-fit">
                  {userIds.map((userId, j) => {
                    return (
                      <div key={j} className="flex justify-center items-center">
                        <span className="font-bold">{j + 1}.</span>
                        <UserIndicator
                          url={groupData.userInfo[userId].profileImage}
                          name={groupData.userInfo[userId].name}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <TrackPlayer
        track={
          processedData.artistTracks[
            processedData.commonArtists[artistIndex].artist.id
          ][trackIndex]
        }
      />
    </div>
  );
}
