import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import Artist from "../Artist";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";
import ArtistImage from "../ArtistImage";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const [artistSelected, setArtistSelected] =
    useState<SpotifyApi.ArtistObjectFull>();
  const [trackIndex, setTrackIndex] = useState(0);

  const topArtist = summaryData.processed.topArtists[0];
  const tracksByTopArtist = summaryData.processed.artistTracks[topArtist.id];
  const topArtistPercentage = summaryData.processed.topArtistsPercentage[0];

  useEffect(() => {
    setTrackIndex(Math.floor(Math.random() * tracksByTopArtist.length));
  }, []);

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold animate-fadeInUp mb-2">
        Your top artist was
      </div>
      <div
        className={`w-1/2 animate-fadeInUp delay-entrance`}
        style={{
          animationDelay: "0.5s",
          animationDuration: "3s",
        }}
        onClick={() => setArtistSelected(topArtist)}
      >
        <ArtistImage artist={topArtist} />
      </div>
      <div
        className={`animate-fadeInUp delay-entrance`}
        style={{ animationDelay: "1s", animationDuration: "3s" }}
      >
        <div className="text-2xl md:text-3xl font-bold my-2">
          {topArtist.name}
        </div>
      </div>
      <div
        className={`animate-fadeInUp delay-entrance`}
        style={{ animationDelay: "1.5s", animationDuration: "3s" }}
      >
        <div className="text-xl md:text-2xl">
          {topArtistPercentage}% of your favourite songs are from them
        </div>
      </div>
      <Artist
        open={artistSelected ? true : false}
        onClose={() => setArtistSelected(undefined)}
        artist={artistSelected}
      />
      <TrackPlayer track={tracksByTopArtist[trackIndex]} />
    </div>
  );
}
