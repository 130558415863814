import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [trackIndex, setTrackIndex] = useState(0);

  const clickTrack = (index: number) => {
    if (index !== trackIndex) {
      setTrackIndex(index);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold mb-2">
        Songs you
        {comparisonData.friend.name
          ? ` and ${comparisonData.friend.name} `
          : " "}
        both love!
      </div>
      <div className={`flex flex-wrap`}>
        {comparisonData.comparison.tracks.map((track, index) => {
          return (
            <div
              key={index}
              className={`transition-all cursor-pointer flex overflow-hidden rounded border-b-8 ${
                trackIndex === index
                  ? "bg-active animate-zoom border-primary"
                  : "md:hover:bg-background-200 border-transparent"
              } w-1/2 md:w-1/3`}
              onClick={() => clickTrack(index)}
            >
              <div
                className={`animate-fadeInUp delay-entrance w-2/5`}
                style={{
                  animationDelay: `${index * 0.1}s`,
                }}
              >
                <AlbumCover track={track} />
              </div>
              <div
                className={`transition-all animate-fadeInUp delay-entrance w-3/5 m-auto`}
                style={{
                  animationDelay: `${0.5 + index * 0.1}s`,
                }}
              >
                <div className={`text-sm md:text-base line-clamp-2`}>
                  {track.name}
                </div>
                <div className={`text-xs md:text-sm font-thin line-clamp-1`}>
                  {track.artists[0].name}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <TrackPlayer track={comparisonData.comparison.tracks[trackIndex]} />
    </div>
  );
}
