import React, { useState, useEffect, useMemo, useRef } from "react";
import "maplibre-gl/dist/maplibre-gl.css";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
} from "react-map-gl/maplibre";
import Modal from "./Modal";
import TrackPlayer from "./TrackPlayer";
import { bookmarkEvent } from "../helpers/concerts";
import ErrorPopUp from "./ErrorPopUp";
import { getCookie } from "../helpers/helper";
import ProfileImage from "./ProfileImage";
import { ApiResponse, DatabaseSchema } from "../../types/Data";
import { SpotifyApi } from "../../types/SpotifyApi";
import AlbumCover from "./AlbumCover";
import ArtistImage from "./ArtistImage";

type BookmarkStatus =
  ApiResponse.Concert["groupedEvents"]["venueId"]["eventId"]["bookmarkStatus"]["userId"];

export default function ConcertMap({
  userId,
  concerts,
}: {
  userId: string;
  concerts?: ApiResponse.Concert;
}) {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string>();
  const [selectedVenueId, setSelectedVenueId] = useState<string>();
  const [trackPlaying, setTrackPlaying] =
    useState<SpotifyApi.TrackObjectFull>();
  const [updateCount, setUpdateCount] = useState(0);

  const groupedEvents = concerts?.groupedEvents || {};
  const artistData = concerts?.artistData || {};
  const userData = concerts?.userData || {};

  const [height, setHeight] = useState(512);
  const [width, setWidth] = useState(256);
  const ref = useRef<HTMLDivElement>(null);

  // Resize map based on the div container's size
  const handleResize = () => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [concerts]);

  const handleBookmarkEvent = async (
    eventId: string,
    venueId: string,
    bookmarkStatus: BookmarkStatus
  ) => {
    setIsLoading(true);
    try {
      await bookmarkEvent({
        userId,
        eventId,
        bookmarkStatus: bookmarkStatus || undefined,
      });

      // Update bookmark status in state
      const event = groupedEvents[venueId][eventId];
      event.bookmarkStatus ? null : (event.bookmarkStatus = {});
      !bookmarkStatus
        ? delete event.bookmarkStatus[userId]
        : (event.bookmarkStatus[userId] = bookmarkStatus);

      setUpdateCount((prev) => prev + 1);
    } catch (err) {
      setError(err as Error);
    }
    setIsLoading(false);
  };

  const event = () => {
    if (!selectedEventId || !selectedVenueId) return;

    const event = groupedEvents[selectedVenueId][selectedEventId];
    if (event) {
      // Get list of events by the same artist at the same venue on different dates
      const events = Object.values(groupedEvents[selectedVenueId]).filter(
        (event) => event.artistId === event.artistId
      );

      // Filter user IDs by bookmark status
      const usersInterested = Object.keys(event.bookmarkStatus).filter(
        (userId) => event.bookmarkStatus[userId] === "interested"
      );
      const usersAttending = Object.keys(event.bookmarkStatus).filter(
        (userId) => event.bookmarkStatus[userId] === "attending"
      );

      return (
        <Modal
          open={selectedEventId !== undefined}
          onClose={() => {
            setSelectedEventId(undefined);
            setSelectedVenueId(undefined);
            setTrackPlaying(undefined);
          }}
          hideCloseButton={true}
        >
          <div className="max-w-xl">
            <div className="flex gap-1 sm:gap-2 items-center">
              <div className="w-1/3">
                <ArtistImage artist={artistData[event.artistId].artist} />
                <div>{artistData[event.artistId].artist.name}</div>
              </div>
              <div className="w-2/3 flex flex-col sm:gap-1">
                <div className="text-lg sm:text-2xl font-bold">
                  {event.name}
                </div>
                <div className="text-lg sm:text-2xl font-bold text-primary">
                  {event.date
                    ? new Date(event.date)
                        .toString()
                        .split(" ")
                        .slice(1, 4)
                        .join(" ")
                    : "Multiple Days"}
                </div>
                <div className="text-sm sm:text-base">
                  {event.city}, {event.country}
                </div>
                <div className="text-sm sm:text-base">
                  {event.venue ? `@${event.venue}` : ""}
                </div>
                <div className="mt-1 mx-5">
                  <a href={event.url} target="_blank">
                    <button className="w-full p-2 rounded-lg bg-blue-600">
                      Tickets
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <select
                disabled={isLoading}
                className="bg-background-300 p-2 rounded-lg"
                value={
                  (event.bookmarkStatus && event.bookmarkStatus[userId]) || ""
                }
                onChange={(e) => {
                  handleBookmarkEvent(
                    selectedEventId,
                    selectedVenueId,
                    e.target.value as BookmarkStatus
                  );
                }}
              >
                <option value="">Not interested</option>
                <option value="interested">Interested</option>
                <option value="attending">Going</option>
              </select>
            </div>
            {usersAttending.length ? (
              <div className="mt-1 flex justify-center gap-1">
                {usersAttending.map((userId, i) => {
                  if (event.bookmarkStatus[userId] == "attending") {
                    return (
                      <div key={i} className="flex gap-1">
                        <div className="w-6">
                          <ProfileImage
                            url={userData[userId].profileImage}
                            round={true}
                          />
                        </div>
                        {userData[userId]?.name}
                        {i !== usersAttending.length - 1 ? "," : ""}
                      </div>
                    );
                  }
                })}
                <div>{usersAttending.length > 1 ? "are" : "is"} going</div>
              </div>
            ) : null}
            {usersInterested.length ? (
              <div className="mt-1 flex justify-center gap-1">
                {usersInterested.map((userId, i) => {
                  if (event.bookmarkStatus[userId] == "interested") {
                    return (
                      <div key={i} className="flex gap-1">
                        <div className="w-6">
                          <ProfileImage
                            url={userData[userId]?.profileImage}
                            round={true}
                          />
                        </div>
                        {userData[userId]?.name}
                        {i !== usersInterested.length - 1 ? "," : ""}
                      </div>
                    );
                  }
                })}
                <div>
                  {usersInterested.length > 1 ? "are" : "is"} interested
                </div>
              </div>
            ) : null}
            <div className={`mt-2 ${events.length > 1 ? "" : "hidden"}`}>
              <div className="text-lg sm:text-xl font-bold text-left">
                Event Dates:
              </div>
              <div className="flex flex-wrap gap-2">
                {events.map((event, i) => {
                  return (
                    <div
                      key={i}
                      className={`cursor-pointer text-lg sm:text-2xl font-bold p-2 bg-background-300 rounded-lg ${
                        event.id === selectedEventId ? "text-primary" : ""
                      }`}
                      onClick={() => {
                        setSelectedEventId(event.id);
                        setSelectedVenueId(event.venueId);
                      }}
                    >
                      <div>
                        {event.date
                          ? new Date(event.date)
                              .toString()
                              .split(" ")
                              .slice(1, 3)
                              .join(" ")
                          : "Multiple"}
                      </div>
                      <div>
                        {event.date
                          ? new Date(event.date)
                              .toString()
                              .split(" ")
                              .slice(3, 4)
                              .join(" ")
                          : "Days"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={`mt-2 ${
                artistData[event.artistId].topTracks.length > 0 ? "" : "hidden"
              }`}
            >
              <div className="text-lg sm:text-xl font-bold text-left">
                Their biggest hits:
              </div>
              <div className="grid grid-cols-5 gap-1 sm:gap-2">
                {artistData[event.artistId].topTracks
                  .slice(0, 5)
                  .map((track, i) => {
                    return (
                      <div
                        key={i}
                        className={`transition-all cursor-pointer rounded overflow-hidden ${
                          trackPlaying?.id === track.id
                            ? "text-primary bg-active"
                            : "bg-background-300"
                        }`}
                        onClick={() => {
                          setTrackPlaying(track);
                        }}
                      >
                        <AlbumCover track={track} />
                        <div className="text-xs sm:text-sm line-clamp-2 p-1">
                          {track.name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              className={`mt-2 ${
                artistData[event.artistId].tracks.length > 0 ? "" : "hidden"
              }`}
            >
              <div className="text-lg sm:text-xl font-bold text-left">
                Songs you like from them:
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                {artistData[event.artistId].tracks.map((track, i) => {
                  return (
                    <div
                      key={i}
                      className={`transition-all flex cursor-pointer items-center rounded-lg overflow-hidden gap-2 ${
                        trackPlaying?.id === track.id
                          ? "text-primary bg-active"
                          : "bg-background-300"
                      }`}
                      onClick={() => {
                        setTrackPlaying(track);
                      }}
                    >
                      <div className="w-16">
                        <AlbumCover track={track} />
                      </div>
                      <div className="text-sm sm:text-lg line-clamp-2">
                        {track.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
      );
    }
  };

  const markers = useMemo(
    () =>
      Object.keys(groupedEvents).map((venueId, i) => {
        const artistIds: string[] = [];
        const eventIds = Object.keys(groupedEvents[venueId]);
        if (
          !groupedEvents[venueId][eventIds[0]]?.longitude ||
          !groupedEvents[venueId][eventIds[0]]?.latitude
        ) {
          return;
        }
        return (
          <Marker
            key={i}
            longitude={Number(groupedEvents[venueId][eventIds[0]].longitude)}
            latitude={Number(groupedEvents[venueId][eventIds[0]].latitude)}
          >
            <div className="flex gap-1">
              {eventIds.map((eventId, j) => {
                const event = groupedEvents[venueId][eventId];
                if (!artistIds.includes(event.artistId)) {
                  artistIds.push(event.artistId);
                  const artist = artistData[event.artistId].artist;
                  const artistBookmarks: DatabaseSchema.Event["bookmarkStatus"][] =
                    [];
                  eventIds.forEach((eventId) => {
                    const event = groupedEvents[venueId][eventId];
                    if (event.artistId === artist.id) {
                      artistBookmarks.push(event.bookmarkStatus);
                    }
                  });
                  const bookmarkStatus = artistBookmarks.reduce((acc, curr) => {
                    Object.keys(curr).forEach((userId) => {
                      if (curr[userId] === null) {
                        delete curr[userId];
                      }
                    });

                    return {
                      ...acc,
                      ...curr,
                    };
                  }, {});

                  return (
                    <div
                      className="w-16 flex flex-col items-center gap-1"
                      key={j}
                      onClick={() => {
                        setSelectedEventId(event.id);
                        setSelectedVenueId(event.venueId);
                      }}
                    >
                      <div className="w-full bg-background-100 p-1 rounded-lg">
                        <ArtistImage artist={artist} />
                        <div className="text-xs line-clamp-2">
                          {artist?.name}
                        </div>
                      </div>
                      {Object.values(bookmarkStatus).length ? (
                        <div className="bg-background-100 p-1 rounded-full flex flex-wrap">
                          {Object.keys(bookmarkStatus).map((userId, i) => {
                            if (bookmarkStatus[userId]) {
                              return (
                                <div key={i} className="w-7">
                                  <ProfileImage
                                    round={true}
                                    url={userData[userId]?.profileImage}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
          </Marker>
        );
      }),
    [concerts, updateCount]
  );

  if (!concerts) {
    return (
      <div className="flex flex-col justify-center items-center h-64">
        <div>Sorry, an error occured</div>
      </div>
    );
  } else if (!Object.keys(groupedEvents).length) {
    return (
      <div className="flex flex-col justify-center items-center h-64">
        <div>Compiling...</div>
        <div>
          If this is your first time logging in, it might take a few minutes
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-full" ref={ref}>
        <Map
          initialViewState={{
            longitude: -122.4,
            latitude: 37.8,
            zoom: 14,
          }}
          style={{ width: width, height: height }}
          mapStyle="https://api.maptiler.com/maps/backdrop/style.json?key=oQxkkGesrF5SuaDZSSp3"
          attributionControl={false}
        >
          <GeolocateControl />
          {markers}
        </Map>
        {event()}
        <TrackPlayer track={trackPlaying} />
        <ErrorPopUp
          error={error}
          onClose={() => {
            setError(undefined);
          }}
        />
      </div>
    );
  }
}
