import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";
import { Album } from "lucide-react";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const friendData = comparisonData.friend;
  const myData = comparisonData.me;

  const years = [
    ...new Set([
      ...Object.keys(friendData.timeline),
      ...Object.keys(myData.timeline),
    ]),
  ].sort();

  const [userIndex, setUserIndex] = useState(
    friendData.timeline[years[0]]?.length > 0 ? 0 : 1
  );
  const [yearIndex, setYearIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const clickTrack = (user: number, year: number, track: number) => {
    if (user !== userIndex || year !== yearIndex || track !== trackIndex) {
      setUserIndex(user);
      setYearIndex(year);
      setTrackIndex(track);
    }
  };

  const selectYear = (year: number) => {
    if (yearIndex !== year) {
      setYearIndex(year);

      if (friendData.timeline[years[year]]?.length > 0) {
        clickTrack(0, year, 0);
      } else {
        clickTrack(1, year, 0);
      }
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold">Your favourite eras:</div>
      <div className="w-full max-w-lg flex justify-evenly gap-x-2 mt-2">
        <ol className="relative border-l border-white">
          {years.map((year, i) => {
            return (
              <li key={i} onClick={() => selectYear(i)}>
                <div
                  className={`absolute rounded-full ${
                    yearIndex === i
                      ? "bg-primary w-3 h-3 mt-3 -left-1.5"
                      : "bg-white w-2 h-2 mt-3 -left-1"
                  }`}
                ></div>
                <div
                  className={`cursor-pointer transition-all animate-expand my-2 ${
                    yearIndex === i
                      ? "text-primary text-2xl md:text-3xl font-bold"
                      : "text-white/75 md:hover:text-white text-lg md:text-xl lg:text-2xl"
                  }`}
                >
                  {year}s
                </div>
                <div className="w-full flex">
                  <div
                    className={`w-1/2 transition-all flex flex-col items-center ${
                      yearIndex === i
                        ? "opacity-1 h-auto pointer-events-auto"
                        : "opacity-0 h-0 pointer-events-none"
                    }`}
                  >
                    <UserIndicator
                      url={friendData.profileImage}
                      name={friendData.name}
                      playing={userIndex === 0 ? true : false}
                    />
                    {friendData.timeline[year]?.slice(0, 3).map((track, j) => {
                      return (
                        <div
                          key={j}
                          onClick={() => clickTrack(0, i, j)}
                          className={`flex items-center gap-x-1 cursor-pointer p-2 rounded-lg ${
                            userIndex === 0 &&
                            i === yearIndex &&
                            j === trackIndex
                              ? "bg-active md:animate-zoom"
                              : "md:hover:bg-background-200"
                          }`}
                        >
                          <div className="w-2/5">
                            <AlbumCover track={track} />
                          </div>
                          <div className="w-3/5">
                            <div
                              className={`text-sm md:text-base font-bold line-clamp-2`}
                            >
                              {track.name}
                            </div>
                            <div
                              className={`text-xs md:text-sm font-thin line-clamp-1`}
                            >
                              {track.artists[0].name}
                            </div>
                            <div
                              className={`text-xs md:text-sm text-primary font-bold line-clamp-1`}
                            >
                              {track.album.release_date.split("-")[0]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={`w-1/2 transition-all flex flex-col items-center ${
                      yearIndex === i
                        ? "opacity-1 h-auto pointer-events-auto"
                        : "opacity-0 h-0 pointer-events-none"
                    }`}
                  >
                    <UserIndicator
                      url={myData.profileImage}
                      name={myData.name}
                      playing={userIndex === 1 ? true : false}
                    />
                    {myData.timeline[year]?.slice(0, 3).map((track, j) => {
                      return (
                        <div
                          key={j}
                          onClick={() => clickTrack(1, i, j)}
                          className={`flex items-center gap-x-1 cursor-pointer p-2 rounded-lg ${
                            userIndex === 1 &&
                            i === yearIndex &&
                            j === trackIndex
                              ? "bg-active md:animate-zoom"
                              : "md:hover:bg-background-200"
                          }`}
                        >
                          <div className="w-2/5">
                            <AlbumCover track={track} />
                          </div>
                          <div className="w-3/5">
                            <div
                              className={`text-sm md:text-base font-bold line-clamp-2`}
                            >
                              {track.name}
                            </div>
                            <div
                              className={`text-xs md:text-sm font-thin line-clamp-1`}
                            >
                              {track.artists[0].name}
                            </div>
                            <div
                              className={`text-xs md:text-sm text-primary font-bold line-clamp-1`}
                            >
                              {track.album.release_date.split("-")[0]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
      <TrackPlayer
        track={
          userIndex === 0
            ? friendData.timeline[years[yearIndex]]
              ? friendData.timeline[years[yearIndex]][trackIndex]
              : undefined
            : myData.timeline[years[yearIndex]]
            ? myData.timeline[years[yearIndex]][trackIndex]
            : undefined
        }
      />
    </div>
  );
}
