import { setCookie, getCookie } from "./helper";
import { logOut } from "./login";

const API_URL = process.env.REACT_APP_URL + "/api";

/**
 * Fetch and store Spotify tokens
 * @param {string} code - Spotify authorization code
 * @returns {object} tokens - Spotify access and refresh tokens
 */
export async function fetchTokens(code: string) {
  let tokens = await fetch(`${API_URL}/login`, {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ code: code }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((data) => {
      // store tokens in cookies
      setCookie("access_token", data.access_token);
      setCookie("refresh_token", data.refresh_token);
      setCookie("expires_at", data.expires_at);
      setCookie("userId", data.id);
      setCookie("country", data.country);
      return data;
    });
  return tokens;
}

/**
 * Check if tokens have expired and refresh if necessary
 * @returns {object} tokens - Spotify access and refresh tokens
 */
export async function checkAndRefreshTokens() {
  let tokens;
  if (!getCookie("expires_at") || getCookie("expires_at") == "undefined") {
    logOut();
  } else if (Number(new Date(getCookie("expires_at"))) <= Date.now()) {
    // console.log("Refreshing tokens");
    tokens = await refreshTokens(getCookie("refresh_token"));
  } else {
    tokens = {
      access_token: getCookie("access_token"),
      expires_at: getCookie("expires_at"),
    };
  }
  return tokens;
}

/**
 * Fetch and update refreshed Spotify tokens
 * @param {string} refreshToken - Spotify refresh token
 * @returns {object} tokens - Spotify access and refresh tokens
 */
async function refreshTokens(refreshToken: string) {
  let tokens = await fetch(`${API_URL}/refresh`, {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refresh_token: refreshToken }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((res) => {
      // get time the tokens expire
      let expiresAt = new Date(Date.now() + res.expires_in * 1000);
      res.expires_at = expiresAt;

      // store refreshed tokens in localStorage
      setCookie("access_token", res.access_token);
      setCookie("expires_at", String(expiresAt));

      return res;
    });
  return tokens;
}
