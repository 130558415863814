import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import Artist from "../Artist";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";
import AlbumCover from "../AlbumCover";
import ArtistImage from "../ArtistImage";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const [artistSelected, setArtistSelected] =
    useState<SpotifyApi.ArtistObjectFull>();
  const [genreIndex, setGenreIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const topGenres = summaryData.processed.topGenres;
  const genreArtists = summaryData.processed.genreArtists;
  const genreTracks = summaryData.processed.genreTracks;

  const clickGenre = (index: number) => {
    if (index !== genreIndex) {
      setGenreIndex(index);
      setTrackIndex(
        Math.floor(Math.random() * genreTracks[topGenres[index]].length)
      );
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="animate-fadeInUp">
        <div className="text-2xl md:text-3xl font-bold">Top genres</div>
      </div>
      {topGenres.map((genre, index) => {
        return (
          <div
            key={index}
            className="animate-fadeInRight delay-entrance w-full"
            onClick={() => clickGenre(index)}
            style={{
              animationDelay: `${index * 0.2}s`,
            }}
          >
            <div
              className={`transition-all cursor-pointer font-bold my-1 ${
                genreIndex == index
                  ? "text-2xl md:text-3xl text-primary my-3"
                  : "text-xl md:text-2xl text-white/75 md:hover:text-white"
              }`}
            >
              {genre}
            </div>
            <div
              className={`transition-all flex justify-center ${
                genreIndex == index
                  ? "h-auto opacity-1 animate-fadeInUp"
                  : "h-0 opacity-0"
              }`}
            >
              {genreArtists[genre].slice(0, 5).map((artist) => {
                return (
                  <div key={artist.id} className="w-1/5 mx-0.5">
                    <div
                      className={`w-full`}
                      onClick={() => {
                        setArtistSelected(artist);
                      }}
                    >
                      <ArtistImage artist={artist} />
                    </div>
                    <div className={`text-xs md:text-sm line-clamp-2`}>
                      {artist.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <Artist
        open={artistSelected ? true : false}
        onClose={() => setArtistSelected(undefined)}
        artist={artistSelected}
      />
      <TrackPlayer track={genreTracks[topGenres[genreIndex]][trackIndex]} />
    </div>
  );
}
