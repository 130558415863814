import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../App.css";
import { getGroupInfo, joinGroup } from "../helpers/groups";
import ErrorPage from "./Error";
import Loading from "../components/Loading";
import { isLoggedIn, logInSpotify } from "../helpers/login";
import spotifyLogo from "../images/spotifyLogoWhite.png";
import { setCookie, getCookie } from "../helpers/helper";
import ProfileImage from "../components/ProfileImage";
import { ApiResponse } from "../../types/Data";
import ErrorPopUp from "../components/ErrorPopUp";

const GroupInvite = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [group, setGroup] = useState<ApiResponse.GroupInfo>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getGroup() {
      try {
        if (!groupId) {
          throw new Error("Group ID not provided");
        }

        const groupInfo = await getGroupInfo(groupId);
        setGroup(groupInfo);
      } catch (err) {
        setError(err as Error);
      }
    }
    getGroup();
  }, []);

  const clickStart = async () => {
    try {
      if (!groupId) {
        throw new Error("Group ID not provided");
      }

      setLoading(true);
      const myUserId = getCookie("userId");
      await joinGroup({
        userId: myUserId,
        groupId: groupId,
      });
      setLoading(false);
      navigate(`/groupinsight/${groupId}`);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
    }
  };

  if (!group) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="max-w-screen-md space-y-2">
          <div className="text-2xl md:text-3xl font-bold">Group Invite:</div>
          <div className="text-base md:text-lg font-bold">
            You were invited to join
            <span className="text-primary"> {group.name}</span> to compare music
            data with your friends!
          </div>
          <div className="md:w-full rounded bg-background-100 p-4">
            <div className="font-bold"> Group Members:</div>
            <div className="w-full flex flex-wrap">
              {Object.keys(group.userInfo).map((userId) => {
                return (
                  <div
                    key={userId}
                    className="w-1/2 md:w-1/3 flex justify-center items-center gap-x-1 text-lg my-1"
                  >
                    <div className="w-12">
                      <ProfileImage
                        url={group.userInfo[userId].profileImage}
                        round={true}
                      />
                    </div>
                    {group.userInfo[userId].name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-base md:text-lg">
            By joining, your Spotify data will be shared to the group
          </div>
          {isLoggedIn() ? (
            <button
              className="bg-primary text-white text-sm md:text-base rounded-full mx-auto p-4 flex items-center justify-center gap-x-2"
              onClick={clickStart}
            >
              {loading ? "Loading..." : "Join!"}
            </button>
          ) : (
            <button
              className="bg-primary text-white text-sm md:text-base rounded-full mx-auto p-4 flex items-center justify-center gap-x-2"
              onClick={() => {
                logInSpotify(`/groupInvite/${groupId}`);
              }}
            >
              <img src={spotifyLogo} className="h-8"></img>
              Log in with Spotify
            </button>
          )}
          {error && error.message == "Payload Too Large" ? (
            <div className="text-base md:text-lg text-alert animate-fadeInUp">
              Sorry the group is now full
            </div>
          ) : (
            <></>
          )}
        </div>
        <ErrorPopUp
          error={error}
          onClose={() => {
            setError(undefined);
          }}
        />
      </>
    );
  }
};

export default GroupInvite;
