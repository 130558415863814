import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import ArtistImage from "../ArtistImage";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [genreIndex, setGenreIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const processedData = groupData.processed;
  processedData.commonGenres = processedData.commonGenres.slice(0, 10);

  const clickGenre = (index: number) => {
    if (index !== genreIndex) {
      setGenreIndex(index);
      setTrackIndex(
        Math.floor(
          Math.random() *
            (processedData.commonGenres[index].tracks?.length || 0)
        )
      );
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">Most loved genres</div>
      <div className="grid grid-cols-1 gap-2 w-full sm:w-[500px] lg:w-[700px]">
        {processedData.commonGenres.map((genreInfo, i) => {
          const genre = genreInfo.genre;
          const userIds = genreInfo.users.map((user) => user.userId);
          return (
            <div
              className={`transition-all cursor-pointer p-2 rounded-lg ${
                i === genreIndex
                  ? "bg-background-100"
                  : "hover:bg-background-100"
              }`}
              onClick={() => clickGenre(i)}
            >
              <div key={i} className="lg:flex gap-2">
                <div
                  className={`text-3xl font-bold ${
                    i === genreIndex ? "text-primary" : ""
                  }`}
                >
                  <span>#{i + 1}. </span>
                  <span>{genre}</span>
                </div>
                <div className="flex flex-wrap justify-center items-center mt-2 gap-2">
                  {userIds.map((userId, j) => {
                    return (
                      <div key={j} className="flex justify-center items-center">
                        <span className="font-bold">{j + 1}.</span>
                        <UserIndicator
                          url={groupData.userInfo[userId].profileImage}
                          name={groupData.userInfo[userId].name}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              {i === genreIndex && (
                <div className="animate-fadeInUp flex flex-wrap justify-center items-center mt-2">
                  {genreInfo.artists?.map((artist, j) => {
                    if (j < 6) {
                      return (
                        <div
                          className="w-1/6 p-1 animate-fadeInUp delay-entrance"
                          style={{
                            animationDelay: `${j * 0.05}s`,
                          }}
                        >
                          <ArtistImage artist={artist} />
                          <div className="text-xs sm:text-sm line-clamp-2">
                            {artist.name}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <TrackPlayer
        track={processedData.commonGenres[genreIndex].tracks?.at(trackIndex)}
      />
    </div>
  );
}
