import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import Artist from "../Artist";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";
import ArtistImage from "../ArtistImage";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [artistSelected, setArtistSelected] =
    useState<SpotifyApi.ArtistObjectFull>();
  const [userIndex, setUserIndex] = useState(0);
  const [artistIndex, setArtistIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const myTopArtists = comparisonData.me.topArtists.slice(0, 5);
  const friendTopArtists = comparisonData.friend.topArtists.slice(0, 5);
  const userTracksByTopArtists = myTopArtists.map(
    (artist) => comparisonData.me.artistTracks[artist.id]
  );
  const friendTracksByTopArtists = friendTopArtists.map(
    (artist) => comparisonData.friend.artistTracks[artist.id]
  );

  const clickArtist = (user: number, artist: number) => {
    if (user !== userIndex || artist !== artistIndex) {
      setUserIndex(user);
      setArtistIndex(artist);
      setTrackIndex(
        user === 0
          ? Math.floor(Math.random() * friendTracksByTopArtists[artist]?.length)
          : Math.floor(Math.random() * userTracksByTopArtists[artist]?.length)
      );
    } else {
      setArtistSelected(
        user === 0 ? friendTopArtists[artist] : myTopArtists[artist]
      );
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold">Top artists</div>
      <div className="w-full flex justify-evenly gap-x-1 animate-fadeInUp">
        <UserIndicator
          url={comparisonData.friend.profileImage}
          name={comparisonData.friend.name}
          playing={userIndex === 0}
        />
        <UserIndicator
          url={comparisonData.me.profileImage}
          name={"You"}
          playing={userIndex === 1}
        />
      </div>
      <div className="max-w-md grid grid-cols-[1fr_auto_1fr] gap-1">
        <div className={`col-start-1 col-end-2 animate-fadeInRight`}>
          {friendTopArtists.map((artist, index) => {
            return (
              <div
                key={index}
                className={`transition-all cursor-pointer w-full rounded ${
                  userIndex === 0 && artistIndex === index
                    ? `scale-100`
                    : "scale-90"
                }`}
                onClick={() => clickArtist(0, index)}
              >
                <div
                  className={`animate-fadeInRight delay-entrance w-full border-b-4 ${
                    userIndex === 0 && artistIndex === index
                      ? `border-primary`
                      : `border-transparent`
                  }`}
                  style={{
                    animationDelay: `${0.5 + index * 0.1}s`,
                  }}
                >
                  <ArtistImage artist={artist} />
                </div>
                <div
                  className={`text-lg md:text-xl line-clamp-1 ${
                    userIndex === 0 && artistIndex === index
                      ? `text-primary font-bold`
                      : "text-white"
                  }`}
                >
                  {artist.name}
                </div>
              </div>
            );
          })}
        </div>
        <div className={`animate-fadeInUp col-start-2 col-end-3 row-start-1`}>
          {myTopArtists.map((track, index) => {
            return (
              <div
                key={index}
                className={`text-2xl md:text-3xl font-bold transition-all h-1/5 ${
                  artistIndex === index ? `text-primary` : "text-white"
                }`}
              >
                #{index + 1}
              </div>
            );
          })}
        </div>
        <div className={`col-start-3 col-end-4 animate-fadeInLeft`}>
          {myTopArtists.map((artist, index) => {
            return (
              <div
                key={index}
                className={`transition-all cursor-pointer w-full rounded ${
                  userIndex === 1 && artistIndex === index
                    ? `scale-100`
                    : "scale-90"
                }`}
                onClick={() => clickArtist(1, index)}
              >
                <div
                  className={`aspect-square object-cover animate-fadeInLeft delay-entrance w-full border-b-4 ${
                    userIndex === 1 && artistIndex === index
                      ? `border-primary`
                      : `border-transparent`
                  }`}
                  style={{
                    animationDelay: `${0.5 + index * 0.1}s`,
                  }}
                >
                  <ArtistImage artist={artist} />
                </div>
                <div
                  className={`text-lg md:text-xl line-clamp-1 ${
                    userIndex === 1 && artistIndex === index
                      ? `text-primary font-bold`
                      : "text-white"
                  }`}
                >
                  {artist.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <TrackPlayer
        track={
          userIndex === 0
            ? friendTracksByTopArtists[artistIndex]?.at(trackIndex)
            : userTracksByTopArtists[artistIndex]?.at(trackIndex)
        }
      />
      <Artist
        open={artistSelected ? true : false}
        onClose={() => setArtistSelected(undefined)}
        artist={artistSelected}
      />
    </div>
  );
}
