import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const popularityBar1 = useRef<HTMLSpanElement>(null);
  const popularityBar2 = useRef<HTMLSpanElement>(null);
  const [headerText, setHeaderText] = useState("");
  const [myMedianPopularity, setMyMedianPopularity] = useState(0);
  const [friendMedianPopularity, setFriendMedianPopularity] = useState(0);

  useEffect(() => {
    let myMedianPopularity = comparisonData.me.medianPopularity;
    let friendMedianPopularity = comparisonData.friend.medianPopularity;
    setTimeout(() => {
      if (popularityBar1.current) {
        popularityBar1.current.style.height = `${friendMedianPopularity}%`;
      }
      if (popularityBar2.current) {
        popularityBar2.current.style.height = `${myMedianPopularity}%`;
      }
    }, 0);
    setMyMedianPopularity(myMedianPopularity);
    setFriendMedianPopularity(friendMedianPopularity);

    if (myMedianPopularity > friendMedianPopularity) {
      setHeaderText(
        `${
          comparisonData.friend.name
            ? comparisonData.friend.name
            : "Your friend"
        }'s songs are ${
          myMedianPopularity - friendMedianPopularity
        }% more obscure than yours.`
      );
    } else if (friendMedianPopularity > myMedianPopularity) {
      setHeaderText(
        `Your songs are ${
          friendMedianPopularity - myMedianPopularity
        }% more obscure than ${
          comparisonData.friend.name
            ? comparisonData.friend.name
            : "your friend"
        }'s.`
      );
    } else {
      setHeaderText(
        `You and ${
          comparisonData.friend.name
            ? comparisonData.friend.name
            : "your friend"
        }'s songs are equally edgy.`
      );
    }
  }, []);

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold">{headerText}</div>
      <div className="flex justify-center mt-5">
        <div className="flex flex-col items-center">
          <div className="flex flex-col-reverse h-96 w-10 bg-active rounded-full mb-5">
            <span
              className="bg-primary w-full h-0 text-xs md:text-sm rounded-full transition-all duration-1000 p-2"
              ref={popularityBar1}
            >
              {Math.round(friendMedianPopularity)}%
            </span>
          </div>
          <UserIndicator
            url={comparisonData.friend.profileImage}
            name={comparisonData.friend.name}
          ></UserIndicator>
        </div>
        <div className="text-xs md:text-sm flex flex-col justify-between pb-12">
          <div>Most mainstream</div>
          <div>Most edgy</div>
        </div>
        <div className="flex flex-col items-center">
          <div
            className={`flex flex-col-reverse h-96 w-10 bg-active rounded-full mb-5`}
          >
            <span
              className="bg-primary w-full h-0 text-xs md:text-sm rounded-full transition-all duration-1000 p-2"
              ref={popularityBar2}
            >
              {Math.round(myMedianPopularity)}%
            </span>
          </div>
          <UserIndicator
            url={comparisonData.me.profileImage}
            name={"You"}
          ></UserIndicator>
        </div>
      </div>
      <TrackPlayer track={comparisonData.me.leastPopularTracks[0]} />
    </div>
  );
}
