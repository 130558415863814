import { checkAndRefreshTokens } from "./tokens";

const API_URL = process.env.REACT_APP_URL + "/api";

export async function deleteAccount(userId: string) {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;
  const data = await fetch(`${API_URL}/user/${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}
