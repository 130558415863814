import { checkAndRefreshTokens } from "./tokens";
import { ApiResponse } from "../../types/Data";

const API_URL = process.env.REACT_APP_URL + "/api";

export async function getConcertsStream(userId: string) {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  // Fetch the streamed data from the server
  return fetch(`${API_URL}/concertstream/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  });
}

export async function getConcerts(userId: string) {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  const data: ApiResponse.Concert = await fetch(
    `${API_URL}/concerts/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function bookmarkEvent({
  userId,
  eventId,
  bookmarkStatus,
}: {
  userId: string;
  eventId: string;
  bookmarkStatus?: string;
}) {
  if (!bookmarkStatus) {
    bookmarkStatus = undefined;
  }

  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  let data = await fetch(`${API_URL}/concerts/${userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
    body: JSON.stringify({ eventId, bookmarkStatus }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export function mergeConcerts(
  oldConcerts: ApiResponse.Concert,
  newConcerts: ApiResponse.Concert
): ApiResponse.Concert {
  let mergedConcerts = { ...oldConcerts };
  for (let artistId in newConcerts.artistData) {
    if (!mergedConcerts.artistData[artistId]) {
      mergedConcerts.artistData[artistId] = newConcerts.artistData[artistId];
    }
  }
  for (let userId in newConcerts.userData) {
    if (!mergedConcerts.userData[userId]) {
      mergedConcerts.userData[userId] = newConcerts.userData[userId];
    }
  }
  for (let venueId in newConcerts.groupedEvents) {
    if (!mergedConcerts.groupedEvents[venueId]) {
      mergedConcerts.groupedEvents[venueId] =
        newConcerts.groupedEvents[venueId];
    }

    for (let eventId in newConcerts.groupedEvents[venueId]) {
      if (!mergedConcerts.groupedEvents[venueId][eventId]) {
        mergedConcerts.groupedEvents[venueId][eventId] =
          newConcerts.groupedEvents[venueId][eventId];
      }
    }
  }

  return mergedConcerts;
}
