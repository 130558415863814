import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const clickTrack = (user: number, index: number) => {
    if (user !== userIndex || index !== trackIndex) {
      setUserIndex(user);
      setTrackIndex(index);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold">
        Your least mainstream songs
      </div>
      <div className="max-w-lg grid grid-cols-2 gap-x-1 mt-2 animate-fadeInUp">
        <div className="col-start-1 col-end-2">
          <UserIndicator
            url={comparisonData.friend.profileImage}
            name={comparisonData.friend.name}
            playing={userIndex === 0}
          ></UserIndicator>
          {comparisonData.friend.leastPopularTracks
            .slice(0, 5)
            .map((track, index) => {
              return (
                <div
                  key={index}
                  className={`transition-all cursor-pointer w-full flex flex-row-reverse overflow-hidden rounded border-b-4 animate-fadeInRight delay-entrance ${
                    userIndex === 0 && index === trackIndex
                      ? "border-primary bg-active"
                      : "md:hover:bg-background-200 border-transparent"
                  }`}
                  style={{
                    animationDelay: `${0.5 + index * 0.1}s`,
                  }}
                  onClick={() => clickTrack(0, index)}
                >
                  <div className="w-2/5">
                    <AlbumCover track={track} />
                  </div>
                  <div className={`w-3/5 m-auto`}>
                    <div
                      className={`text-sm md:text-base font-bold line-clamp-2`}
                    >
                      {track.name}
                    </div>
                    <div
                      className={`text-xs md:text-sm font-thin line-clamp-1`}
                    >
                      {track.artists[0].name}
                    </div>
                    <div
                      className={`transition-all h-1 flex m-auto w-4/5 bg-active rounded-full`}
                    >
                      <span
                        className={`bg-primary h-full rounded-full`}
                        style={{ width: `${track.popularity}%` }}
                      ></span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="col-start-2 col-end-3">
          <UserIndicator
            url={comparisonData.me.profileImage}
            name={comparisonData.me.name}
            playing={userIndex === 1}
          ></UserIndicator>
          {comparisonData.me.leastPopularTracks
            .slice(0, 5)
            .map((track, index) => {
              return (
                <div
                  key={index}
                  className={`transition-all cursor-pointer w-full flex overflow-hidden rounded border-b-4 animate-fadeInLeft delay-entrance ${
                    userIndex === 1 && index === trackIndex
                      ? "border-primary bg-active"
                      : "md:hover:bg-background-200 border-transparent"
                  }`}
                  style={{
                    animationDelay: `${0.6 + index * 0.1}s`,
                  }}
                  onClick={() => clickTrack(1, index)}
                >
                  <div className="w-2/5">
                    <AlbumCover track={track} />
                  </div>
                  <div className={`w-3/5 m-auto`}>
                    <div
                      className={`text-sm md:text-base font-bold line-clamp-2`}
                    >
                      {track.name}
                    </div>
                    <div
                      className={`text-xs md:text-sm font-thin line-clamp-1`}
                    >
                      {track.artists[0].name}
                    </div>
                    <div
                      className={`transition-all h-1 flex m-auto w-4/5 bg-active rounded-full`}
                    >
                      <span
                        className={`bg-primary h-full rounded-full`}
                        style={{ width: `${track.popularity}%` }}
                      ></span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <TrackPlayer
        track={
          userIndex === 0
            ? comparisonData.friend.leastPopularTracks[trackIndex]
            : comparisonData.me.leastPopularTracks[trackIndex]
        }
      />
    </div>
  );
}
