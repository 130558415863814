import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";

export default function TimeRangeSelector({
  open,
  onClose,
  compare = false,
  friendId,
}: {
  open: boolean;
  onClose: () => void;
  compare?: boolean;
  friendId?: string;
}) {
  const navigate = useNavigate();
  const [timeRange, setTimeRange] = useState<
    "shortTerm" | "mediumTerm" | "longTerm"
  >("mediumTerm");

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={true}>
      <div className="font-bold">Select the time range below</div>
      <div className="font-bold">
        Click <span className="text-primary">start</span> to
        {compare ? " compare" : " see"} your Spotify data
      </div>
      <div className="w-full h-24 flex items-stretch">
        <div
          className={`transition-all cursor-pointer text-lg md:text-xl font-bold w-1/3 p-1 rounded flex justify-center items-center overflow-hidden ${
            timeRange === "shortTerm"
              ? `bg-active text-primary font-bold animate-zoom`
              : `md:hover:bg-background-300`
          }`}
          onClick={() => {
            setTimeRange("shortTerm");
          }}
        >
          {/* 30 days */}Last month
        </div>
        <div
          className={`transition-all cursor-pointer text-lg md:text-xl font-bold w-1/3 p-1 rounded flex justify-center items-center overflow-hidden ${
            timeRange === "mediumTerm"
              ? `bg-active text-primary font-bold animate-zoom`
              : `md:hover:bg-background-300`
          }`}
          onClick={() => {
            setTimeRange("mediumTerm");
          }}
        >
          {/* 1/2 year */}This year so far
        </div>
        <div
          className={`transition-all cursor-pointer text-lg md:text-xl font-bold w-1/3 p-1 rounded flex justify-center items-center overflow-hidden ${
            timeRange === "longTerm"
              ? `bg-active text-primary font-bold animate-zoom`
              : `md:hover:bg-background-300`
          }`}
          onClick={() => {
            setTimeRange("longTerm");
          }}
        >
          All
        </div>
      </div>
      <button
        className="w-full transition-all text-white bg-primary rounded px-2 py-1 mt-1"
        onClick={() => {
          navigate(
            compare && friendId
              ? `/comparison/${friendId}?time_range=${timeRange}`
              : friendId
              ? `/summary/${friendId}?time_range=${timeRange}`
              : `/summary?time_range=${timeRange}`
          );
        }}
      >
        Start
      </button>
    </Modal>
  );
}
