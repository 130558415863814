import React, { useState, useEffect } from "react";
import {
  Home,
  UserCheck,
  Settings,
  Users,
  User,
  Guitar,
  Search,
  LogIn,
  LogOut,
} from "lucide-react";
import { getCookie } from "../helpers/helper";
import { logInSpotify, logOut } from "../helpers/login";
import spotifyLogo from "../images/spotifyLogoWhiteSmall.png";

const SideNav = ({
  isOpen,
  isLoggedIn,
}: {
  isOpen: boolean;
  isLoggedIn: boolean;
}) => {
  const currentPath = window.location.pathname;
  const navItems: {
    name: string;
    icon: any;
    link: string;
  }[] = isLoggedIn
    ? [
        { name: "Home", icon: Home, link: "/dashboard" },
        {
          name: "Your profile",
          icon: User,
          link: `/user/${getCookie("userId")}`,
        },
        { name: "Friends", icon: UserCheck, link: "/friends" },
        { name: "Search", icon: Search, link: "/search" },
        { name: "Groups", icon: Users, link: "/groups" },
        { name: "Concerts", icon: Guitar, link: "/concerts" },
        { name: "Settings", icon: Settings, link: "/settings" },
        { name: "Log out", icon: LogOut, link: "/logout" },
      ]
    : [{ name: "Log in", icon: LogIn, link: "/login" }];

  return (
    <div
      className={`${
        isOpen ? "w-52" : "w-0"
      } bg-background-100 transition-width duration-300 z-40 overflow-hidden fixed md:relative pt-12 md:pt-0 inset-0`}
    >
      <nav>
        {isOpen &&
          navItems.map((item) => (
            <a
              key={item.name}
              href={item.link}
              onClick={(e) => {
                if (item.link == "/login" || item.link == "/logout") {
                  e.preventDefault();

                  if (item.link == "/login") {
                    logInSpotify(currentPath);
                  } else {
                    logOut();
                  }
                }
              }}
              className={`${
                currentPath.toLocaleLowerCase() ===
                item.link.toLocaleLowerCase()
                  ? "text-primary pointer-events-none"
                  : ""
              } flex items-center p-4 hover:bg-background-200 transition-colors select-none`}
            >
              <item.icon className="w-6 h-6 min-w-6 min-h-6 mr-2" />
              <span className="line-clamp-1">{item.name}</span>
            </a>
          ))}
      </nav>
      <div className="absolute bottom-2 left-0 right-0 opacity-50 select-none line-clamp-1">
        Powered by Spotify{" "}
        <img className="inline-block h-4" src={spotifyLogo} />
      </div>
    </div>
  );
};

export default SideNav;
