import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import Artist from "../Artist";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";
import ArtistImage from "../ArtistImage";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [artistSelected, setArtistSelected] =
    useState<SpotifyApi.ArtistObjectFull>();
  const [userIndex, setUserIndex] = useState(0);
  const [track, setTrack] = useState<
    SpotifyApi.TrackObjectSimplified | SpotifyApi.TrackObjectFull
  >();

  const myTopArtist = comparisonData.me.topArtists[0];
  const myTopArtistPercentage = comparisonData.me.topArtistsPercentage[0];
  const friendTopArtist = comparisonData.friend.topArtists[0];
  const friendTopArtistPercentage =
    comparisonData.friend.topArtistsPercentage[0];

  const friendArtistTracks = comparisonData.friend.artistTracks;
  const myArtistTracks = comparisonData.me.artistTracks;

  useEffect(() => {
    setTrack(
      userIndex === 0
        ? friendArtistTracks[friendTopArtist.id][
            Math.floor(
              Math.random() * friendArtistTracks[friendTopArtist.id].length
            )
          ]
        : myArtistTracks[myTopArtist.id][
            Math.floor(Math.random() * myArtistTracks[myTopArtist.id].length)
          ]
    );
  }, [userIndex]);

  const clickArtist = (index: number) => {
    if (index !== userIndex) {
      setUserIndex(index);
    } else {
      setArtistSelected(index === 0 ? friendTopArtist : myTopArtist);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold">Top artists</div>
      <div className="grid grid-cols-2 gap-x-1 max-w-lg">
        <div
          className="col-start-1 col-end-2 animate-fadeInRight cursor-pointer"
          onClick={() => clickArtist(0)}
        >
          <UserIndicator
            url={comparisonData.friend.profileImage}
            name={comparisonData.friend.name}
            playing={userIndex === 0 ? true : false}
          />

          <div
            className={`animate-fadeInRight delay-entrance w-full border-b-8 ${
              userIndex === 0 ? `border-primary` : `border-transparent`
            }`}
          >
            <ArtistImage artist={friendTopArtist} />
          </div>
          <div
            className={`text-xl md:text-2xl line-clamp-1 mb-1 ${
              userIndex === 0 ? `text-primary font-bold` : "text-white"
            }`}
          >
            {friendTopArtist.name}
          </div>
          <div className="text-base md:text-lg">
            <span className="text-primary">{friendTopArtistPercentage}% </span>
            of their favourite songs are from them
          </div>
        </div>
        <div
          className="col-start-2 col-end-3 animate-fadeInLeft cursor-pointer"
          onClick={() => clickArtist(1)}
        >
          <UserIndicator
            url={comparisonData.me.profileImage}
            name={comparisonData.me.name}
            playing={userIndex === 1 ? true : false}
          />

          <div
            className={`animate-fadeInLeft delay-entrance w-full border-b-8 ${
              userIndex === 1 ? `border-primary` : `border-transparent`
            }`}
          >
            <ArtistImage artist={myTopArtist} />
          </div>
          <div
            className={`text-xl md:text-2xl line-clamp-1 mb-1 ${
              userIndex === 1 ? `text-primary font-bold` : "text-white"
            }`}
          >
            {myTopArtist.name}
          </div>
          <div className="text-base md:text-lg">
            <span className="text-primary">{myTopArtistPercentage}% </span>
            of your favourite songs are from them
          </div>
        </div>
      </div>
      <TrackPlayer track={track} />
      <Artist
        open={artistSelected ? true : false}
        onClose={() => setArtistSelected(undefined)}
        artist={artistSelected}
      />
    </div>
  );
}
