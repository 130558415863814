import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import ArtistImage from "../ArtistImage";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [artistIndex, setArtistIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const processedData = groupData.processed;

  const topArtists = Object.keys(processedData.topArtists).map((userId) => {
    return processedData.topArtists[userId][0];
  });

  const clickArtist = (index: number) => {
    if (index !== artistIndex) {
      const randomTrackIndex = Math.floor(
        Math.random() * processedData.artistTracks[topArtists[index].id].length
      );
      setArtistIndex(index);
      setTrackIndex(randomTrackIndex);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">
        Your top artists were
      </div>
      <div className="grid grid-cols-3 md:grid-cols-4 gap-2">
        {Object.keys(processedData.topArtists).map((userId, index) => {
          const topArtist = topArtists[index];
          return (
            <div
              key={index}
              className={`md:p-2 cursor-pointer animate-fadeInLeft delay-entrance ${
                artistIndex === index ? "text-primary" : ""
              }`}
              style={{
                animationDelay: `${0.5 + index * 0.1}s`,
              }}
              onClick={() => clickArtist(index)}
            >
              <UserIndicator
                url={groupData.userInfo[userId].profileImage}
                name={groupData.userInfo[userId].name}
                playing={artistIndex === index}
              />
              <div
                className={`w-full mb-1 border-b-4 ${
                  artistIndex === index
                    ? "border-primary"
                    : "border-transparent"
                }`}
              >
                <ArtistImage artist={topArtist} />
              </div>
              <div className="font-bold line-clamp-2">{topArtist.name}</div>
              <div className="font-thin line-clamp-3">
                {topArtist.genres.join(", ")}
              </div>
            </div>
          );
        })}
      </div>
      <TrackPlayer
        track={
          processedData.artistTracks[topArtists[artistIndex].id][trackIndex]
        }
      />
    </div>
  );
}
