import React, { useEffect, useState } from "react";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";
import "../App.css";

export default function WordCloud({
  disableAnimation,
  genreScores,
}: {
  disableAnimation?: boolean;
  genreScores: { [key: string]: number };
}) {
  const [genres, setGenres] = useState<string[]>([]);
  const [animationDelays, setAnimationDelays] = useState<number[]>([]);
  const isMobileScreen = useCheckMobileScreen();

  if (!disableAnimation) {
    disableAnimation = false;
  }
  const maxCount = Math.max(...Object.values(genreScores));

  useEffect(() => {
    setGenres(Object.keys(genreScores).sort(() => Math.random() - 0.5));
    setAnimationDelays(Object.keys(genreScores).map(() => Math.random() * 2.5));
  }, []);

  return (
    <ul className="cursor-pointer list-none flex justify-center items-center flex-wrap p-0 leading-3">
      {genres.map((genre, index) => {
        return (
          <li
            key={index}
            className={`block relative mx-0.5 ${
              disableAnimation ? "" : "animate-fadeInUp delay-entrance"
            }`}
            style={{
              fontSize: `${
                genreScores[genre] === 1
                  ? isMobileScreen
                    ? 0.75
                    : 1
                  : isMobileScreen
                  ? ((genreScores[genre] / maxCount) * 3 + 1) * 0.5
                  : ((genreScores[genre] / maxCount) * 3 + 1) * 0.75
              }em`,
              animationDelay: `${animationDelays[index]}s`,
              color: `rgba(255, 255, 255, ${
                (genreScores[genre] / maxCount) * 0.5 + 0.5
              })`,
            }}
          >
            {genre}
          </li>
        );
      })}
    </ul>
  );
}
