import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUserData } from "../helpers/fetchData";
import { addFriend } from "../helpers/friends";
import { getCookie, setCookie } from "../helpers/helper";
import "../App.css";
import { isLoggedIn, logInSpotify } from "../helpers/login";
import ProfileImage from "../components/ProfileImage";
import spotifyLogo from "../images/spotifyLogoWhite.png";
import ErrorPage from "./Error";
import Loading from "../components/Loading";
import { ApiResponse } from "../../types/Data";

const Invite = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [friendData, setFriendData] = useState<ApiResponse.User>();

  useEffect(() => {
    async function getInviterData() {
      try {
        if (!userId) {
          throw new Error("Friend not found");
        }

        const friendData = await getUserData(userId, false);
        setFriendData(friendData);
      } catch (err) {
        setError(err as Error);
      }
    }
    getInviterData();
  }, []);

  const clickStart = async () => {
    try {
      if (!friendData) {
        throw new Error("Friend not found");
      }

      setLoading(true);
      const myUserId = getCookie("userId");
      const friendUserId = friendData.id;
      if (myUserId != friendUserId) {
        await addFriend(myUserId, friendUserId);
        navigate(`/comparison/${userId}`);
      } else {
        alert("Sorry, you can't compare music with yourself");
      }
      setLoading(false);
    } catch (err) {
      setError(err as Error);
    }
  };

  if (error) {
    return <ErrorPage error={error} />;
  } else if (friendData) {
    return (
      <>
        <div className="w-32 mt-6">
          <ProfileImage url={friendData.profileImage} />
        </div>
        <div className="text-base md:text-lg font-bold">
          <span className="text-primary">{friendData.name}</span> invited you to
          compare your music tastes!
        </div>
        <div className="text-base md:text-lg m-1">
          By clicking start, you will be added as their friend
        </div>
        <div>
          {isLoggedIn() ? (
            <button
              className="bg-primary text-white text-sm md:text-base rounded-full p-4 flex items-center justify-center gap-x-2"
              onClick={clickStart}
            >
              {loading ? "Loading..." : "Start!"}
            </button>
          ) : (
            <button
              className="bg-primary text-white text-sm md:text-base rounded-full p-4 flex items-center justify-center gap-x-2"
              onClick={() => {
                logInSpotify(`/invite/${userId}`);
              }}
            >
              <img src={spotifyLogo} className="h-8"></img>
              Log in with Spotify
            </button>
          )}
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Invite;
