import React, { useEffect, useState } from "react";
import "../App.css";
import profileImage from "../images/profileImage.png";
import { SpotifyApi } from "../../types/SpotifyApi";

export default function ArtistImage({
  artist,
}: {
  artist?: SpotifyApi.ArtistObjectFull | SpotifyApi.ArtistObjectSimplified;
}) {
  const [src, setSrc] = useState<string | undefined>();

  useEffect(() => {
    const url =
      artist && "images" in artist && artist.images
        ? artist.images.at(1)?.url
        : undefined;
    setSrc(url);
  }, [artist]);

  return (
    <>
      {src ? (
        <img
          className="transition-all aspect-square object-cover min-w-full"
          src={src}
          onError={() => {
            setSrc(profileImage);
          }}
        ></img>
      ) : (
        <img
          className="transition-all aspect-square object-cover min-w-full bg-black"
          src={profileImage}
        ></img>
      )}
    </>
  );
}
