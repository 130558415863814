import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const topTrack = summaryData.processed.topTracks[0];

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div
        className="animate-fadeInUp"
        style={{
          animationDuration: "3s",
        }}
      >
        <div className="text-2xl md:text-3xl font-bold mb-2">
          Your top song was
        </div>
      </div>
      <div
        className="w-1/2 max-w-72 animate-fadeInUp delay-entrance"
        style={{
          animationDelay: "0.5s",
          animationDuration: "3s",
        }}
      >
        <AlbumCover track={topTrack} />
      </div>
      <div
        className="opacity-0 animate-fadeInUp delay-entrance"
        style={{ animationDelay: "1s", animationDuration: "3s" }}
      >
        <div className="text-2xl md:text-3xl font-bold my-2">
          {topTrack.name}
        </div>
      </div>
      <div
        className="opacity-0 animate-fadeInUp delay-entrance"
        style={{ animationDelay: "1.5s", animationDuration: "3s" }}
      >
        <div className="text-lg md:text-xl">{topTrack.artists[0].name}</div>
      </div>
      <TrackPlayer track={topTrack} />
    </div>
  );
}
