import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import ErrorPopUp from "../components/ErrorPopUp";
import Modal from "../components/Modal";
import { deleteAccount } from "../helpers/account";
import { logOut } from "../helpers/login";
import { getCookie } from "../helpers/helper";
import screenshot from "../images/deleteAccountScreenshot.png";

const Settings = () => {
  const [error, setError] = useState<Error>();

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const clickDeleteAccount = async () => {
    try {
      const data = await deleteAccount(getCookie("userId"));
      if (data) {
        logOut();
      }
    } catch (err) {
      setError(err as Error);
    }
  };

  return (
    <>
      <div>
        <h1 className="text-2xl md:text-3xl py-4 font-bold text-center">
          Settings
        </h1>
      </div>
      <div className="w-full max-w-screen-sm bg-background-100 rounded-lg p-2 mb-2 md:p-4 md:mb-4">
        <div className="text-lg md:text-xl font-bold text-left border-b border-active p-2">
          Account:
        </div>
        <div className="text-base md:text-lg flex justify-between items-center border-b border-active p-1">
          <div className="text-base md:text-lg">Delete account</div>
          <button
            className={`text-base md:text-lg bg-alert text-white p-1 rounded`}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Delete
          </button>
        </div>
        <div>
          <button
            className={`text-base md:text-lg font-bold bg-active text-white p-1 rounded mt-2`}
            onClick={() => {
              logOut();
              navigate("/");
            }}
          >
            Log Out
          </button>
        </div>
      </div>
      <div className="text-sm w-80 opacity-60 mt-1">
        If you found bugs or have suggestions, please contact me at:
        <a href="mailto:hi@compile.fm"> hi@compile.fm</a> 😊
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        hideCloseButton={true}
      >
        <div>
          <div className="text-base md:text-lg">
            Are you sure you want to delete your account?
          </div>
          <div className="text-base font-bold md:text-lg text-alert text-left my-2">
            <div>This will:</div>
            <div>- Delete your compile.fm account and all your data.</div>
            <div>- Remove your followers and follows.</div>
            <div>- Disable any invite links.</div>
          </div>
          <div className="text-base md:text-lg text-left my-2">
            <div>Additionally:</div>
            <div>
              - You can also disconnect compile.fm directly on Spotify{" "}
              <a
                href="https://www.spotify.com/account/apps"
                target="_blank"
                className="font-bold underline text-primary"
              >
                here
              </a>
              .
            </div>
            <div>
              - In the "Manage Apps" menu, scroll down to "compile.fm" and click
              the "Remove Access" button.
            </div>
            <img className="mt-2 mx-auto sm:w-3/4" src={screenshot}></img>
          </div>
          <div className="inline-flex gap-x-1 mt-2">
            <button
              className={`text-base md:text-lg font-bold bg-alert text-white py-2 px-4 rounded`}
              onClick={clickDeleteAccount}
            >
              Yes
            </button>
            <button
              className={`text-base md:text-lg font-bold bg-primary text-white py-2 px-4 rounded`}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <ErrorPopUp
        error={error}
        onClose={() => {
          setError(undefined);
        }}
      />
    </>
  );
};

export default Settings;
