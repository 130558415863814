import { ApiResponse } from "../../types/Data";
import { checkAndRefreshTokens } from "./tokens";

const URL = process.env.REACT_APP_URL;
const API_URL = process.env.REACT_APP_URL + "/api";

export async function getGroups(
  userId: string
): Promise<ApiResponse.AllGroups> {
  const data: ApiResponse.AllGroups = await fetch(
    `${API_URL}/group/get?userId=${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function getGroupInfo(
  groupId: string
): Promise<ApiResponse.GroupInfo> {
  const data: ApiResponse.GroupInfo = await fetch(
    `${API_URL}/group/${groupId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function getGroupData(
  groupId: string,
  userId: string,
  timeRange: string
): Promise<ApiResponse.GroupPageData> {
  const data: ApiResponse.GroupPageData = await fetch(
    `${API_URL}/group/${groupId}?userId=${userId}&timeRange=${timeRange}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function joinGroup({
  userId,
  groupId,
}: {
  userId: string;
  groupId: string;
}) {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  let data = await fetch(`${API_URL}/group/${groupId}?userId=${userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function createGroup({
  userId,
  groupName,
  description,
}: {
  userId: string;
  groupName: string;
  description?: string;
}): Promise<ApiResponse.SimpleResponse<string>> {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  const data: ApiResponse.SimpleResponse<string> = await fetch(
    `${API_URL}/group/new?userId=${userId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ name: groupName, description: description }),
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function leaveGroup({
  userId,
  groupId,
}: {
  userId: string;
  groupId: string;
}) {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  let data = await fetch(`${API_URL}/group/${groupId}?userId=${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      action: "leaveGroup",
    }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}

export async function renameGroup({
  userId,
  groupId,
  newGroupName,
}: {
  userId: string;
  groupId: string;
  newGroupName: string;
}) {
  const tokens = await checkAndRefreshTokens();
  const accessToken = tokens.access_token;

  let data = await fetch(`${API_URL}/group/${groupId}?userId=${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      action: "renameGroup",
      newName: newGroupName,
    }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
  return data;
}
