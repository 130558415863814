import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [searchParams] = useSearchParams();

  // let timeRangeText = "in the past 6 months";
  let timeRangeText = "this year so far";
  if (searchParams.get("time_range") === "shortTerm") {
    timeRangeText = "from the last month";
  } else if (searchParams.get("time_range") === "longTerm") {
    timeRangeText = "from the beginning of time";
  }

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-xl md:text-2xl animate-fadeInUp">
        Hello {groupData.userInfo[userId].name}!
      </div>
      <div
        className="text-xl md:text-2xl animate-fadeInUp delay-entrance"
        style={{
          animationDelay: "0.5s",
        }}
      >
        Welcome to the group insights for{" "}
        <span className="font-bold text-primary">{groupData.name}</span>
      </div>
      <div
        className="max-w-lg p-2 md:p-3 rounded-lg bg-background-100 animate-fadeInUp delay-entrance"
        style={{
          animationDelay: "0.5s",
        }}
      >
        <div className="font-bold mb-1">{`Group Members (${groupData.userIds.length}):`}</div>
        <div className="flex flex-wrap gap-y-1 max-w-sm md:max-w-lg">
          {groupData.userIds.map((userId, index) => {
            return (
              <div
                className="w-1/2 md:w-1/3 flex gap-x-1 items-center"
                key={index}
              >
                <div className="w-1/3">
                  <ProfileImage url={groupData.userInfo[userId].profileImage} />
                </div>
                <div>{groupData.userInfo[userId].name}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="text-xl md:text-2xl animate-fadeInUp delay-entrance"
        style={{
          animationDelay: "1s",
        }}
      >
        We compiled your group's Spotify data{" "}
        <span className="font-bold text-primary">{timeRangeText}</span>
      </div>
      <TrackPlayer track={groupData.processed.randomTracks[0]} />
    </div>
  );
}
