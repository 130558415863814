import React from "react";
import "../App.css";
import styles from "./SoundWave.module.css";

export default function SoundWave() {
  return (
    <div className="flex justify-center items-center">
      <div className={styles.soundWaves}>
        <div className={`${styles.soundWave} ${styles.wave1}`}></div>
        <div className={`${styles.soundWave} ${styles.wave2}`}></div>
        <div className={`${styles.soundWave} ${styles.wave3}`}></div>
        <div className={`${styles.soundWave} ${styles.wave4}`}></div>
        <div className={`${styles.soundWave} ${styles.wave5}`}></div>
      </div>
    </div>
  );
}
