import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [trackIndex, setTrackIndex] = useState(0);

  const clickTrack = (index: number) => {
    if (index !== trackIndex) {
      setTrackIndex(index);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div>
        <div className="text-2xl md:text-3xl font-bold mb-2">
          Songs that
          {comparisonData.friend.name
            ? ` ${comparisonData.friend.name} `
            : " they "}
          love that you also might like
        </div>
        <div className="flex flex-wrap">
          {comparisonData.comparison.recommendations.map((track, index) => {
            return (
              <div
                className={`transition-all cursor-pointer w-1/3 md:w-1/4 ${
                  trackIndex === index ? "scale-100" : "scale-90"
                }`}
                key={index}
                onClick={() => {
                  clickTrack(index);
                }}
              >
                <div
                  className={`w-full border-b-4 ${
                    trackIndex === index
                      ? `border-primary`
                      : `border-transparent`
                  }`}
                  style={{
                    animationDelay: `${index * 0.1}s`,
                  }}
                >
                  <AlbumCover track={track} />
                </div>

                <div>
                  <div
                    className={`text-sm md:text-base line-clamp-2 ${
                      trackIndex === index ? "text-primary" : "text-white"
                    }`}
                  >
                    {track.name}
                  </div>
                  <div
                    className={`text-xs md:text-sm font-thin line-clamp-2 ${
                      trackIndex === index ? "text-primary" : "text-white"
                    }`}
                  >
                    {track.artists[0].name}
                  </div>
                  {track.rank ? (
                    <div className="text-xs md:text-sm mt-1">
                      Ranked{" "}
                      <span className="text-primary font-bold">
                        {`#${track.rank}`}
                      </span>{" "}
                      in
                      {comparisonData.friend.name
                        ? ` ${comparisonData.friend.name}'s `
                        : " their "}{" "}
                      songs
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <TrackPlayer
        track={
          comparisonData.comparison.recommendations.length > 0
            ? comparisonData.comparison.recommendations[trackIndex]
            : undefined
        }
      />
    </div>
  );
}
