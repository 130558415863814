import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import Artist from "../Artist";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";
import ArtistImage from "../ArtistImage";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [artistSelected, setArtistSelected] =
    useState<SpotifyApi.ArtistObjectFull>();
  const [artistIndex, setArtistIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const comparisonsData = comparisonData.comparison;

  const clickArtist = (index: number) => {
    if (index !== artistIndex) {
      setArtistIndex(index);
      setTrackIndex(
        Math.floor(
          Math.random() *
            comparisonsData.artistsTracks[comparisonsData.artists[index].id]
              ?.length
        ) || 0
      );
    } else {
      setArtistSelected(comparisonsData.artists[artistIndex]);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold mb-2">
        Artists you
        {comparisonData.friend.name
          ? ` and ${comparisonData.friend.name} `
          : " "}
        both love!
      </div>
      <div className="max-w-lg flex flex-wrap">
        {comparisonsData.artists.map((artist, index) => {
          return (
            <div
              className={`transition-all cursor-pointer animate-fadeInUp delay-entrance w-1/3 border-b-8 ${
                artistIndex === index ? `border-primary` : "border-transparent"
              }`}
              key={index}
              style={{
                animationDelay: `${index * 0.1}s`,
              }}
              onClick={() => {
                clickArtist(index);
              }}
            >
              <div
                key={index}
                className={`transition-all w-full aspect-square object-cover ${
                  artistIndex === index ? `scale-100` : "scale-90"
                }`}
              >
                <ArtistImage artist={artist} />
              </div>
              <div
                className={`line-clamp-1 ${
                  artistIndex === index ? `text-primary font-bold` : ``
                }`}
              >
                {artist.name}
              </div>
            </div>
          );
        })}
      </div>
      <TrackPlayer
        track={comparisonsData.artistsTracks[
          comparisonsData.artists[artistIndex].id
        ]?.at(trackIndex)}
      />
      <Artist
        open={artistSelected ? true : false}
        onClose={() => setArtistSelected(undefined)}
        artist={artistSelected}
      />
    </div>
  );
}
