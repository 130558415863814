import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import profileImage from "../images/profileImage.png";

export default function ProfileImage({
  userId,
  url,
  round,
}: {
  userId?: string;
  url?: string;
  round?: boolean;
}) {
  const [src, setSrc] = useState<string | undefined>(url);
  const navigate = useNavigate();

  const handleClickProfile = () => {
    if (userId) {
      navigate(`/user/${userId}`);
    }
  };

  useEffect(() => {
    setSrc(url);
  }, [url]);

  return (
    <div
      onClick={handleClickProfile}
      className={`${userId ? "cursor-pointer" : ""}`}
    >
      {src ? (
        <img
          className={`transition-all aspect-square object-cover min-w-full ${
            round ? "rounded-full" : "rounded-lg"
          }`}
          src={src}
          onError={() => {
            setSrc(profileImage);
          }}
        ></img>
      ) : (
        <img
          className={`transition-all aspect-square object-cover min-w-full bg-black ${
            round ? "rounded-full" : "rounded-lg"
          }`}
          src={profileImage}
        ></img>
      )}
    </div>
  );
}
