import React from "react";
import { ApiResponse } from "../../types/Data";
import Flag from "./Flag";
import ProfileImage from "./ProfileImage";

const FriendsList = ({
  user,
  friends,
  title,
  onFollow,
  loadingIds,
}: {
  user?: ApiResponse.User;
  friends?: ApiResponse.Friend[];
  title?: string;
  onFollow: (friendId: string) => void;
  loadingIds: string[];
}) => {
  return (
    <>
      <div className="font-bold text-xl md:text-2xl mb-2">{title}</div>
      {!friends ? (
        <div>Loading...</div>
      ) : (
        friends.map((friend, index) => {
          return (
            <div key={index} className="p-0.5 md:w-96">
              <a href={`/user/${friend.id}`}>
                <div
                  className="p-1 flex items-center gap-x-2 justify-between h-full animate-fadeInUp delay-entrance rounded-lg border border-background-300 bg-background-200 overflow-hidden"
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  <div className="grow flex items-center gap-x-2">
                    <div className="w-12">
                      <ProfileImage url={friend.profileImage} round={true} />
                    </div>
                    <div className="grow text-base md:text-lg line-clamp-1">
                      {`${friend.name} `}
                      <Flag countryCode={friend.country} />
                    </div>
                  </div>
                  {!user ? (
                    <div className="min-w-max px-2 py-3 transition-all text-sm md:text-base rounded-lg overflow-hidden">
                      Loading...
                    </div>
                  ) : user.id == friend.id ? (
                    <div className="min-w-max px-2 py-3 transition-all text-sm md:text-base rounded-lg overflow-hidden">
                      You
                    </div>
                  ) : user.following?.includes(friend.id) ? (
                    <button
                      className="min-w-max px-2 py-3 transition-all bg-background-300 text-white text-sm md:text-base rounded-lg overflow-hidden"
                      disabled={true}
                    >
                      Following
                    </button>
                  ) : (
                    <>
                      {loadingIds.includes(friend.id) ? (
                        <button
                          disabled={true}
                          className="min-w-max px-2 py-3 transition-all bg-background-300 text-sm md:text-base rounded-lg overflow-hidden"
                          onClick={(e) => {
                            e.preventDefault();
                            onFollow(friend.id);
                          }}
                        >
                          ...
                        </button>
                      ) : (
                        <button
                          className="min-w-max px-2 py-3 transition-all bg-background-300 md:hover:bg-active text-primary text-sm md:text-base rounded-lg overflow-hidden"
                          onClick={(e) => {
                            e.preventDefault();
                            onFollow(friend.id);
                          }}
                        >
                          Follow
                        </button>
                      )}
                    </>
                  )}
                </div>
              </a>
            </div>
          );
        })
      )}
    </>
  );
};

export default FriendsList;
