import React, { useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import { SpotifyApi } from "../../../types/SpotifyApi";
import { ApiResponse } from "../../../types/Data";
import { useSearchParams } from "react-router-dom";
import AlbumCover from "../AlbumCover";
import ArtistImage from "../ArtistImage";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const topArtists = summaryData.processed.topArtists;
  const topTracks = summaryData.processed.topTracks;
  const topGenres = summaryData.processed.topGenres;

  const [searchParams] = useSearchParams();
  let timeRangeText = (
    <>
      Your Spotify wrapped
      <span className="font-bold text-primary"> this year so far</span>
    </>
  );
  if (searchParams.get("time_range") === "shortTerm") {
    timeRangeText = (
      <>
        Your Spotify wrapped for
        <span className="font-bold text-primary"> last month</span>
      </>
    );
  } else if (searchParams.get("time_range") === "longTerm") {
    timeRangeText = (
      <>
        Your
        <span className="font-bold text-primary"> all time </span> Spotify
        wrapped
      </>
    );
  }

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl mb-2 md:mb-4">{timeRangeText}</div>
      <div className="max-w-md grid grid-cols-[1fr_auto_1fr] grid-rows-1fr-5fr-1fr gap-y-3">
        <div className="w-full row-start-1 row-end-2 col-start-1 col-end-2">
          <div className={`text-lg md:text-xl font-bold line-clamp-1`}>
            Top Songs
          </div>
        </div>
        <div className="row-start-2 row-end-3 col-start-1 col-end-2 animate-fadeInRight">
          {topTracks
            .slice(0, 5)
            .map((track: SpotifyApi.TrackObjectFull, index: number) => {
              return (
                <div className="flex w-full" key={track.id}>
                  <div className="w-3/5 px-1 my-auto">
                    <div className="text-sm md:text-base font-bold line-clamp-1">
                      {track.name}
                    </div>
                    <div className="text-xs md:text-sm font-thin line-clamp-2">
                      {track.artists[0].name}
                    </div>
                  </div>
                  <div className="w-2/5">
                    <AlbumCover track={track} />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row-start-2 row-end-3 col-start-2 col-end-3">
          {topTracks
            .slice(0, 5)
            .map((track: SpotifyApi.TrackObjectFull, index: number) => {
              return (
                <div
                  className="h-1/5 p-1 text-2xl md:text-3xl font-bold"
                  key={index}
                >
                  #{index + 1}
                </div>
              );
            })}
        </div>
        <div className="row-start-1 row-end-2 col-start-3 col-end-4">
          <div className={`text-lg md:text-xl font-bold line-clamp-1`}>
            Top Artists
          </div>
        </div>
        <div
          className="w-full row-start-2 row-end-3 col-start-3 col-end-4 animate-fadeInLeft delay-entrance"
          style={{
            animationDelay: "0.5s",
          }}
        >
          {topArtists
            .slice(0, 5)
            .map((artist: SpotifyApi.ArtistObjectFull, index: number) => {
              return (
                <div className="flex w-full" key={artist.id}>
                  <div className="w-2/5">
                    <ArtistImage artist={artist} />
                  </div>
                  <div className="w-3/5 px-1 my-auto line-clamp-3 text-sm md:text-base font-bold">
                    {artist.name}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row-start-3 col-start-1 col-end-4">
          <div className={`text-lg md:text-xl font-bold line-clamp-1`}>
            Top Genres
          </div>
          <div
            className="flex justify-center animate-fadeInUp delay-entrance"
            style={{
              animationDelay: "1s",
            }}
          >
            {topGenres.slice(0, 5).map((genre: string, index: number) => {
              return (
                <div className="w-1/5 mx-px" key={genre}>
                  <div className="text-xl md:text-2xl font-bold">
                    #{index + 1}
                  </div>
                  <div className="text-sm md:text-base font-bold overflow-hidden">
                    {genre}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <TrackPlayer track={summaryData.processed.randomTracks[2]} />
    </div>
  );
}
