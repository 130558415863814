import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(1);

  const myTopTracks = comparisonData.me.topTracks.slice(0, 5);
  const friendTopTracks = comparisonData.friend.topTracks.slice(0, 5);

  const clickTrack = (user: number, track: number) => {
    if (user !== userIndex || track !== trackIndex) {
      setUserIndex(user);
      setTrackIndex(track);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold">Top songs</div>
      <div className="w-full flex justify-evenly gap-x-1 animate-fadeInUp">
        <UserIndicator
          url={comparisonData.friend.profileImage}
          name={comparisonData.friend.name}
          playing={userIndex === 0}
        />
        <UserIndicator
          url={comparisonData.me.profileImage}
          name={"You"}
          playing={userIndex === 1}
        />
      </div>
      <div className="max-w-lg grid grid-cols-[1fr_auto_1fr] gap-x-1">
        <div className={`col-start-1 col-end-2 animate-fadeInRight`}>
          {friendTopTracks.map((track, index) => {
            return (
              <div
                key={index}
                className={`transition-all cursor-pointer flex justify-end border-b-8 ${
                  userIndex === 0 && trackIndex === index
                    ? "border-primary text-primary"
                    : "border-transparent text-white"
                }`}
                onClick={() => clickTrack(0, index)}
              >
                <div
                  className="w-7/12 md:w-3/5 animate-zoomIn delay-entrance m-auto"
                  style={{
                    animationDelay: `${index * 0.1}s`,
                  }}
                >
                  <div className="text-sm md:text-base line-clamp-2 font-bold">
                    {track?.name}
                  </div>
                  <div className="text-xs md:text-sm line-clamp-1 font-thin">
                    {track.artists[0]?.name}
                  </div>
                </div>
                <div className="w-5/12 md:w-2/5 animate-fadeInleft">
                  <div
                    style={{
                      animationDelay: `${0.5 + index * 0.1}s`,
                    }}
                  >
                    <AlbumCover track={track} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={`animate-fadeInUp col-start-2 col-end-3 row-start-1`}>
          {myTopTracks.map((track, index) => {
            return (
              <div
                key={index}
                className={`transition-all h-1/5 text-2xl md:text-3xl font-bold ${
                  trackIndex === index ? "text-primary" : "text-white"
                }`}
              >
                #{index + 1}
              </div>
            );
          })}
        </div>
        <div className={`col-start-3 col-end-4 animate-fadeInLeft`}>
          {myTopTracks.map((track, index) => {
            return (
              <div
                key={index}
                className={`transition-all cursor-pointer flex justify-end border-b-8 ${
                  userIndex === 1 && trackIndex === index
                    ? "border-primary text-primary"
                    : "border-transparent text-white"
                }`}
                onClick={() => clickTrack(1, index)}
              >
                <div className="w-5/12 md:w-2/5 animate-fadeInleft">
                  <div
                    style={{
                      animationDelay: `${0.5 + index * 0.1}s`,
                    }}
                  >
                    <AlbumCover track={track} />
                  </div>
                </div>
                <div
                  className="w-7/12 md:w-3/5 animate-zoomIn delay-entrance m-auto"
                  style={{
                    animationDelay: `${index * 0.1}s`,
                  }}
                >
                  <div className="text-sm md:text-base line-clamp-2 font-bold">
                    {track?.name}
                  </div>
                  <div className="text-xs md:text-sm line-clamp-1 font-thin">
                    {track.artists[0]?.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <TrackPlayer
        track={
          userIndex === 0
            ? friendTopTracks[trackIndex]
            : myTopTracks[trackIndex]
        }
      />
    </div>
  );
}
