import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import ProfileImage from "../ProfileImage";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function GroupPage({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const processedData = groupData.processed;

  const clickTrack = (user: number, track: number) => {
    if (userIndex !== user || trackIndex !== track) {
      setUserIndex(user);
      setTrackIndex(track);
    }
  };

  const sortedUserIds = Object.keys(processedData.medianPopularity).sort(
    (a, b) =>
      processedData.medianPopularity[a] - processedData.medianPopularity[b]
  );

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">
        Most edgy music tastes
      </div>
      <div className="max-w-lg">
        {sortedUserIds.map((userId, i) => {
          const popularity = processedData.medianPopularity[userId];
          return (
            <>
              <div
                key={i}
                className={`flex justify-center items-center gap-2 cursor-pointer ${
                  userIndex === i ? "text-primary" : ""
                }`}
                onClick={() => clickTrack(i, 0)}
              >
                <div className="text-3xl font-bold">#{i + 1}</div>
                <div className="w-20">
                  <ProfileImage
                    url={groupData.userInfo[userId].profileImage}
                    round={true}
                  />
                  <div>{groupData.userInfo[userId].name}</div>
                </div>
                <div className="grow">
                  <div>Average song popularity:</div>
                  <div className="flex bg-active rounded-full">
                    <span
                      className="bg-primary text-white h-full text-xs rounded-full"
                      style={{ width: `${popularity}%` }}
                    >
                      {Math.round(popularity)}
                    </span>
                  </div>
                </div>
              </div>
              {userIndex !== i ? null : (
                <div className="mb-4 animate-fadeInUp">
                  <div>Their least popular songs:</div>
                  <div className="grid grid-cols-2 gap-2">
                    {processedData.leastPopularTracks[userId].map(
                      (track, j) => {
                        if (j >= 4) return;
                        return (
                          <div
                            key={j}
                            className={`flex gap-x-1 items-center rounded-lg overflow-hidden cursor-pointer bg-background-100 ${
                              userIndex === i && trackIndex === j
                                ? "text-primary bg-background-300"
                                : "hover:bg-background-200"
                            }`}
                            onClick={() => clickTrack(i, j)}
                          >
                            <div className="w-2/5">
                              <AlbumCover track={track} />
                            </div>
                            <div className="w-3/5 text-sm md:text-base">
                              <div className="font-bold line-clamp-2">
                                {track.name}
                              </div>
                              <div className="font-thin line-clamp-1">
                                {track.artists[0].name}
                              </div>
                              <div className="flex h-1 bg-active rounded-full md:m-2 mb-0">
                                <span
                                  className="bg-primary h-full text-xs rounded-full"
                                  style={{ width: `${popularity}%` }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
      <TrackPlayer
        track={
          processedData.leastPopularTracks[sortedUserIds[userIndex]][trackIndex]
        }
      />
    </div>
  );
}
