import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const popularityBar = useRef<HTMLSpanElement>(null);
  const [trackIndex, setTrackIndex] = useState(0);

  const medianPopularity = summaryData.processed.medianPopularity;
  const leastPopularTracks = summaryData.processed.leastPopularTracks;

  useEffect(() => {
    setTimeout(() => {
      if (popularityBar.current) {
        popularityBar.current.style.width = `${medianPopularity}%`;
      }
    }, 0);
  }, []);

  const clickTrack = (index: number) => {
    if (index !== trackIndex) {
      setTrackIndex(index);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="animate-fadeInUp">
        <div className="text-2xl md:text-3xl font-bold mb-2">
          How mainstream is your music taste?
        </div>
        <div className="flex bg-active rounded-full">
          <span
            ref={popularityBar}
            className="bg-primary h-full w-0 text-xs md:text-sm rounded-full transition-all duration-1000"
          >
            {Math.round(medianPopularity)}
          </span>
        </div>
        <div className="flex justify-between">
          <div className="text-xs md:text-sm">Most obscure</div>
          <div className="text-xs md:text-sm">Most mainstream</div>
        </div>
      </div>
      <div
        className="animate-fadeInRight delay-entrance px-4"
        style={{ animationDelay: "1s" }}
      >
        <div className="text-xl md:text-2xl font-bold mt-2">
          Your most hidden gems:
        </div>
        <div className="transition-all flex justify-center flex-wrap">
          {leastPopularTracks.slice(0, 6).map((track, index) => {
            return (
              <div
                key={index}
                onClick={() => clickTrack(index)}
                className={`transition-all cursor-pointer p-1 rounded ${
                  trackIndex === index
                    ? "bg-active md:animate-zoom"
                    : "md:hover:bg-background-200"
                } w-1/3 md:w-1/6`}
              >
                <AlbumCover track={track} />
                <div>
                  <div className="text-sm md:text-base font-bold line-clamp-3">
                    {track.name}
                  </div>
                  <div className="text-xs md:text-sm font-thin line-clamp-1 mb-1">
                    {track.artists[0].name}
                  </div>
                  <div className="text-xs md:text-sm font-thin">
                    Popularity:
                  </div>
                  <div className="flex h-2 w-full bg-active rounded-full">
                    <span
                      className="bg-primary h-full rounded-full"
                      style={{ width: `${track.popularity}%` }}
                    ></span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <TrackPlayer track={leastPopularTracks[trackIndex]} />
    </div>
  );
}
