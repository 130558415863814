import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const [trackIndex, setTrackIndex] = useState(1);

  const topTracks = summaryData.processed.topTracks.slice(0, 5);

  const clickTrack = (index: number) => {
    if (index !== trackIndex) {
      setTrackIndex(index);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div>
        <div className="text-2xl md:text-3xl font-bold mb-2">Top songs</div>
      </div>
      <div className="max-w-md flex flex-col justify-center px-8">
        {topTracks.map((track, index) => {
          return (
            <div
              key={index}
              className={`flex justify-start overflow-hidden cursor-pointer border-b-4 ${
                trackIndex === index
                  ? "border-primary text-primary pointer-events-none"
                  : "border-transparent text-white pointer-events-auto"
              }`}
              onClick={() => clickTrack(index)}
            >
              <div
                className={`w-min transition-all animate-fadeInRight delay-entrance text-2xl md:text-3xl font-bold mr-1 my-auto`}
                style={{
                  animationDelay: `${index * 0.1}s`,
                }}
              >
                #{index + 1}
              </div>
              <div
                className="w-1/4 animate-fadeInRight delay-entrance mr-2"
                style={{
                  animationDelay: `${0.25 + index * 0.1}s`,
                }}
              >
                <AlbumCover track={topTracks[index]} />
              </div>
              <div
                className={`w-1/2 animate-fadeInUp delay-entrance my-auto`}
                style={{
                  animationDelay: `${0.5 + index * 0.1}s`,
                }}
              >
                <div
                  className={`transition-all line-clamp-2 text-base md:text-lg font-bold`}
                >
                  {topTracks[index].name}
                </div>
                <div
                  className={`transition-all line-clamp-1 overflow-hidden text-sm md:text-base font-thin`}
                >
                  {topTracks[index].artists[0].name}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <TrackPlayer track={topTracks[trackIndex]} />
    </div>
  );
}
