import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Friends from "../components/Friends";
import Followers from "../components/Followers";
import { useEffect } from "react";
import { getCookie } from "../helpers/helper";
import { getFriends } from "../helpers/friends";
import { getUserData } from "../helpers/fetchData";
import { ApiResponse } from "../../types/Data";
import HomeButton from "../components/HomeButton";
import FriendSuggestions from "../components/FriendSuggestions";

// Rest of the code...

export default function FriendsPage() {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [error, setError] = useState<Error>();
  const [friends, setFriends] = useState<ApiResponse.Friend[]>();

  const userId = getCookie("userId");

  useEffect(() => {
    fetchFriends(userId);
  }, []);

  const fetchFriends = async (userId: string) => {
    try {
      const friends = await getFriends(userId, true, true);
      setFriends(friends);
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleFollow = (friendId: string) => {
    setFriends((friends) =>
      friends
        ? friends.map((follower) => {
            if (follower.id === friendId) {
              follower.followedByUser = true;
            }
            return follower;
          })
        : []
    );
  };

  const handleUnfollow = (friendId: string) => {
    setFriends((friend) =>
      friend
        ? friend.map((friend) => {
            if (friend.id === friendId) {
              friend.followedByUser = false;
            }
            return friend;
          })
        : []
    );
  };

  return (
    <>
      <div>
        <h1 className="text-2xl md:text-3xl py-4 font-bold text-center">
          Friends
        </h1>
      </div>

      <div className="w-full max-w-screen-md bg-background-100 rounded-lg">
        <div className="flex justify-evenly gap-x-1 text-sm sm:text-base md:text-lg font-bold">
          <div
            className={`transition-all grow cursor-pointer p-2 rounded-t-lg border-b-4 ${
              tabIndex === 0
                ? `text-primary border-primary`
                : `border-transparent hover:border-active hover:bg-background-200`
            }`}
            onClick={() => {
              setTabIndex(0);
            }}
          >
            Following
          </div>
          <div
            className={`transition-all grow cursor-pointer p-2 rounded-t-lg border-b-4 ${
              tabIndex === 1
                ? `text-primary border-primary`
                : `border-transparent hover:border-active hover:bg-background-200`
            }`}
            onClick={() => {
              setTabIndex(1);
            }}
          >
            Followers
          </div>
          <div
            className={`transition-all grow cursor-pointer p-2 rounded-t-lg border-b-4 ${
              tabIndex === 2
                ? `text-primary border-primary`
                : `border-transparent hover:border-active hover:bg-background-200`
            }`}
            onClick={() => {
              setTabIndex(2);
            }}
          >
            People you might know
          </div>
        </div>
        <div className={`${tabIndex === 0 ? "" : "hidden"} md:py-1 md:px-2`}>
          <Friends
            userId={userId}
            following={friends?.filter((friend) => friend.followedByUser)}
            onUnfollow={handleUnfollow}
          />
        </div>
        <div className={`${tabIndex === 1 ? "" : "hidden"} md:py-1 md:px-2`}>
          <Followers
            userId={userId}
            followers={friends?.filter((friend) => friend.followsUser)}
            onFollowBack={handleFollow}
          />
        </div>
        <div className={`${tabIndex === 2 ? "" : "hidden"} md:py-1 md:px-2`}>
          <FriendSuggestions
            userId={userId}
            following={friends?.filter((friend) => !friend.followedByUser)}
            onFollowBack={handleFollow}
          />
        </div>
      </div>

      <HomeButton />
    </>
  );
}
