import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import {
  getGroups,
  createGroup,
  leaveGroup,
  renameGroup,
} from "../helpers/groups";
import ProfileImage from "./ProfileImage";
import Modal from "./Modal";
import ShareModal from "./ShareModal";
import ErrorPopUp from "./ErrorPopUp";
import QRCode from "react-qr-code";
import { getCookie } from "../helpers/helper";
import { ApiResponse } from "../../types/Data";

export default function Groups({ userId }: { userId: string }) {
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const [groupInfo, setGroupInfo] = useState<ApiResponse.AllGroups>();
  const [timeRange, setTimeRange] = useState("mediumTerm");

  // Boolean states for opening modals and loading
  const [isCreateNewGroup, setIsCreateNewGroup] = useState(false);
  const [isRenameGroup, setIsRenameGroup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Refs for input fields
  const groupNameRef = useRef<HTMLInputElement>(null);
  const newGroupNameRef = useRef<HTMLInputElement>(null);

  // Group index for opening modals
  const [viewGroupIndex, setViewGroupIndex] = useState<number>();
  const [confirmLeaveGroup, setConfirmLeaveGroup] = useState(false);

  // Share URL
  const [shareUrl, setShareUrl] = useState<string>();

  const URL = process.env.REACT_APP_URL;

  async function fetchGroupData() {
    try {
      const groupInfo = await getGroups(userId);
      setGroupInfo(groupInfo);
      setIsLoading(false);
    } catch (err) {
      setError(err as Error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchGroupData();
  }, []);

  const handleRenameGroup = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      if (viewGroupIndex === undefined || groupInfo === undefined) {
        throw new Error("Missing data");
      }

      event.preventDefault();
      const newGroupName = newGroupNameRef.current?.value;
      if (newGroupName) {
        setIsLoading(true);
        await renameGroup({
          userId: userId,
          groupId: groupInfo.groups[viewGroupIndex]._id,
          newGroupName: newGroupName,
        }).then(() => {
          setIsRenameGroup(false);
          fetchGroupData();
        });
      }
    } catch (err) {
      setIsLoading(false);
      setError(err as Error);
    }
  };

  const handleCreateGroup = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      event.preventDefault();
      const groupName = groupNameRef.current?.value;

      if (groupName) {
        setIsLoading(true);
        await createGroup({
          userId: userId,
          groupName: groupName,
        }).then(() => {
          setIsCreateNewGroup(false);
          fetchGroupData();
        });
      }
    } catch (err) {
      setIsLoading(false);
      setError(err as Error);
    }
  };

  const handleLeaveGroup = async () => {
    try {
      if (viewGroupIndex === undefined || groupInfo === undefined) {
        throw new Error("Missing data");
      }

      setIsLoading(true);
      await leaveGroup({
        userId: userId,
        groupId: groupInfo.groups[viewGroupIndex]._id,
      }).then(() => {
        setViewGroupIndex(undefined);
        setConfirmLeaveGroup(false);
        fetchGroupData();
      });
    } catch (err) {
      setIsLoading(false);
      setError(err as Error);
    }
  };

  if (groupInfo) {
    return (
      <>
        <div>
          {groupInfo.groups.map((group, index) => {
            return (
              <div
                className="bg-background-200 rounded flex items-center justify-between mx-2 mb-2 p-2 overflow-hidden animate-fadeInRight delay-entrance"
                key={index}
                style={{
                  animationDelay: `${index * 0.1}s`,
                }}
              >
                <div className="flex items-center">
                  {group.userIds.slice(0, 5).map((userId, index) => {
                    return (
                      <div
                        className="w-8 md:w-10 -mr-4 md:-mr-5"
                        key={index}
                        style={{
                          zIndex: `${group.userIds.length - index}`,
                        }}
                      >
                        <ProfileImage
                          key={index}
                          url={groupInfo.userInfo[userId].profileImage}
                          round={true}
                        />
                      </div>
                    );
                  })}
                  {group.userIds.length > 5 && (
                    <div className="ml-5 md:ml-6 px-2 bg-background-300 rounded-full text-primary font-bold text-sm md:text-base">
                      +{group.userIds.length - 5}
                    </div>
                  )}
                </div>
                <div>
                  <div className="text-base md:text-lg font-bold">
                    {group.name ? group.name : "Untitled Group"}
                  </div>
                  <div className="text-xs md:text-sm font-thin">
                    {`${group.userIds.length} member${
                      group.userIds.length > 1 ? "s" : ""
                    }`}
                  </div>
                </div>
                <div className="flex gap-x-1">
                  <button
                    className="transition-all bg-background-300 md:hover:bg-active text-white text-base md:text-lg py-2 px-4 rounded-lg overflow-hidden"
                    onClick={() => {
                      setViewGroupIndex(undefined);
                      setShareUrl(`${URL}/groupInvite/${group._id}`);
                    }}
                  >
                    Share
                  </button>
                  <button
                    className="transition-all bg-background-300 md:hover:bg-active text-primary text-base md:text-lg py-2 px-4 rounded-lg overflow-hidden"
                    onClick={() => {
                      setShareUrl(undefined);
                      setViewGroupIndex(index);
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {groupInfo.groups.length === 0 && (
          <div className="mt-3 mx-auto text-base md:text-lg bold">
            Make a group with multiple friends:
          </div>
        )}
        <button
          className={`transition-all text-base md:text-lg bg-background-200 md:hover:bg-active rounded px-2 py-1 mt-1`}
          onClick={() => {
            setIsCreateNewGroup(true);
          }}
        >
          Create Group
        </button>
        <Modal
          open={isCreateNewGroup}
          onClose={() => {
            setIsCreateNewGroup(false);
          }}
          hideCloseButton={true}
        >
          <>
            <div className="text-lg font-bold mb-2">Create New Group</div>
            <form>
              <label>
                <div className="w-full text-left font-thin">Group Name:</div>
              </label>
              <input
                ref={groupNameRef}
                className="rounded-lg w-80 max-w-full text-base md:text-lg p-2 bg-background-100 text-white"
              />

              <div className="w-full flex text-base md:text-lg mt-2 gap-x-1">
                <button
                  type="submit"
                  className="w-1/2 transition-all bg-background-300 text-primary hover:text-white hover:bg-primary rounded px-2 py-1"
                  onClick={handleCreateGroup}
                  disabled={isLoading}
                >
                  {isLoading ? "..." : "Create"}
                </button>
                <button
                  className="w-1/2 transition-all bg-background-300 text-alert hover:text-white hover:bg-alert rounded px-2 py-1"
                  onClick={() => {
                    setIsCreateNewGroup(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        </Modal>
        {viewGroupIndex !== undefined && (
          <Modal
            open={true}
            onClose={() => {
              setViewGroupIndex(undefined);
              setConfirmLeaveGroup(false);
            }}
            hideCloseButton={true}
          >
            <div className="max-w-sm">
              <div className="text-2xl font-bold">
                <form>
                  {isLoading ? (
                    "..."
                  ) : !isRenameGroup ? (
                    groupInfo.groups[viewGroupIndex]?.name ? (
                      groupInfo.groups[viewGroupIndex].name
                    ) : (
                      "Untitled Group"
                    )
                  ) : (
                    <input
                      ref={newGroupNameRef}
                      className="rounded-lg max-w-full text-base md:text-lg p-2 bg-background-100 text-white"
                    ></input>
                  )}
                  {isLoading ? (
                    <></>
                  ) : !isRenameGroup ? (
                    <button
                      className="ml-1 text-white"
                      onClick={() => {
                        setIsRenameGroup(true);
                      }}
                    >
                      <svg className="w-4 h-4 fill-current" viewBox="0 0 24 24">
                        <path d="M 19.171875 2 C 18.448125 2 17.724375 2.275625 17.171875 2.828125 L 16 4 L 20 8 L 21.171875 6.828125 C 22.275875 5.724125 22.275875 3.933125 21.171875 2.828125 C 20.619375 2.275625 19.895625 2 19.171875 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"></path>
                      </svg>
                    </button>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="ml-2 text-white"
                        onClick={handleRenameGroup}
                      >
                        <svg
                          className="w-4 h-4 fill-current"
                          viewBox="0 0 26 26"
                        >
                          <path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z"></path>
                        </svg>{" "}
                      </button>

                      <button
                        className="ml-2 text-white"
                        onClick={() => {
                          setIsRenameGroup(false);
                        }}
                      >
                        <svg
                          className="w-4 h-4 fill-current"
                          viewBox="0 0 30 30"
                        >
                          <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
                        </svg>
                      </button>
                    </>
                  )}
                </form>
              </div>
              <div className="w-full pt-2 pb-3 border-t-0 border-white/30">
                <div className="font-bold">
                  Click <span className="text-primary">start</span> to see your
                  group insights:
                </div>
                <div className="w-full h-24 flex items-stretch">
                  <div
                    className={`transition-all cursor-pointer text-lg md:text-xl font-bold w-1/3 p-1 rounded flex justify-center items-center overflow-hidden ${
                      timeRange === "shortTerm"
                        ? `bg-active text-primary font-bold animate-zoom`
                        : `md:hover:bg-background-300`
                    }`}
                    onClick={() => {
                      setTimeRange("shortTerm");
                    }}
                  >
                    {/* 30 days */}Last month
                  </div>
                  <div
                    className={`transition-all cursor-pointer text-lg md:text-xl font-bold w-1/3 p-1 rounded flex justify-center items-center overflow-hidden ${
                      timeRange === "mediumTerm"
                        ? `bg-active text-primary font-bold animate-zoom`
                        : `md:hover:bg-background-300`
                    }`}
                    onClick={() => {
                      setTimeRange("mediumTerm");
                    }}
                  >
                    {/* 1/2 year */}This year so far
                  </div>
                  <div
                    className={`transition-all cursor-pointer text-lg md:text-xl font-bold w-1/3 p-1 rounded flex justify-center items-center overflow-hidden ${
                      timeRange === "longTerm"
                        ? `bg-active text-primary font-bold animate-zoom`
                        : `md:hover:bg-background-300`
                    }`}
                    onClick={() => {
                      setTimeRange("longTerm");
                    }}
                  >
                    All
                  </div>
                </div>
                <button
                  className="w-full transition-all text-white bg-primary rounded px-2 py-1 mt-1"
                  onClick={() => {
                    navigate(
                      `/groupinsight/${groupInfo.groups[viewGroupIndex]._id}?time_range=${timeRange}`
                    );
                  }}
                >
                  Start
                </button>
              </div>
              <div className="text-left font-bold">{`Group Members (${groupInfo.groups[viewGroupIndex]?.userIds.length}):`}</div>
              <div className="flex flex-wrap gap-y-1">
                {groupInfo.groups[viewGroupIndex]?.userIds.map(
                  (userId, index) => {
                    return (
                      <a href={`/user/${userId}`} key={index} className="w-1/2">
                        <div className="flex gap-x-1 items-center">
                          <div className="w-1/3">
                            <ProfileImage
                              url={groupInfo.userInfo[userId].profileImage}
                            />
                          </div>
                          <div>{groupInfo.userInfo[userId].name}</div>
                        </div>
                      </a>
                    );
                  }
                )}
              </div>
              <div className="mt-2">
                {!confirmLeaveGroup ? (
                  <button
                    className="text-sm md:text-base font-bold bg-alert text-white p-2 rounded-lg w-1/2 min-w-max animate-fadeInUp"
                    onClick={() => {
                      setConfirmLeaveGroup(true);
                    }}
                  >
                    Leave Group
                  </button>
                ) : (
                  <div className="animate-fadeInDown">
                    <div className="text-base md:text-lg font-bold">
                      Are you sure you want to leave this group?
                    </div>
                    <div className="inline-flex items-center gap-x-1">
                      <button
                        className={`text-base md:text-lg font-bold bg-alert text-white py-2 px-4 rounded`}
                        onClick={handleLeaveGroup}
                        disabled={isLoading}
                      >
                        {isLoading ? "..." : "Yes"}
                      </button>
                      <button
                        className={`text-base md:text-lg font-bold bg-active text-white py-2 px-4 rounded`}
                        onClick={() => {
                          setConfirmLeaveGroup(false);
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        )}
        <ShareModal
          url={shareUrl}
          onClose={() => {
            setShareUrl(undefined);
          }}
        ></ShareModal>
        <ErrorPopUp
          error={error}
          onClose={() => {
            setError(undefined);
          }}
        />
      </>
    );
  } else {
    return <>Loading</>;
  }
}
