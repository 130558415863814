import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const friendData = comparisonData.friend;
  const myData = comparisonData.me;

  const clickTrack = (user: number, index: number) => {
    if (user !== userIndex || index !== trackIndex) {
      setUserIndex(user);
      setTrackIndex(index);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-xl md:text-2xl font-bold">
        The average release year of your top songs are:
      </div>
      <div className="w-full flex justify-evenly mt-3">
        <div>
          <div className="text-2xl md:text-3xl font-bold text-white mb-1 animate-zoom">
            {friendData.averageReleaseYear}
          </div>
          <UserIndicator
            url={friendData.profileImage}
            name={friendData.name}
            playing={userIndex === 0}
          />
        </div>
        <div>
          <div className="text-2xl md:text-3xl font-bold text-white mb-1 animate-zoom">
            {myData.averageReleaseYear}
          </div>
          <UserIndicator url={myData.profileImage} playing={userIndex === 1} />
        </div>
      </div>
      <div className="text-xl md:text-2xl font-bold mb-1">
        Your oldest songs:
      </div>
      <div className="w-full max-w-lg flex justify-evenly gap-x-2">
        <div
          className="w-1/2 animate-fadeInRight delay-entrance"
          style={{
            animationDelay: "0.5s",
          }}
        >
          <div>
            {friendData.oldestTracks.map((track, index) => {
              return (
                <div
                  key={index}
                  className={`flex flex-row-reverse cursor-pointer border-b-8 ${
                    userIndex === 0 && index === trackIndex
                      ? "border-primary text-primary"
                      : "border-transparent"
                  }`}
                  onClick={() => clickTrack(0, index)}
                >
                  <div className="w-1/2 sm:w-2/5">
                    <AlbumCover track={track} />
                  </div>
                  <div className="w-1/2 sm:w-3/5 my-auto">
                    <div className="text-sm md:text-base font-bold line-clamp-2">
                      {track.name}
                    </div>
                    <div className="text-xs md:text-sm font-thin line-clamp-1">
                      {track.artists[0].name}
                    </div>
                    <div className="text-xs md:text-sm text-primary">
                      {track.album.release_date.split("-")[0]}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="w-1/2 animate-fadeInLeft delay-entrance"
          style={{
            animationDelay: "1s",
          }}
        >
          <div>
            {myData.oldestTracks.map((track, index) => {
              return (
                <div
                  key={index}
                  className={`flex cursor-pointer border-b-8 ${
                    userIndex === 1 && index === trackIndex
                      ? "border-primary text-primary"
                      : "border-transparent"
                  }`}
                  onClick={() => clickTrack(1, index)}
                >
                  <div className="w-1/2 sm:w-2/5">
                    <AlbumCover track={track} />
                  </div>
                  <div className="w-1/2 sm:w-3/5 my-auto">
                    <div className="text-sm md:text-base font-bold line-clamp-2">
                      {track.name}
                    </div>
                    <div className="text-xs md:text-sm font-thin line-clamp-1">
                      {track.artists[0].name}
                    </div>
                    <div className="text-xs md:text-sm text-primary">
                      {track.album.release_date.split("-")[0]}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <TrackPlayer
        track={
          userIndex === 0
            ? friendData.oldestTracks[trackIndex]
            : myData.oldestTracks[trackIndex]
        }
      />
    </div>
  );
}
