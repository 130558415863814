import React, { useState, useEffect, useRef } from "react";
import { addFriend } from "../helpers/friends";
import "../App.css";
import ProfileImage from "./ProfileImage";
import Flag from "./Flag";
import ErrorPopUp from "./ErrorPopUp";
import { ApiResponse } from "../../types/Data";

export default function Followers({
  userId,
  onFollowBack,
  followers,
}: {
  userId: string;
  onFollowBack: (friendId: string) => void;
  followers?: ApiResponse.Friend[];
}) {
  const [error, setError] = useState<Error | undefined>();
  const [loadingIds, setLoadingIds] = useState<string[]>([]);

  const onFollow = async (friendId: string) => {
    setLoadingIds((loadingIds) => [...(loadingIds ?? []), friendId]);
    try {
      await addFriend(userId, friendId);
      onFollowBack(friendId);
    } catch (err) {
      setError(err as Error);
    }
    setLoadingIds((loadingIds) => loadingIds?.filter((id) => id !== friendId));
  };

  if (!followers) {
    return <>Loading...</>;
  } else {
    return (
      <>
        <div className="flex flex-wrap items-stretch p-1">
          {followers.length == 0 && (
            <div className="my-2 mx-auto text-base md:text-lg bold">
              No followers yet
            </div>
          )}
          {followers.map((friend, index) => {
            return (
              <div key={index} className="p-0.5 md:p-1 w-full md:w-1/2 ">
                <a href={`/user/${friend.id}`}>
                  <div
                    className="p-1 flex items-center justify-between h-full animate-fadeInUp delay-entrance rounded-lg border border-background-300 bg-background-200 overflow-hidden"
                    style={{ animationDelay: `${index * 0.1}s` }}
                  >
                    <div className="grow flex items-center">
                      <div className="w-12">
                        <ProfileImage url={friend.profileImage} round={true} />
                      </div>
                      <div className="grow text-base md:text-lg line-clamp-1">
                        {`${friend.name} `}
                        <Flag countryCode={friend.country} />
                      </div>
                    </div>
                    {friend.followedByUser ? (
                      <button
                        className="min-w-max px-2 py-3 transition-all bg-background-300 text-white text-sm md:text-base rounded-lg overflow-hidden"
                        disabled={true}
                      >
                        Following
                      </button>
                    ) : (
                      <>
                        {loadingIds.includes(friend.id) ? (
                          <button
                            disabled={true}
                            className="min-w-max px-2 py-3 transition-all bg-background-300 text-sm md:text-base rounded-lg overflow-hidden"
                            onClick={(e) => {
                              e.preventDefault();
                              onFollow(friend.id);
                            }}
                          >
                            ...
                          </button>
                        ) : (
                          <button
                            className="min-w-max px-2 py-3 transition-all bg-background-300 md:hover:bg-active text-primary text-sm md:text-base rounded-lg overflow-hidden"
                            onClick={(e) => {
                              e.preventDefault();
                              onFollow(friend.id);
                            }}
                          >
                            Follow Back
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
        <ErrorPopUp
          error={error}
          onClose={() => {
            setError(undefined);
          }}
        />
      </>
    );
  }
}
