import React, { useEffect, useState } from "react";
import ConcertMap from "../components/ConcertMap";
import { getCookie } from "../helpers/helper";
import { getConcerts, getConcertsStream } from "../helpers/concerts";
import config from "../config";
import { mergeConcerts } from "../helpers/concerts";
import { ApiResponse } from "../../types/Data";
import HomeButton from "../components/HomeButton";

export default function Concerts() {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);
  const [concerts, setConcerts] = useState<ApiResponse.Concert>({
    groupedEvents: {},
    artistData: {},
    userData: {},
  });

  const userId = getCookie("userId");

  useEffect(() => {
    const fetchBookmarkedEvents = async () => {
      try {
        const response = await getConcerts(userId);
        setConcerts(response);
      } catch (e) {
        setError(e as Error);
      }
    };

    const fetchConcerts = async () => {
      try {
        const response = await getConcertsStream(userId);

        if (!response.body) {
          throw new Error("Response body is null");
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setIsLoading(false);
            break;
          }

          const data = decoder.decode(value, { stream: true });

          try {
            const parsedData = JSON.parse(data);
            setConcerts((prevConcerts) =>
              mergeConcerts(prevConcerts, parsedData)
            );
          } catch (e) {
            // console.log("Error parsing JSON", e);
          }
        }
      } catch (e) {
        setIsLoading(false);
        console.error("Error getting concerts:", e);
        setError(e as Error);
      }
    };

    fetchBookmarkedEvents().then(() => {
      fetchConcerts();
    });
  }, [userId]);

  return (
    <>
      <div>
        <h1 className="text-2xl md:text-3xl py-4 font-bold text-center">
          Concerts
        </h1>
      </div>

      <div className="w-full h-full mx-auto max-w-screen-xl p-2 pb-8 md:p-4 bg-background-100 rounded-lg flex-1">
        <ConcertMap userId={userId} concerts={concerts} />
      </div>

      <HomeButton />
    </>
  );
}
