import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getProfileData, getUserData } from "../helpers/fetchData";
import { ApiResponse, TimeRanges } from "../../types/Data";
import ProfileImage from "../components/ProfileImage";
import Flag from "../components/Flag";
import ErrorPage from "./Error";
import { getCookie } from "../helpers/helper";
import AlbumCover from "../components/AlbumCover";
import TrackPlayer from "../components/TrackPlayer";
import ArtistImage from "../components/ArtistImage";
import { addFriend, getFriends, removeFriend } from "../helpers/friends";
import Modal from "../components/Modal";
import { isLoggedIn, logInSpotify } from "../helpers/login";
import FriendsList from "../components/FriendsList";
import TimeRangeSelector from "../components/TimeRangeSelector";

const Profile = () => {
  const { userId } = useParams();
  const [error, setError] = useState<Error>();
  const [user, setUser] = useState<ApiResponse.User>();
  const [me, setMe] = useState<ApiResponse.User>();
  const [profileData, setProfileData] = useState<ApiResponse.ProfilePageData>();
  const [friends, setFriends] = useState<ApiResponse.Friend[]>();
  const [timeRange, setTimeRange] = useState<TimeRanges>("mediumTerm");
  const [trackIndex, setTrackIndex] = useState<number>();
  const [selectedArtistId, setSelectedArtistId] = useState<string>();
  const [selectedGenre, setSelectedGenre] = useState<string>();

  // Modal states
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowing, setShowFollowing] = useState(false);

  // Comparison / summary page time range selector
  const [openSummary, setOpenSummary] = useState(false);
  const [openComparison, setOpenComparison] = useState(false);

  useEffect(() => {
    async function fetchData(userId?: string) {
      try {
        if (!userId) {
          throw new Error("User ID not found");
        }
        const user = await getUserData(userId, false);
        setUser(user);
        const friends = await getFriends(userId, false, true);
        setFriends(friends);
        if (isLoggedIn()) {
          const me = await getUserData(getCookie("userId"), false);
          setMe(me);
        }
        const data = await getProfileData(userId);
        setProfileData(data);
      } catch (err) {
        setError(err as Error);
      }
    }
    fetchData(userId);
  }, []);

  const clickTrack = (index: number) => {
    setSelectedArtistId(undefined);
    setSelectedGenre(undefined);
    if (trackIndex === index) {
      setTrackIndex(undefined);
    } else {
      setTrackIndex(index);
    }
  };

  const clickArtist = (artistId: string) => {
    setTrackIndex(undefined);
    setSelectedGenre(undefined);
    if (selectedArtistId === artistId) {
      setSelectedArtistId(undefined);
    } else {
      setSelectedArtistId(artistId);
    }
  };

  const clickGenre = (genre: string) => {
    setTrackIndex(undefined);
    setSelectedArtistId(undefined);
    if (selectedGenre === genre) {
      setSelectedGenre(undefined);
    } else {
      setSelectedGenre(genre);
    }
  };

  const selectTimeRange = (range: TimeRanges) => {
    setTrackIndex(undefined);
    setSelectedArtistId(undefined);
    setSelectedGenre(undefined);
    setTimeRange(range);
  };

  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const onFollow = async (friendId: string) => {
    const userId = me?.id;
    if (userId) {
      setLoadingIds((loadingIds) => [...(loadingIds ?? []), friendId]);
      try {
        await addFriend(userId, friendId);
        setMe((me) => {
          if (me) {
            const newMe = { ...me };
            newMe.following?.push(friendId);
            return newMe;
          }
        });
        setUser((user) => {
          if (user) {
            const newUser = { ...user };
            newUser.followers?.push(userId);
            return newUser;
          }
        });
      } catch (err) {
        setError(err as Error);
      }
      setLoadingIds((loadingIds) =>
        loadingIds?.filter((id) => id !== friendId)
      );
    }
  };

  const onUnfollow = async (friendId: string) => {
    const userId = me?.id;
    if (userId) {
      setLoadingIds((loadingIds) => [...(loadingIds ?? []), friendId]);
      try {
        await removeFriend(userId, friendId);
        setMe((me) => {
          if (me) {
            const newMe = { ...me };
            newMe.following = newMe.following?.filter((id) => id !== friendId);
            return newMe;
          }
        });
        setUser((user) => {
          if (user) {
            const newUser = { ...user };
            newUser.followers = newUser.followers?.filter(
              (id) => id !== userId
            );
            return newUser;
          }
        });
      } catch (err) {
        setError(err as Error);
      }
      setLoadingIds((loadingIds) =>
        loadingIds?.filter((id) => id !== friendId)
      );
    }
  };

  if (error) {
    return <ErrorPage error={error} />;
  } else if (!user) {
    return <div>Loading...</div>;
  } else
    return (
      <>
        <div className="max-w-screen-md">
          <div className="w-full flex justify-center gap-x-2 mt-4">
            <div className="w-24 md:w-36">
              <ProfileImage url={user.profileImage} round={true} />
              <div className="text-base md:text-lg font-bold mb-1">
                {`${user.name} `}
                <Flag countryCode={user.country} />
              </div>
            </div>
            <div className="animate-fadeInUp my-auto text-sm md:text-base">
              <div className="mb-1">Select time range:</div>
              <div className="flex mx-auto cursor-pointer select-none bg-background-200 rounded-full w-56 md:w-72">
                <div
                  onClick={() => selectTimeRange("shortTerm")}
                  className={`w-1/3 py-1 md:py-2 px-2 md:px-3 rounded-full flex items-center justify-center ${
                    timeRange === "shortTerm"
                      ? "text-primary bg-background-300 animate-zoom"
                      : "bg-background-200"
                  }`}
                >
                  {/* 30 days */}Last month
                </div>
                <div
                  onClick={() => selectTimeRange("mediumTerm")}
                  className={`w-1/3 py-1 md:py-2 px-2 md:px-3 rounded-full flex items-center justify-center ${
                    timeRange === "mediumTerm"
                      ? "text-primary bg-background-300 animate-zoom"
                      : "bg-background-200"
                  }`}
                >
                  {/* 1/2 year */}This year so far
                </div>
                <div
                  onClick={() => selectTimeRange("longTerm")}
                  className={`w-1/3 py-1 md:py-2 px-2 md:px-3 rounded-full flex items-center justify-center ${
                    timeRange === "longTerm"
                      ? "text-primary bg-background-300 animate-zoom"
                      : "bg-background-200"
                  }`}
                >
                  All time
                </div>
              </div>
              {me && userId && userId != me?.id ? (
                loadingIds?.includes(userId) ? (
                  <button className="w-4/5 bg-background-200 border border-background-300 rounded-lg mt-2 md:mt-5 py-1">
                    Loading...
                  </button>
                ) : me.following?.includes(userId) ? (
                  <button
                    onClick={() => {
                      onUnfollow(userId);
                    }}
                    className="w-4/5 bg-background-200 border border-background-300 rounded-lg mt-2 md:mt-5 py-1"
                  >
                    Unfollow
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      onFollow(userId);
                    }}
                    className="w-4/5 bg-primary border border-background-300 rounded-lg mt-2 md:mt-5 py-1"
                  >
                    Follow
                  </button>
                )
              ) : null}
            </div>
          </div>
          <div className="w-full flex gap-x-2 text-base md:text-lg my-2 md:my-6 pl-2 select-none">
            <div
              onClick={() => {
                setShowFollowing(false);
                setShowFollowers(true);
              }}
              className="w-1/4 p-2 md:p-6 rounded-lg flex flex-col items-center justify-center bg-[#006450] hover:bg-opacity-75 transition-colors cursor-pointer"
            >
              <div className="font-bold text-lg md:text-xl">
                {user.followers?.length ?? 0}
              </div>
              <div>Followers</div>
            </div>
            <div
              onClick={() => {
                setShowFollowers(false);
                setShowFollowing(true);
              }}
              className="w-1/4 p-2 md:p-6 rounded-lg flex flex-col items-center justify-center bg-[#283ea3] hover:bg-opacity-75 transition-colors cursor-pointer"
            >
              <div className="font-bold text-lg md:text-xl">
                {user.following?.length ?? 0}
              </div>
              <div>Following</div>
            </div>
            {me && userId != me?.id ? (
              <>
                <div
                  onClick={() => {
                    setOpenSummary(true);
                  }}
                  className="w-1/4 p-2 md:p-6 rounded-lg flex flex-col items-center justify-center bg-[#8400e7] hover:bg-opacity-75 transition-colors cursor-pointer"
                >
                  <div>See their wrapped</div>
                  <TimeRangeSelector
                    open={openSummary}
                    compare={false}
                    friendId={userId}
                    onClose={() => {
                      setOpenSummary(false);
                    }}
                  />
                </div>

                <div
                  onClick={() => {
                    setOpenComparison(true);
                  }}
                  className="w-1/4 p-2 md:p-6 rounded-lg flex flex-col items-center justify-center bg-[#e8115b] hover:bg-opacity-75 transition-colors cursor-pointer"
                >
                  <div>Compare your data</div>
                  <TimeRangeSelector
                    open={openComparison}
                    compare={true}
                    friendId={userId}
                    onClose={() => {
                      setOpenComparison(false);
                    }}
                  />
                </div>
              </>
            ) : (
              !isLoggedIn() && (
                <>
                  <div
                    onClick={() => {
                      logInSpotify(`/user/${userId}`);
                    }}
                    className="w-1/4 p-2 md:p-6 text-xs md:text-base rounded-lg flex flex-col items-center justify-center bg-[#8400e7] hover:bg-opacity-75 transition-colors cursor-pointer"
                  >
                    <div>Log in to see their wrapped</div>
                  </div>

                  <div
                    onClick={() => {
                      logInSpotify(`/user/${userId}`);
                    }}
                    className="w-1/4 p-2 md:p-6 text-xs md:text-base rounded-lg flex flex-col items-center justify-center bg-[#e8115b] hover:bg-opacity-75 transition-colors cursor-pointer"
                  >
                    <div>Log in to compare your data</div>
                  </div>
                </>
              )
            )}
          </div>
          {profileData && Object.keys(profileData).length > 0 && (
            <>
              <div className="w-full px-2 space-y-4">
                <div
                  className="animate-fadeInUp delay-entrance"
                  style={{ animationDelay: "0.1s" }}
                >
                  <div className="text-xl md:text-2xl font-bold flex gap-x-2 mb-1">
                    Top songs
                    <span className="text-primary flex items-center">
                      {timeRange === "shortTerm"
                        ? "last month"
                        : timeRange === "mediumTerm"
                        ? "this year so far"
                        : "of all time"}
                    </span>
                  </div>
                  <div className="transition-all flex justify-center flex-wrap">
                    {profileData[timeRange].topTracks.map((track, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => clickTrack(index)}
                          className={`transition-all cursor-pointer p-0.5 md:p-1 rounded ${
                            trackIndex === index
                              ? "bg-active md:animate-zoom text-primary"
                              : "md:hover:bg-background-200"
                          } w-1/5`}
                        >
                          <AlbumCover track={track} />
                          <div className="">
                            <div className="text-sm md:text-base font-bold line-clamp-3">
                              {track.name}
                            </div>
                            <div className="text-xs md:text-sm font-thin line-clamp-1 mb-1">
                              {track.artists[0].name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="animate-fadeInUp delay-entrance"
                  style={{ animationDelay: "0.2s" }}
                >
                  <div className="text-xl md:text-2xl font-bold flex gap-x-2 mb-1">
                    Top artists
                    <span className="text-primary flex items-center">
                      {timeRange === "shortTerm"
                        ? "last month"
                        : timeRange === "mediumTerm"
                        ? "this year so far"
                        : "of all time"}
                    </span>
                  </div>
                  <div className="transition-all flex flex-wrap">
                    {profileData[timeRange].topArtists.map((artist, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => clickArtist(artist.id)}
                          className={`transition-all cursor-pointer p-0.5 md:p-1 rounded ${
                            selectedArtistId === artist.id
                              ? "bg-active md:animate-zoom text-primary"
                              : "md:hover:bg-background-200"
                          } w-1/5`}
                        >
                          <ArtistImage artist={artist} />
                          <div className="text-sm md:text-base font-bold line-clamp-3">
                            {artist.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="animate-fadeInUp delay-entrance"
                  style={{ animationDelay: "0.3s" }}
                >
                  <div className="text-xl md:text-2xl font-bold flex gap-x-2 mb-1">
                    Top genres
                    <span className="text-primary flex items-center">
                      {timeRange === "shortTerm"
                        ? "last month"
                        : timeRange === "mediumTerm"
                        ? "this year so far"
                        : "of all time"}
                    </span>
                  </div>
                  <div className="transition-all flex flex-wrap">
                    {profileData[timeRange].topGenres.map((genre, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => clickGenre(genre)}
                          className={`min-h-24 transition-all cursor-pointer p-0.5 md:p-1 rounded flex items-center justify-center ${
                            selectedGenre === genre
                              ? "bg-active md:animate-zoom text-primary"
                              : "md:hover:bg-background-200"
                          } w-1/5`}
                        >
                          <div className="text-lg md:text-xl font-bold line-clamp-3">
                            {genre}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {profileData && Object.keys(profileData).length > 0 && (
          <TrackPlayer
            track={
              trackIndex !== undefined
                ? profileData[timeRange].topTracks[trackIndex]
                : selectedArtistId !== undefined
                ? profileData[timeRange].artistTracks[selectedArtistId][
                    Math.floor(
                      Math.random() *
                        profileData[timeRange].artistTracks[selectedArtistId]
                          .length
                    )
                  ]
                : selectedGenre !== undefined
                ? profileData[timeRange].genreTracks[selectedGenre][
                    Math.floor(
                      Math.random() *
                        profileData[timeRange].genreTracks[selectedGenre].length
                    )
                  ]
                : undefined
            }
          />
        )}
        <Modal
          open={showFollowers}
          onClose={() => {
            setShowFollowers(false);
          }}
        >
          <FriendsList
            user={me}
            title={`${user.name}'s followers`}
            friends={friends?.filter((friend) =>
              user.followers?.includes(friend.id)
            )}
            onFollow={onFollow}
            loadingIds={loadingIds}
          />
        </Modal>
        <Modal
          open={showFollowing}
          onClose={() => {
            setShowFollowing(false);
          }}
        >
          <FriendsList
            user={me}
            title={`${user.name} follows`}
            friends={friends?.filter((friend) =>
              user.following?.includes(friend.id)
            )}
            onFollow={onFollow}
            loadingIds={loadingIds}
          />
        </Modal>
      </>
    );
};

export default Profile;
