import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import WordCloud from "../WordCloud";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [userIndex, setUserIndex] = useState(0);

  const myData = comparisonData.me;
  const friendData = comparisonData.friend;

  const clickUser = (user: number) => {
    if (user !== userIndex) {
      setUserIndex(user);
    }
  };

  const getRandomTrackUrl = (user: number) => {
    let track;
    if (user === 0) {
      track = comparisonData.friend.randomTracks[0];
    } else {
      track = comparisonData.me.randomTracks[0];
    }
    return track;
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold mb-2">
        Your genres at a glance
      </div>
      <div
        onClick={() => {
          clickUser(0);
        }}
      >
        <UserIndicator
          url={comparisonData.friend.profileImage}
          name={comparisonData.friend.name}
          playing={userIndex === 0 ? true : false}
        />
        <div className="max-w-xl">
          <WordCloud genreScores={friendData.genreScores} />
        </div>
      </div>
      <div
        className="mt-8"
        onClick={() => {
          clickUser(1);
        }}
      >
        <UserIndicator
          url={comparisonData.me.profileImage}
          name={"You"}
          playing={userIndex === 1 ? true : false}
        />
        <div className="max-w-md">
          <WordCloud genreScores={myData.genreScores} />
        </div>
      </div>
      <TrackPlayer track={getRandomTrackUrl(userIndex)} />
    </div>
  );
}
