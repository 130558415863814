import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import playIcon from "../../images/play-button.svg";
import TrackPlayer from "../TrackPlayer";
import Artist from "../Artist";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";
import ArtistImage from "../ArtistImage";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const [artistSelected, setArtistSelected] =
    useState<SpotifyApi.ArtistObjectFull>();
  const [artistIndex, setArtistIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const topArtists = summaryData.processed.topArtists.slice(0, 5);
  const artistTracks = topArtists.map(
    (artist) => summaryData.processed.artistTracks[artist.id]
  );

  const clickArtist = (index: number) => {
    if (index !== artistIndex) {
      setArtistIndex(index);
      setTrackIndex(Math.floor(Math.random() * artistTracks[index].length));
    } else {
      setArtistSelected(topArtists[artistIndex]);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold animate-fadeInUp mb-2">
        Top Artists
      </div>
      <div className="max-w-md grid grid-cols-3 grid-rows-4 gap-x-2 gap-y-1 px-8">
        {topArtists.map((artist, index) => {
          return (
            <div
              key={index}
              className={`transition-all cursor-pointer ${
                artistIndex === index
                  ? `animate-fadeInUp col-start-1 col-end-3 row-start-1 row-end-3`
                  : `animate-fadeInRight col-start-3 col-end-4`
              }`}
              onClick={() => clickArtist(index)}
            >
              <div
                className={`w-full animate-fadeInRight delay-entrance`}
                style={{
                  animationDelay: `${index * 0.1}s`,
                }}
              >
                <ArtistImage artist={artist} />
              </div>
              <div className={`line-clamp-1 text-xs md:text-sm`}>
                {artist.name}
              </div>
            </div>
          );
        })}

        <div className="col-start-1 col-end-3 row-start-3 row-end-5">
          {topArtists.map((artist, index) => {
            return (
              <div
                key={index}
                className={`transition-all cursor-pointer flex items-center gap-x-1 mb-1 ${
                  artistIndex === index
                    ? `border-b-4 border-primary md:translate-x-2`
                    : "md:-translate-x-7"
                }`}
                onClick={() => clickArtist(index)}
              >
                <img
                  className={`transition-all aspect-square object-cover w-6 ${
                    artistIndex === index ? "opacity-100" : "opacity-0"
                  } hidden md:block`}
                  src={playIcon}
                />
                <div className={`text-xl md:text-2xl font-bold`}>
                  #{index + 1}
                </div>
                <div className={`text-base md:text-lg line-clamp-2`}>
                  {artist.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Artist
        open={artistSelected ? true : false}
        onClose={() => setArtistSelected(undefined)}
        artist={artistSelected}
      />
      <TrackPlayer track={artistTracks[artistIndex][trackIndex]} />
    </div>
  );
}
