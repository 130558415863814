import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isLoggedIn, logOut } from "../helpers/login";
import { checkAndRefreshTokens } from "../helpers/tokens";
import Loading from "../components/Loading";

export const PrivateRoute = ({ children }) => {
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    async function authenticateLogin() {
      try {
        setIsAuthenticated(false);
        if (isLoggedIn()) {
          // refresh tokens if they have expired
          await checkAndRefreshTokens();
          setIsAuthenticated(true);
        }
      } catch (err) {
        logOut();
      }
    }

    authenticateLogin();
  }, [pathname]);

  if (!isLoggedIn()) {
    return <Navigate to="/"></Navigate>;
  } else if (!isAuthenticated) {
    return <Loading />;
  } else {
    return children;
  }
};
