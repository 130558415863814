import React from "react";
import { useNavigate } from "react-router-dom";

export default function HomeButton() {
  const navigate = useNavigate();
  return (
    <button
      className="bg-primary text-white text-sm md:text-base rounded-full p-4 flex items-center justify-center gap-x-2 mx-auto my-2"
      onClick={() => {
        navigate("/dashboard");
      }}
    >
      Go Back
    </button>
  );
}
