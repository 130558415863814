import React from "react";
import "../App.css";

export default function NavigationSideBar({
  pages,
  currentPage,
  handlePageChange,
}: {
  pages: string[];
  currentPage: number;
  handlePageChange: (index: number) => void;
}) {
  return (
    <ol
      className={`transition-all hidden lg:flex fixed left-0 top-0 bottom-0 flex-col justify-center items-start ml-4`}
    >
      {pages.map((page, index) => {
        return (
          <li
            key={index}
            className={`transition-all cursor-pointer ${
              index === currentPage
                ? `text-primary text-2xl md:text-3xl font-bold`
                : `text-white text-base md:text-lg`
            }`}
            onClick={() => handlePageChange(index)}
          >
            {page}
          </li>
        );
      })}
    </ol>
  );
}
