import React from "react";
import "../App.css";
import ProfileImage from "./ProfileImage";

export default function UserIndicator({
  url,
  name,
  playing,
}: {
  url?: string;
  name?: string;
  playing?: boolean;
}) {
  if (!name) {
    name = "You";
  }

  return (
    <div className="inline-flex gap-x-1">
      <div className="w-6 h-6">
        <ProfileImage url={url} round={true} />
      </div>
      <div
        className={`transition-all text-sm md:text-base font-bold line-clamp-1 ${
          playing ? "text-primary" : "text-white"
        }`}
      >
        {name}
      </div>
    </div>
  );
}
