import React from "react";
import "../App.css";
import styles from "./Flag.module.css";

export default function Flag({ countryCode }: { countryCode?: string }) {
  if (countryCode) {
    return countryFlags[countryCode];
  } else {
    return <></>;
  }
}

const countryFlags: {
  [key: string]: JSX.Element;
} = {
  AF: <span className={styles.flag}>&#x1F1E6;&#x1F1EB;</span>,
  AL: <span className={styles.flag}>&#x1F1E6;&#x1F1FD;</span>,
  DZ: <span className={styles.flag}>&#x1F1E9;&#x1F1FF;</span>,
  AD: <span className={styles.flag}>&#x1F1E6;&#x1F1E9;</span>,
  AO: <span className={styles.flag}>&#x1F1E6;&#x1F1F4;</span>,
  AI: <span className={styles.flag}>&#x1F1E6;&#x1F1EE;</span>,
  AG: <span className={styles.flag}>&#x1F1E6;&#x1F1EC;</span>,
  AR: <span className={styles.flag}>&#x1F1E6;&#x1F1F7;</span>,
  AM: <span className={styles.flag}>&#x1F1E6;&#x1F1F2;</span>,
  AW: <span className={styles.flag}>&#x1F1E6;&#x1F1FC;</span>,
  AC: <span className={styles.flag}>&#x1F1E6;&#x1F1E8;</span>,
  AU: <span className={styles.flag}>&#x1F1E6;&#x1F1FA;</span>,
  AT: <span className={styles.flag}>&#x1F1E6;&#x1F1F9;</span>,
  AZ: <span className={styles.flag}>&#x1F1E6;&#x1F1FF;</span>,
  BS: <span className={styles.flag}>&#x1F1E7;&#x1F1F8;</span>,
  BH: <span className={styles.flag}>&#x1F1E7;&#x1F1ED;</span>,
  BD: <span className={styles.flag}>&#x1F1E7;&#x1F1E9;</span>,
  BB: <span className={styles.flag}>&#x1F1E7;&#x1F1E7;</span>,
  BY: <span className={styles.flag}>&#x1F1E7;&#x1F1FE;</span>,
  BE: <span className={styles.flag}>&#x1F1E7;&#x1F1EA;</span>,
  BZ: <span className={styles.flag}>&#x1F1E7;&#x1F1FF;</span>,
  BJ: <span className={styles.flag}>&#x1F1E7;&#x1F1EF;</span>,
  BM: <span className={styles.flag}>&#x1F1E7;&#x1F1F2;</span>,
  BT: <span className={styles.flag}>&#x1F1E7;&#x1F1F9;</span>,
  BO: <span className={styles.flag}>&#x1F1E7;&#x1F1F4;</span>,
  BQ: <span className={styles.flag}>&#x1F1E7;&#x1F1F6;</span>,
  BA: <span className={styles.flag}>&#x1F1E7;&#x1F1E6;</span>,
  BW: <span className={styles.flag}>&#x1F1E7;&#x1F1FC;</span>,
  BR: <span className={styles.flag}>&#x1F1E7;&#x1F1F7;</span>,
  IO: <span className={styles.flag}>&#x1F1EE;&#x1F1F4;</span>,
  BN: <span className={styles.flag}>&#x1F1E7;&#x1F1F3;</span>,
  BG: <span className={styles.flag}>&#x1F1E7;&#x1F1EC;</span>,
  BF: <span className={styles.flag}>&#x1F1E7;&#x1F1EB;</span>,
  BI: <span className={styles.flag}>&#x1F1E7;&#x1F1EE;</span>,
  KH: <span className={styles.flag}>&#x1F1F0;&#x1F1ED;</span>,
  CM: <span className={styles.flag}>&#x1F1E8;&#x1F1F2;</span>,
  CA: <span className={styles.flag}>&#x1F1E8;&#x1F1E6;</span>,
  CV: <span className={styles.flag}>&#x1F1E8;&#x1F1FB;</span>,
  KY: <span className={styles.flag}>&#x1F1F0;&#x1F1FE;</span>,
  CF: <span className={styles.flag}>&#x1F1E8;&#x1F1EB;</span>,
  TD: <span className={styles.flag}>&#x1F1F9;&#x1F1E9;</span>,
  CL: <span className={styles.flag}>&#x1F1E8;&#x1F1F1;</span>,
  CN: <span className={styles.flag}>&#x1F1E8;&#x1F1F3;</span>,
  CO: <span className={styles.flag}>&#x1F1E8;&#x1F1F4;</span>,
  KM: <span className={styles.flag}>&#x1F1F0;&#x1F1F2;</span>,
  CG: <span className={styles.flag}>&#x1F1E8;&#x1F1EC;</span>,
  CD: <span className={styles.flag}>&#x1F1E8;&#x1F1E9;</span>,
  CK: <span className={styles.flag}>&#x1F1E8;&#x1F1F0;</span>,
  CR: <span className={styles.flag}>&#x1F1E8;&#x1F1F7;</span>,
  HR: <span className={styles.flag}>&#x1F1ED;&#x1F1F7;</span>,
  CU: <span className={styles.flag}>&#x1F1E8;&#x1F1FA;</span>,
  CW: <span className={styles.flag}>&#x1F1E8;&#x1F1FC;</span>,
  CY: <span className={styles.flag}>&#x1F1E8;&#x1F1FE;</span>,
  CZ: <span className={styles.flag}>&#x1F1E8;&#x1F1FF;</span>,
  DK: <span className={styles.flag}>&#x1F1E9;&#x1F1F0;</span>,
  DJ: <span className={styles.flag}>&#x1F1E9;&#x1F1EF;</span>,
  DM: <span className={styles.flag}>&#x1F1E9;&#x1F1F2;</span>,
  DO: <span className={styles.flag}>&#x1F1E9;&#x1F1F4;</span>,
  TL: <span className={styles.flag}>&#x1F1F9;&#x1F1F1;</span>,
  EC: <span className={styles.flag}>&#x1F1EA;&#x1F1E8;</span>,
  EG: <span className={styles.flag}>&#x1F1EA;&#x1F1EC;</span>,
  SV: <span className={styles.flag}>&#x1F1F8;&#x1F1FB;</span>,
  GQ: <span className={styles.flag}>&#x1F1EC;&#x1F1F6;</span>,
  ER: <span className={styles.flag}>&#x1F1EA;&#x1F1F7;</span>,
  EE: <span className={styles.flag}>&#x1F1EA;&#x1F1EA;</span>,
  SZ: <span className={styles.flag}>&#x1F1F8;&#x1F1FF;</span>,
  ET: <span className={styles.flag}>&#x1F1EA;&#x1F1F9;</span>,
  FK: <span className={styles.flag}>&#x1F1EB;&#x1F1F0;</span>,
  FO: <span className={styles.flag}>&#x1F1EB;&#x1F1F4;</span>,
  FJ: <span className={styles.flag}>&#x1F1EB;&#x1F1EF;</span>,
  FI: <span className={styles.flag}>&#x1F1EB;&#x1F1EE;</span>,
  FR: <span className={styles.flag}>&#x1F1EB;&#x1F1F7;</span>,
  GF: <span className={styles.flag}>&#x1F1EC;&#x1F1EB;</span>,
  PF: <span className={styles.flag}>&#x1F1F5;&#x1F1EB;</span>,
  GA: <span className={styles.flag}>&#x1F1EC;&#x1F1E6;</span>,
  GM: <span className={styles.flag}>&#x1F1EC;&#x1F1F2;</span>,
  GE: <span className={styles.flag}>&#x1F1EC;&#x1F1EA;</span>,
  DE: <span className={styles.flag}>&#x1F1E9;&#x1F1EA;</span>,
  GH: <span className={styles.flag}>&#x1F1EC;&#x1F1ED;</span>,
  GI: <span className={styles.flag}>&#x1F1EC;&#x1F1EE;</span>,
  GR: <span className={styles.flag}>&#x1F1EC;&#x1F1F7;</span>,
  GL: <span className={styles.flag}>&#x1F1EC;&#x1F1F1;</span>,
  GD: <span className={styles.flag}>&#x1F1EC;&#x1F1E9;</span>,
  GP: <span className={styles.flag}>&#x1F1EC;&#x1F1F5;</span>,
  GU: <span className={styles.flag}>&#x1F1EC;&#x1F1FA;</span>,
  GT: <span className={styles.flag}>&#x1F1EC;&#x1F1F9;</span>,
  GN: <span className={styles.flag}>&#x1F1EC;&#x1F1F3;</span>,
  GW: <span className={styles.flag}>&#x1F1EC;&#x1F1FC;</span>,
  GY: <span className={styles.flag}>&#x1F1EC;&#x1F1FE;</span>,
  HT: <span className={styles.flag}>&#x1F1ED;&#x1F1F9;</span>,
  HN: <span className={styles.flag}>&#x1F1ED;&#x1F1F3;</span>,
  HK: <span className={styles.flag}>&#x1F1ED;&#x1F1F0;</span>,
  HU: <span className={styles.flag}>&#x1F1ED;&#x1F1FA;</span>,
  IS: <span className={styles.flag}>&#x1F1EE;&#x1F1F8;</span>,
  IN: <span className={styles.flag}>&#x1F1EE;&#x1F1F3;</span>,
  ID: <span className={styles.flag}>&#x1F1EE;&#x1F1E9;</span>,
  IR: <span className={styles.flag}>&#x1F1EE;&#x1F1F7;</span>,
  IQ: <span className={styles.flag}>&#x1F1EE;&#x1F1F6;</span>,
  IE: <span className={styles.flag}>&#x1F1EE;&#x1F1EA;</span>,
  IL: <span className={styles.flag}>&#x1F1EE;&#x1F1F1;</span>,
  IT: <span className={styles.flag}>&#x1F1EE;&#x1F1F9;</span>,
  CI: <span className={styles.flag}>&#x1F1E8;&#x1F1EE;</span>,
  JM: <span className={styles.flag}>&#x1F1EF;&#x1F1F2;</span>,
  JP: <span className={styles.flag}>&#x1F1EF;&#x1F1F5;</span>,
  JO: <span className={styles.flag}>&#x1F1EF;&#x1F1F4;</span>,
  KZ: <span className={styles.flag}>&#x1F1F0;&#x1F1FF;</span>,
  KE: <span className={styles.flag}>&#x1F1F0;&#x1F1EA;</span>,
  KI: <span className={styles.flag}>&#x1F1F0;&#x1F1EE;</span>,
  KP: <span className={styles.flag}>&#x1F1F0;&#x1F1F5;</span>,
  KR: <span className={styles.flag}>&#x1F1F0;&#x1F1F7;</span>,
  XK: <span className={styles.flag}>&#x1F1FD;&#x1F1F0;</span>,
  KW: <span className={styles.flag}>&#x1F1F0;&#x1F1FC;</span>,
  KG: <span className={styles.flag}>&#x1F1F0;&#x1F1EC;</span>,
  LA: <span className={styles.flag}>&#x1F1F1;&#x1F1E6;</span>,
  LV: <span className={styles.flag}>&#x1F1F1;&#x1F1FB;</span>,
  LB: <span className={styles.flag}>&#x1F1F1;&#x1F1E7;</span>,
  LS: <span className={styles.flag}>&#x1F1F1;&#x1F1F8;</span>,
  LR: <span className={styles.flag}>&#x1F1F1;&#x1F1F7;</span>,
  LY: <span className={styles.flag}>&#x1F1F1;&#x1F1FE;</span>,
  LI: <span className={styles.flag}>&#x1F1F1;&#x1F1EE;</span>,
  LT: <span className={styles.flag}>&#x1F1F1;&#x1F1F9;</span>,
  LU: <span className={styles.flag}>&#x1F1F1;&#x1F1FA;</span>,
  MO: <span className={styles.flag}>&#x1F1F2;&#x1F1F4;</span>,
  MK: <span className={styles.flag}>&#x1F1F2;&#x1F1F0;</span>,
  MG: <span className={styles.flag}>&#x1F1F2;&#x1F1EC;</span>,
  MW: <span className={styles.flag}>&#x1F1F2;&#x1F1FC;</span>,
  MY: <span className={styles.flag}>&#x1F1F2;&#x1F1FE;</span>,
  MV: <span className={styles.flag}>&#x1F1F2;&#x1F1FB;</span>,
  ML: <span className={styles.flag}>&#x1F1F2;&#x1F1F1;</span>,
  MT: <span className={styles.flag}>&#x1F1F2;&#x1F1F9;</span>,
  MH: <span className={styles.flag}>&#x1F1F2;&#x1F1ED;</span>,
  MQ: <span className={styles.flag}>&#x1F1F2;&#x1F1F6;</span>,
  MR: <span className={styles.flag}>&#x1F1F2;&#x1F1F7;</span>,
  MU: <span className={styles.flag}>&#x1F1F2;&#x1F1FA;</span>,
  YT: <span className={styles.flag}>&#x1F1FE;&#x1F1F9;</span>,
  MX: <span className={styles.flag}>&#x1F1F2;&#x1F1FD;</span>,
  FM: <span className={styles.flag}>&#x1F1EB;&#x1F1F2;</span>,
  MD: <span className={styles.flag}>&#x1F1F2;&#x1F1E9;</span>,
  MC: <span className={styles.flag}>&#x1F1F2;&#x1F1E8;</span>,
  MN: <span className={styles.flag}>&#x1F1F2;&#x1F1F3;</span>,
  ME: <span className={styles.flag}>&#x1F1F2;&#x1F1EA;</span>,
  MS: <span className={styles.flag}>&#x1F1F2;&#x1F1F8;</span>,
  MA: <span className={styles.flag}>&#x1F1F2;&#x1F1E6;</span>,
  MZ: <span className={styles.flag}>&#x1F1F2;&#x1F1FF;</span>,
  NA: <span className={styles.flag}>&#x1F1F3;&#x1F1E6;</span>,
  NR: <span className={styles.flag}>&#x1F1F3;&#x1F1F7;</span>,
  NP: <span className={styles.flag}>&#x1F1F3;&#x1F1F5;</span>,
  NL: <span className={styles.flag}>&#x1F1F3;&#x1F1F1;</span>,
  NC: <span className={styles.flag}>&#x1F1F3;&#x1F1E8;</span>,
  NZ: <span className={styles.flag}>&#x1F1F3;&#x1F1FF;</span>,
  NI: <span className={styles.flag}>&#x1F1F3;&#x1F1EE;</span>,
  NE: <span className={styles.flag}>&#x1F1F3;&#x1F1EA;</span>,
  NG: <span className={styles.flag}>&#x1F1F3;&#x1F1EC;</span>,
  NU: <span className={styles.flag}>&#x1F1F3;&#x1F1FA;</span>,
  NF: <span className={styles.flag}>&#x1F1F3;&#x1F1EB;</span>,
  NO: <span className={styles.flag}>&#x1F1F3;&#x1F1F4;</span>,
  OM: <span className={styles.flag}>&#x1F1F4;&#x1F1F2;</span>,
  PK: <span className={styles.flag}>&#x1F1F5;&#x1F1F0;</span>,
  PW: <span className={styles.flag}>&#x1F1F5;&#x1F1FC;</span>,
  PS: <span className={styles.flag}>&#x1F1F5;&#x1F1F8;</span>,
  PA: <span className={styles.flag}>&#x1F1F5;&#x1F1E6;</span>,
  PG: <span className={styles.flag}>&#x1F1F5;&#x1F1EC;</span>,
  PY: <span className={styles.flag}>&#x1F1F5;&#x1F1FE;</span>,
  PE: <span className={styles.flag}>&#x1F1F5;&#x1F1EA;</span>,
  PH: <span className={styles.flag}>&#x1F1F5;&#x1F1ED;</span>,
  PL: <span className={styles.flag}>&#x1F1F5;&#x1F1F1;</span>,
  PT: <span className={styles.flag}>&#x1F1F5;&#x1F1F9;</span>,
  PR: <span className={styles.flag}>&#x1F1F5;&#x1F1F7;</span>,
  QA: <span className={styles.flag}>&#x1F1F6;&#x1F1E6;</span>,
  RE: <span className={styles.flag}>&#x1F1F7;&#x1F1EA;</span>,
  RO: <span className={styles.flag}>&#x1F1F7;&#x1F1F4;</span>,
  RU: <span className={styles.flag}>&#x1F1F7;&#x1F1FA;</span>,
  RW: <span className={styles.flag}>&#x1F1F7;&#x1F1FC;</span>,
  WS: <span className={styles.flag}>&#x1F1FC;&#x1F1F8;</span>,
  SM: <span className={styles.flag}>&#x1F1F8;&#x1F1F2;</span>,
  ST: <span className={styles.flag}>&#x1F1F8;&#x1F1F9;</span>,
  SA: <span className={styles.flag}>&#x1F1F8;&#x1F1E6;</span>,
  SN: <span className={styles.flag}>&#x1F1F8;&#x1F1F3;</span>,
  CS: <span className={styles.flag}>&#x1F1F7;&#x1F1F8;</span>,
  SC: <span className={styles.flag}>&#x1F1F8;&#x1F1E8;</span>,
  SL: <span className={styles.flag}>&#x1F1F8;&#x1F1F1;</span>,
  SG: <span className={styles.flag}>&#x1F1F8;&#x1F1EC;</span>,
  SK: <span className={styles.flag}>&#x1F1F8;&#x1F1F0;</span>,
  SI: <span className={styles.flag}>&#x1F1F8;&#x1F1EE;</span>,
  SB: <span className={styles.flag}>&#x1F1F8;&#x1F1E7;</span>,
  SO: <span className={styles.flag}>&#x1F1F8;&#x1F1F4;</span>,
  ZA: <span className={styles.flag}>&#x1F1FF;&#x1F1E6;</span>,
  SS: <span className={styles.flag}>&#x1F1F8;&#x1F1F8;</span>,
  ES: <span className={styles.flag}>&#x1F1EA;&#x1F1F8;</span>,
  LK: <span className={styles.flag}>&#x1F1F1;&#x1F1F0;</span>,
  SH: <span className={styles.flag}>&#x1F1F8;&#x1F1ED;</span>,
  KN: <span className={styles.flag}>&#x1F1F0;&#x1F1F3;</span>,
  PM: <span className={styles.flag}>&#x1F1F5;&#x1F1F2;</span>,
  SD: <span className={styles.flag}>&#x1F1F8;&#x1F1E9;</span>,
  SR: <span className={styles.flag}>&#x1F1F8;&#x1F1F7;</span>,
  SE: <span className={styles.flag}>&#x1F1F8;&#x1F1EA;</span>,
  CH: <span className={styles.flag}>&#x1F1E8;&#x1F1ED;</span>,
  TW: <span className={styles.flag}>&#x1F1F9;&#x1F1FC;</span>,
  TJ: <span className={styles.flag}>&#x1F1F9;&#x1F1EF;</span>,
  TZ: <span className={styles.flag}>&#x1F1F9;&#x1F1FF;</span>,
  TH: <span className={styles.flag}>&#x1F1F9;&#x1F1ED;</span>,
  TG: <span className={styles.flag}>&#x1F1F9;&#x1F1EC;</span>,
  TK: <span className={styles.flag}>&#x1F1F9;&#x1F1F0;</span>,
  TO: <span className={styles.flag}>&#x1F1F9;&#x1F1F4;</span>,
  TT: <span className={styles.flag}>&#x1F1F9;&#x1F1F9;</span>,
  TN: <span className={styles.flag}>&#x1F1F9;&#x1F1F3;</span>,
  TR: <span className={styles.flag}>&#x1F1F9;&#x1F1F7;</span>,
  TM: <span className={styles.flag}>&#x1F1F9;&#x1F1F2;</span>,
  TC: <span className={styles.flag}>&#x1F1F9;&#x1F1E8;</span>,
  TV: <span className={styles.flag}>&#x1F1F9;&#x1F1FB;</span>,
  UG: <span className={styles.flag}>&#x1F1FA;&#x1F1EC;</span>,
  UA: <span className={styles.flag}>&#x1F1FA;&#x1F1E6;</span>,
  AE: <span className={styles.flag}>&#x1F1E6;&#x1F1EA;</span>,
  GB: <span className={styles.flag}>&#x1F1EC;&#x1F1E7;</span>,
  US: <span className={styles.flag}>&#x1F1FA;&#x1F1F8;</span>,
  UY: <span className={styles.flag}>&#x1F1FA;&#x1F1FE;</span>,
  UZ: <span className={styles.flag}>&#x1F1FA;&#x1F1FF;</span>,
  VU: <span className={styles.flag}>&#x1F1FB;&#x1F1FA;</span>,
  VA: <span className={styles.flag}>&#x1F1FB;&#x1F1E6;</span>,
  VE: <span className={styles.flag}>&#x1F1FB;&#x1F1EA;</span>,
  VN: <span className={styles.flag}>&#x1F1FB;&#x1F1F3;</span>,
  VG: <span className={styles.flag}>&#x1F1FB;&#x1F1EC;</span>,
  VI: <span className={styles.flag}>&#x1F1FB;&#x1F1EE;</span>,
  WF: <span className={styles.flag}>&#x1F1FC;&#x1F1EB;</span>,
  EH: <span className={styles.flag}>&#x1F1EA;&#x1F1ED;</span>,
  YE: <span className={styles.flag}>&#x1F1FE;&#x1F1EA;</span>,
  ZM: <span className={styles.flag}>&#x1F1FF;&#x1F1F2;</span>,
  ZW: <span className={styles.flag}>&#x1F1FF;&#x1F1FC;</span>,
};
