import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import { ApiResponse } from "../../../types/Data";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  // let timeRangeText = "in the past 6 months";
  let timeRangeText = "this year so far";
  if (searchParams.get("time_range") === "shortTerm") {
    timeRangeText = "in the last month";
  } else if (searchParams.get("time_range") === "longTerm") {
    timeRangeText = "since you started using Spotify";
  }

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold mb-2 animate-fadeInUp">
        Hi {comparisonData.me.name}!
      </div>
      <div className="max-w-md grid grid-cols-2 gap-x-1 mb-2">
        <div
          className={`col-start-1 col-end-2 flex flex-col items-center animate-fadeInRight`}
        >
          <div className="w-4/5">
            <ProfileImage
              url={comparisonData.friend.profileImage}
              round={true}
            />
          </div>
          <div className="text-sm md:text-base">
            {comparisonData.friend.name}
          </div>
        </div>
        <div
          className={`col-start-2 col-end-3 flex flex-col items-center animate-fadeInLeft`}
        >
          <div className="w-4/5">
            <ProfileImage url={comparisonData.me.profileImage} round={true} />
          </div>
          <div className="text-sm md:text-base">You</div>
        </div>
      </div>
      <div className="text-xl md:text-2xl animate-fadeInUp">
        Here's how the music you've listened to{" "}
        <span className="font-bold text-primary">{timeRangeText}</span> compares
        to {comparisonData.friend.name}'s
      </div>
    </div>
  );
}
