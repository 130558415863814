import React, { useState } from "react";
import "../App.css";
import * as Sentry from "@sentry/react";

export default function ErrorPopUp({
  error,
  onClose,
  reportError = false,
}: {
  error?: Error;
  onClose: () => void;
  reportError?: boolean;
}) {
  if (error) {
    if (reportError) {
      // Send error to Sentry
      Sentry.captureException(error);
    }

    return (
      <div className="fixed bottom-0 right-0 bg-alert m-8 p-2 rounded text-white text-xs md:text-sm flex gap-x-2">
        Sorry, something went wrong: {error?.message}
        <div className="text-white font-bold cursor-pointer" onClick={onClose}>
          &#10005;
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
