import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import Error from "./pages/Error";
import InAppBrowserWarning from "./InAppBrowserWarning ";

if (process.env.NODE_ENV !== "development") {
  const environment = "production"; // TODO: Set environment to "development" on preview builds
  Sentry.init({
    environment: environment ?? "development",
    dsn: "https://c753618f28cf4c89663aa6d455645e97@o4507568552542208.ingest.us.sentry.io/4507568554639360",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: environment === "development" ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Sentry.ErrorBoundary fallback={<Error />}>
    <InAppBrowserWarning />
    <App />
  </Sentry.ErrorBoundary>
);
