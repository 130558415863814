import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getUrl, getFriends, removeFriend } from "../helpers/friends";
import "../App.css";
import ProfileImage from "./ProfileImage";
import Flag from "./Flag";
import ShareModal from "./ShareModal";
import ErrorPopUp from "./ErrorPopUp";
import deleteIcon from "../images/delete.svg";
import closeIcon from "../images/close.svg";
import { getCookie } from "../helpers/helper";
import Modal from "./Modal";
import { ApiResponse } from "../../types/Data";
import TimeRangeSelector from "./TimeRangeSelector";

export default function Friends({
  userId,
  following,
  onUnfollow,
}: {
  userId: string;
  following?: ApiResponse.Friend[];
  onUnfollow: (friendId: string) => void;
}) {
  const [error, setError] = useState<Error | undefined>();

  const [shareUrl, setShareUrl] = useState<string | undefined>();
  const [friendsOptionsSelected, setFriendsOptionsSelected] = useState<
    string[]
  >([]);
  const [friendSelected, setFriendSelected] = useState<string | undefined>();
  const [deletingIds, setDeletingIds] = useState<string[]>([]);

  const URL = process.env.REACT_APP_URL;

  const onRemoveFriend = async (friendId: string) => {
    setDeletingIds((friendIds) => [...(friendIds ?? []), friendId]);
    try {
      await removeFriend(userId, friendId);
      onUnfollow(friendId);
    } catch (err) {
      setError(err as Error);
    }
    setDeletingIds((friendIds) => friendIds?.filter((id) => id !== friendId));
  };

  if (!following) {
    return <>Loading...</>;
  } else {
    return (
      <>
        <div className="flex flex-wrap items-stretch">
          {following.map((friend, index) => {
            return (
              <div key={index} className="w-1/3 md:w-1/4 lg:w-1/5 p-1">
                <div
                  className="w-full h-full animate-fadeInUp delay-entrance rounded-lg border border-background-300 bg-background-200 overflow-hidden"
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  {friendsOptionsSelected.includes(friend.id) && (
                    <div className="absolute w-full h-full animate-fadeIn bg-background-200 p-1 md:p-2 flex flex-col justify-between">
                      <div></div>
                      <div className="text-base md:text-lg">
                        Remove
                        <span className="text-primary">{` ${friend.name} `}</span>
                        as friend?
                      </div>
                      <div className="flex gap-x-1 animate-fadeInUp">
                        <button
                          className="w-full transition-all bg-alert text-white text-base md:text-lg p-2 rounded-lg overflow-hidden"
                          disabled={deletingIds.includes(friend.id)}
                          onClick={() => onRemoveFriend(friend.id)}
                        >
                          {deletingIds.includes(friend.id) ? "..." : "Yes"}
                        </button>
                        <button
                          className="w-full transition-all bg-background-300 md:hover:bg-active text-primary text-base md:text-lg p-2 rounded-lg overflow-hidden"
                          onClick={() =>
                            setFriendsOptionsSelected((friendIds) =>
                              friendIds?.filter((id) => id !== friend.id)
                            )
                          }
                        >
                          No
                        </button>
                      </div>
                    </div>
                  )}
                  <>
                    <img
                      className="w-4 fixed m-1 md:m-2 right-0 top-0 cursor-pointer"
                      src={
                        friendsOptionsSelected.includes(friend.id)
                          ? closeIcon
                          : deleteIcon
                      }
                      onClick={() =>
                        setFriendsOptionsSelected((friendIds) => {
                          if (friendIds?.includes(friend.id)) {
                            return friendIds?.filter((id) => id !== friend.id);
                          } else {
                            return [...(friendIds ?? []), friend.id];
                          }
                        })
                      }
                    />
                    <div className="w-full">
                      <ProfileImage
                        url={friend.profileImage}
                        userId={friend.id}
                      />
                    </div>
                    <div className="p-1 md:p-2">
                      <div className="text-base md:text-lg line-clamp-1 mb-1">
                        {`${friend.name} `}
                        <Flag countryCode={friend.country} />
                      </div>
                      <button
                        className="w-full transition-all bg-background-300 md:hover:bg-active text-primary text-base md:text-lg p-2 rounded-lg overflow-hidden"
                        onClick={() => {
                          setFriendSelected(friend.id);
                        }}
                      >
                        Compare
                      </button>
                    </div>
                  </>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          {following.length == 0 ? (
            <div className="mt-2 mx-auto text-base md:text-lg bold">
              Compare your Spotify data with friends:
            </div>
          ) : null}
          <button
            className="transition-all text-base md:text-lg bg-background-200 md:hover:bg-active rounded px-2 py-1 mb-1"
            onClick={() => {
              setShareUrl(`${URL}/invite/${userId}`);
            }}
          >
            Invite Friends
          </button>
        </div>
        <ShareModal
          url={shareUrl}
          onClose={() => {
            setShareUrl(undefined);
          }}
        ></ShareModal>
        <ErrorPopUp
          error={error}
          onClose={() => {
            setError(undefined);
          }}
        />
        <TimeRangeSelector
          open={!!friendSelected}
          onClose={() => {
            setFriendSelected(undefined);
          }}
          compare={true}
          friendId={friendSelected}
        />
      </>
    );
  }
}
