import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeButton from "../components/HomeButton";
import Groups from "../components/Groups";
import { getCookie } from "../helpers/helper";

export default function GroupsPage() {
  const userId = getCookie("userId");

  return (
    <>
      <div>
        <h1 className="text-2xl md:text-3xl py-4 font-bold text-center">
          Groups
        </h1>
      </div>

      <div className="w-full py-2 md:p-4 max-w-screen-md bg-background-100 rounded-lg">
        <Groups userId={userId} />
      </div>

      <HomeButton />
    </>
  );
}
