import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../helpers/fetchData";
import { getCookie } from "../helpers/helper";
import { getConcerts, mergeConcerts } from "../helpers/concerts";
import { logOut } from "../helpers/login";
import { getFriends } from "../helpers/friends";
import "../App.css";
import Friends from "../components/Friends";
import Followers from "../components/Followers";
import ConcertMap from "../components/ConcertMap";
import Groups from "../components/Groups";
import ProfileImage from "../components/ProfileImage";
import Flag from "../components/Flag";
import Error from "./Error";
import Loading from "../components/Loading";
import config from "../config";
import { ApiResponse } from "../../types/Data";
import DashboardButton from "../components/DashboardButton";

export default function Dashboard() {
  const [error, setError] = useState<Error>();
  const [user, setUser] = useState<ApiResponse.User>();
  const [timeRange, setTimeRange] = useState("mediumTerm");
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const [followers, setFollowers] = useState<ApiResponse.Friend[]>([]);
  const [following, setFollowing] = useState<ApiResponse.User[]>([]);
  const [concerts, setConcerts] = useState<ApiResponse.Concert>();

  const userId = getCookie("userId");

  useEffect(() => {
    async function fetchData(userId: string) {
      try {
        const accessToken = getCookie("access_token");
        const userData = await getUserData(userId);
        setUser(userData);
      } catch (err) {
        setError(err as Error);
      }
    }
    fetchData(userId);
  }, []);

  if (error) {
    return <Error error={error} />;
  } else if (user) {
    return (
      <>
        <div className="my-2 md:my-4">
          <div className="w-28 md:w-36 mx-auto">
            <ProfileImage url={user.profileImage} round={true} />
          </div>
          <div className="text-base md:text-lg font-bold">
            {`Hello, ${user.name} `}
            <Flag countryCode={user.country} />
          </div>
        </div>
        <div className="flex flex-wrap max-w-screen-md">
          <DashboardButton text="Your data" url="data" />
          <DashboardButton text="Concerts" url="concerts" />
          <DashboardButton text="Your profile" url={`user/${user.id}`} />
          <DashboardButton text="Search" url="search" />
          <DashboardButton text="Friends" url="friends" />
          <DashboardButton text="Groups" url="groups" />
        </div>
        <div className="text-sm w-80 max-w-full opacity-60 mt-10">
          If you found bugs or have suggestions, please contact me at:
          <a href="mailto:hi@compile.fm"> hi@compile.fm</a> 😊
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
