import React, { useState, useEffect, useRef } from "react";
import { getSpotifyRecommendations } from "../../helpers/fetchData";
import { checkAndRefreshTokens } from "../../helpers/tokens";
import { getCookie, getTopGenres } from "../../helpers/helper";
import { generateOrAddToPlaylist } from "../../helpers/playlist";
import "../../App.css";
import ErrorPopUp from "../ErrorPopUp";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";
import AlbumCover from "../AlbumCover";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const [error, setError] = useState<Error>();

  const [trackIndex, setTrackIndex] = useState<number>(); // index of the track opened in the modal
  const [playing, setPlaying] = useState<number>(0); // index of the track playing in the player
  const [recommendedTracks, setRecommendedTracks] = useState<
    SpotifyApi.RecommendationTrackObject[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false); // indicates whether the track is being added to the playlist
  const [addSuccessful, setAddSuccessful] = useState<boolean>(); // indicates whether the track was added to the playlist successfully

  const clickTrack = (index: number) => {
    if (index !== trackIndex) {
      setTrackIndex(index);
      setPlaying(index);
    }
  };

  async function getRecommendations() {
    try {
      const topTracks = summaryData.processed.topTracks;
      const topArtists = summaryData.processed.topArtists;
      const recommendations = await getSpotifyRecommendations(
        topArtists.map((artist) => artist.id).slice(0, 4),
        getTopGenres(topArtists).slice(0, 1),
        topTracks.map((track) => track.id).slice(0, 0),
        50
      );
      return recommendations;
    } catch (err) {
      setError(err as Error);
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        const recommendations = await getRecommendations();
        if (recommendations) {
          const recommendedTracks = recommendations.tracks
            .filter((track) => track.preview_url !== null)
            .slice(0, 28);
          setRecommendedTracks(recommendedTracks);
        }
      } catch (err) {
        setError(err as Error);
      }
    }
    getData();
  }, []);

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="animate-fadeInUp">
        <div className="text-2xl md:text-3xl font-bold mb-2">
          Songs you might like
        </div>
      </div>
      <div className={`max-w-lg flex justify-center flex-wrap`}>
        {recommendedTracks.length > 0 ? (
          recommendedTracks.map((track, index) => {
            return (
              <div
                key={index}
                className={`${
                  trackIndex === index
                    ? "fixed top-0 left-0 w-full h-full bg-black/75 z-10 flex items-center justify-center flex-col gap-y-2"
                    : "w-1/4"
                }`}
                style={{
                  padding: "2px",
                }}
                onClick={() => {
                  clickTrack(index);
                }}
              >
                <div
                  className={`cursor-pointer ${
                    trackIndex === index
                      ? "w-40 animate-zoomIn"
                      : "w-full animate-fadeInUp"
                  }`}
                >
                  <AlbumCover track={track} />
                </div>
                {trackIndex === index ? (
                  <div
                    className={`animate-fadeInUp text-lg md:text-xl font-bold`}
                  >
                    {track.name}
                  </div>
                ) : null}
                {trackIndex === index ? (
                  <div
                    className={`animate-fadeInUp delay-entrance text-base md:text-lg`}
                    style={{ animationDelay: "0.5s" }}
                  >
                    {track.artists[0].name}
                  </div>
                ) : null}
                {trackIndex === index ? (
                  <div
                    className={`animate-fadeInUp delay-entrance flex flex-col items-center justify-center`}
                    style={{ animationDelay: "1s" }}
                  >
                    <button
                      className="bg-primary text-white text-sm md:text-base rounded-full p-3 gap-x-2"
                      onClick={async () => {
                        setIsLoading(true);
                        await generateOrAddToPlaylist(
                          recommendedTracks[trackIndex].uri,
                          summaryData.id
                        )
                          .then((res) => {
                            setIsLoading(false);
                            if (!res.ok) {
                              throw new Error(res.statusText);
                            }
                            setAddSuccessful(true);
                            return res;
                          })
                          .catch((e) => {
                            setAddSuccessful(false);
                          });
                      }}
                    >
                      {isLoading ? "Adding..." : "Add to Spotify"}
                    </button>
                    <div
                      className={`transition-all text-xs md:text-sm ${
                        addSuccessful === undefined
                          ? "h-0 my-1"
                          : addSuccessful
                          ? "text-primary my-2"
                          : "text-alert my-2"
                      }`}
                    >
                      {addSuccessful === undefined
                        ? ""
                        : addSuccessful
                        ? "Added to Spotify!"
                        : "Something went wrong. Please try again."}
                    </div>
                  </div>
                ) : null}

                {trackIndex === index ? (
                  <div
                    className={`cursor-pointer border rounded-full border-white text-white w-10 h-10 leading-10`}
                    onClick={() => {
                      setTrackIndex(undefined);
                      setAddSuccessful(undefined);
                    }}
                  >
                    &#10005;
                  </div>
                ) : null}
              </div>
            );
          })
        ) : (
          <>Loading...</>
        )}
      </div>
      <TrackPlayer track={recommendedTracks[playing]} />
      <ErrorPopUp
        error={error}
        onClose={() => {
          setError(undefined);
        }}
      />
    </div>
  );
}
