import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { getComparisonData } from "../helpers/fetchData";
import { getCookie } from "../helpers/helper";
import "../App.css";

import ErrorPage from "./Error";
import Loading from "../components/Loading";
import NavigationSideBar from "../components/NavigationSideBar";
import NavigationButtons from "../components/NavigationButtons";
import ComparisonIntro from "../components/comparison/ComparisonIntro";
import ComparisonTopTrack from "../components/comparison/ComparisonTopTrack";
import ComparisonTopTracks1 from "../components/comparison/ComparisonTopTracks1";
import ComparisonTopTracks2 from "../components/comparison/ComparisonTopTracks2";
import ComparisonCommonTracks from "../components/comparison/ComparisonCommonTracks";
import ComparisonTopArtist from "../components/comparison/ComparisonTopArtist";
import ComparisonTopArtists from "../components/comparison/ComparisonTopArtists";
import ComparisonCommonArtists from "../components/comparison/ComparisonCommonArtists";
import ComparisonTopGenres from "../components/comparison/ComparisonTopGenres";
import ComparisonGenres from "../components/comparison/ComparisonGenres";
import ComparisonRecommendations from "../components/comparison/ComparisonRecommendations";
import ComparisonPopularityBars from "../components/comparison/ComparisonPopularityBars";
import ComparisonPopularity from "../components/comparison/ComparisonPopularity";
import ComparisonTimeline from "../components/comparison/ComparisonTimeline";
import ComparisonOldest from "../components/comparison/ComparisonOldest";
import { ApiResponse } from "../../types/Data";

const Comparison = () => {
  const [error, setError] = useState<Error>();

  const [pages, setPages] = useState<React.ComponentType<any>[]>([]);
  const [pageTitles, setPageTitles] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [comparisonData, setComparisonData] =
    useState<ApiResponse.ComparisonPageData>();
  const [searchParams] = useSearchParams();

  const { friendId } = useParams();
  const timeRange = searchParams.get("time_range") ?? "mediumTerm";

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const PageComponent = pages[currentPage];

  useEffect(() => {
    async function getData() {
      try {
        if (!friendId) {
          throw new Error("Friend ID not provided");
        }
        const comparisonData = await getComparisonData(
          getCookie("userId"),
          friendId,
          timeRange
        );
        const pagesShown = comparisonData.comparison.pagesShown;

        const pages = [
          pagesShown.intro ? ComparisonIntro : null,
          pagesShown.topTrack ? ComparisonTopTrack : null,
          pagesShown.topTracks1 ? ComparisonTopTracks1 : null,
          pagesShown.topTracks2 ? ComparisonTopTracks2 : null,
          pagesShown.commonTracks ? ComparisonCommonTracks : null,
          pagesShown.topArtists ? ComparisonTopArtist : null,
          pagesShown.topArtists ? ComparisonTopArtists : null,
          pagesShown.commonArtists ? ComparisonCommonArtists : null,
          pagesShown.topGenres ? ComparisonTopGenres : null,
          pagesShown.genres ? ComparisonGenres : null,
          pagesShown.recommendations ? ComparisonRecommendations : null,
          pagesShown.popularityBars ? ComparisonPopularityBars : null,
          pagesShown.popularity ? ComparisonPopularity : null,
          pagesShown.timeline ? ComparisonTimeline : null,
          pagesShown.oldest ? ComparisonOldest : null,
        ].filter((page) => page != null);

        const pageTitles = [
          pagesShown.intro ? "Intro" : null,
          pagesShown.topTrack ? "Top song" : null,
          pagesShown.topTracks1 ? "Top songs I" : null,
          pagesShown.topTracks2 ? "Top songs II" : null,
          pagesShown.commonTracks ? "Songs you both like" : null,
          pagesShown.topArtists ? "Top artist" : null,
          pagesShown.topArtists ? "Other top artists" : null,
          pagesShown.commonArtists ? "Artists you both like" : null,
          pagesShown.topGenres ? "Top genres" : null,
          pagesShown.genres ? "All genres" : null,
          pagesShown.recommendations ? "Recommendations" : null,
          pagesShown.popularityBars ? "Obscurity" : null,
          pagesShown.popularity ? "Least mainstream songs" : null,
          pagesShown.timeline ? "Timeline" : null,
          pagesShown.oldest ? "Release dates" : null,
        ].filter((page) => page != null);

        setComparisonData(comparisonData);
        setPages(pages as React.ComponentType<any>[]);
        setPageTitles(pageTitles as string[]);
      } catch (err) {
        setError(err as Error);
      }
    }
    getData();
  }, []);

  if (error) {
    return <ErrorPage error={error} />;
  } else if (comparisonData) {
    return (
      <div className="flex flex-col items-center">
        <NavigationSideBar
          pages={pageTitles}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
        <div className="fixed top-0 left-0 opacity-50 z-10 m-1 text-base md:text-lg">
          compile.fm
        </div>
        <PageComponent comparisonData={comparisonData} />
        <NavigationButtons
          exitPath={`/user/${friendId}`}
          index={currentPage}
          setIndex={setCurrentPage}
          numPages={pages.length}
        />
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Comparison;
