import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../../App.css";
import ProfileImage from "../ProfileImage";
import TrackPlayer from "../TrackPlayer";
import { getFriends, addFriend } from "../../helpers/friends";
import ErrorPopUp from "../ErrorPopUp";
import { ApiResponse } from "../../../types/Data";

export default function GroupFollow({
  userId,
  groupData,
}: {
  userId: string;
  groupData: ApiResponse.GroupPageData;
}) {
  const [error, setError] = useState<Error>();
  const [friendIds, setFriendIds] = useState<string[]>([]);
  const [loadingIds, setLoadingIds] = useState<string[]>();

  const onFollow = async (friendId: string) => {
    setLoadingIds((loadingIds) => [...(loadingIds ?? []), friendId]);
    try {
      await addFriend(userId, friendId);
      setFriendIds((friendIds) => [...friendIds, friendId]);
    } catch (err) {
      setError(err as Error);
    }
    setLoadingIds((loadingIds) => loadingIds?.filter((id) => id !== friendId));
  };

  useEffect(() => {
    getFriends(userId).then((data) => {
      setFriendIds(data.map((friend) => friend.id));
      setLoadingIds([]);
    });
  }, []);

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center gap-y-3">
      <div className="text-2xl md:text-3xl font-bold">
        Follow your group members for more detailed comparisons
      </div>

      <div className="flex flex-wrap gap-1 animate-fadeIn">
        {groupData.userIds
          .filter((friendId) => friendId !== userId)
          .map((friendId) => {
            return (
              <div
                key={friendId}
                className="flex flex-1 justify-between items-center gap-x-1 p-2 rounded-lg bg-background-100"
              >
                <a href={`/user/${friendId}`}>
                  <div className="flex gap-x-2 items-center">
                    <div className="w-12">
                      <ProfileImage
                        url={groupData.userInfo[friendId].profileImage}
                      />
                    </div>
                    <div className="overflow-hidden line-clamp-1 max-w-32">
                      {groupData.userInfo[friendId].name}
                    </div>
                  </div>
                </a>
                {loadingIds === undefined ? (
                  <div
                    className={`min-w-max px-2 py-3 text-white/75 text-sm md:text-base rounded-lg overflow-hidden`}
                  >
                    Loading...
                  </div>
                ) : loadingIds.includes(friendId) ? (
                  <button
                    className={`min-w-max px-2 py-3 transition-all bg-background-200 text-white/75 text-sm md:text-base rounded-lg overflow-hidden`}
                  >
                    Loading...
                  </button>
                ) : friendIds.includes(friendId) ? (
                  <button
                    disabled={true}
                    className={`min-w-max px-2 py-3 transition-all bg-background-200 text-white/75 text-sm md:text-base rounded-lg overflow-hidden`}
                  >
                    Following
                  </button>
                ) : (
                  <button
                    className={`min-w-max px-2 py-3 transition-all bg-background-200 md:hover:bg-active text-primary text-sm md:text-base rounded-lg overflow-hidden`}
                    onClick={() => onFollow(friendId)}
                  >
                    Follow
                  </button>
                )}
              </div>
            );
          })}
      </div>
      <TrackPlayer track={groupData.processed.randomTracks[2]} />
      <ErrorPopUp
        error={error}
        onClose={() => {
          setError(undefined);
        }}
      />
    </div>
  );
}
