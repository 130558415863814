import React, { useState } from "react";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";
import Modal from "./Modal";
import spotifyLogo from "../images/spotifyLogoWhite.png";
import { SpotifyApi } from "../../types/SpotifyApi";
import ArtistImage from "./ArtistImage";

export default function Artist({
  open,
  onClose,
  artist,
}: {
  open: boolean;
  onClose: () => void;
  artist?: SpotifyApi.ArtistObjectFull;
}) {
  const isMobileScreen = useCheckMobileScreen();
  if (artist) {
    return (
      <Modal open={open} onClose={onClose} hideCloseButton={true}>
        <div className="max-w-xs">
          <div className="flex flex-col items-center p-3">
            <div className="w-full sm:w-3/4 max-w-xs">
              <ArtistImage artist={artist} />
            </div>
            <div className="text-lg md:text-xl font-bold">{artist.name}</div>
          </div>
          <div className="mb-3">
            <span className="text-lg md:text-xl font-bold text-primary">
              {"Genres: "}
            </span>
            {artist.genres.map((genre, index) => {
              return (
                <span key={index} className="text-base md:text-lg">
                  {`${genre}${index < artist.genres.length - 1 ? ", " : "."}`}
                </span>
              );
            })}
          </div>
          <a href={artist.external_urls?.spotify} target="_blank">
            <button className="flex justify-center items-center mx-auto mt-1 bg-primary p-1 rounded-full">
              <img src={spotifyLogo} className="w-6 h-6"></img>
              <div className="my-auto mx-1 text-base md:text-lg">
                Open in Spotify
              </div>
            </button>
          </a>
        </div>
      </Modal>
    );
  } else {
    return <></>;
  }
}
