import React, { ReactNode, useEffect, useState } from "react";
import { isLoggedIn } from "../helpers/login";
import "../App.css";
import { Home, Settings, Menu, ChevronLeft } from "lucide-react";
import SideNav from "./SideNav";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";

export default function NavigationBar({ children }: { children: ReactNode }) {
  const loggedIn = isLoggedIn();
  const [isOpen, setIsOpen] = useState(false);
  const isMobileScreen = useCheckMobileScreen();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isMobileScreen || window.location.pathname === "/") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isMobileScreen]);

  const SettingsButton = () => {
    return (
      <a href="/settings">
        <div className="hover:text-primary">
          <svg className="w-6 h-6 fill-current">
            <path d="M24 14v-4c-1.619 0-2.906.267-3.705-1.476-.697-1.663.604-2.596 1.604-3.596l-2.829-2.828c-1.033 1.033-1.908 2.307-3.666 1.575-1.674-.686-1.404-2.334-1.404-3.675h-4c0 1.312.278 2.985-1.404 3.675-1.761.733-2.646-.553-3.667-1.574l-2.829 2.828c1.033 1.033 2.308 1.909 1.575 3.667-.348.849-1.176 1.404-2.094 1.404h-1.581v4c1.471 0 2.973-.281 3.704 1.475.698 1.661-.604 2.596-1.604 3.596l2.829 2.829c1-1 1.943-2.282 3.667-1.575 1.673.687 1.404 2.332 1.404 3.675h4c0-1.244-.276-2.967 1.475-3.704 1.645-.692 2.586.595 3.596 1.604l2.828-2.829c-1-1-2.301-1.933-1.604-3.595l.03-.072c.687-1.673 2.332-1.404 3.675-1.404zm-12 2c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
          </svg>
        </div>
      </a>
    );
  };

  const HomeButton = () => {
    return (
      <a href="/dashboard">
        <div className="hover:text-primary">
          <svg className="w-6 h-6 fill-current">
            <path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z" />
          </svg>
        </div>
      </a>
    );
  };

  const SideNavButton = () => {
    return (
      <div className="hover:text-primary" onClick={handleToggle}>
        {isOpen ? (
          <ChevronLeft className="w-6 h-6 mx-2" />
        ) : (
          <Menu className="w-6 h-6 mx-2" />
        )}
      </div>
    );
  };

  return (
    <div className="w-screen h-screen flex flex-col">
      <nav className="z-50 px-2 flex items-center justify-between bg-background-100 border-b-[1px] border-b-white/10 h-12 text-xs sm:text-base md:text-lg font-bold">
        <div className="flex items-center">
          <SideNavButton />
          <div className="text-xl -mt-1 select-none">compile.fm</div>
        </div>
        <HomeButton />
      </nav>
      <div className="flex flex-1 overflow-hidden">
        <SideNav isOpen={isOpen} isLoggedIn={loggedIn} />
        <section className="flex-1 overflow-y-auto flex flex-col items-center p-2 md:pt-4">
          {children}
        </section>
      </div>
    </div>
  );
}
