import React, { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import { getCookie } from "../helpers/helper";

const Search = () => {
  const userId = getCookie("userId");

  return (
    <>
      <div className="mt-4">
        <h1 className="text-2xl md:text-3xl py-4 font-bold text-center">
          Search
        </h1>
      </div>
      <div className="w-full lg:w-1/2 px-4 lg:px-0">
        <SearchBar userId={userId} />
      </div>
    </>
  );
};

export default Search;
