import React from "react";
import "../App.css";
import * as Sentry from "@sentry/react";

export default function Error({
  error,
  navigateTo,
  reportError = false,
}: {
  error?: Error;
  navigateTo?: string;
  reportError?: boolean;
}) {
  const handleButtonClick = () => {
    window.location.href = window.location.origin + "/" + (navigateTo ?? "");
  };

  if (error) {
    if (reportError) {
      // Send error to Sentry
      Sentry.captureException(error);
    }

    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center max-w-screen-md">
        <div className="text-2xl md:text-3xl font-bold mb-2">Oops!</div>
        <div className="text-sm md:text-base">Sorry, something went wrong</div>
        <div className="text-sm md:text-base">Please try again later</div>
        {process.env.NODE_ENV === "development" ? (
          <div className="text-sm md:text-base">{error?.message ?? ""}</div>
        ) : null}
        <button
          className="bg-primary text-white text-sm md:text-base rounded-full p-3 mt-3 flex items-center justify-center gap-x-2"
          onClick={handleButtonClick}
        >
          Go Back
        </button>
      </div>
    );
  } else {
    return <></>;
  }
}
