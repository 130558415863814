import React, { useEffect, useState } from "react";
import "../App.css";
import albumCover from "../images/albumCover.png";
import { SpotifyApi } from "../../types/SpotifyApi";

export default function AlbumCover({
  track,
}: {
  track?: SpotifyApi.TrackObjectFull | SpotifyApi.TrackObjectSimplified;
}) {
  const [src, setSrc] = useState<string | undefined>();

  useEffect(() => {
    const url =
      track && "album" in track && track.album.images
        ? track.album.images?.at(1)?.url
        : undefined;
    setSrc(url);
  }, [track]);

  return (
    <>
      {src ? (
        <img
          className="transition-all aspect-square object-cover min-w-full"
          src={src}
          onError={() => {
            setSrc(albumCover);
          }}
        ></img>
      ) : (
        <img
          className="transition-all aspect-square object-cover min-w-full bg-black"
          src={albumCover}
        ></img>
      )}
    </>
  );
}
