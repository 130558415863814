import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import UserIndicator from "../UserIndicator";
import TrackPlayer from "../TrackPlayer";
import Artist from "../Artist";
import { ApiResponse } from "../../../types/Data";
import { SpotifyApi } from "../../../types/SpotifyApi";
import ArtistImage from "../ArtistImage";

export default function ComparisonPage({
  comparisonData,
}: {
  comparisonData: ApiResponse.ComparisonPageData;
}) {
  const [artistSelected, setArtistSelected] =
    useState<SpotifyApi.ArtistObjectFull>();
  const [userIndex, setUserIndex] = useState(0);
  const [genreIndex, setGenreIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const friendData = comparisonData.friend;
  const myData = comparisonData.me;

  const clickGenre = (user: number, index: number) => {
    if (user !== userIndex || index !== genreIndex) {
      setUserIndex(user);
      setGenreIndex(index);
      setTrackIndex(
        user === 0
          ? Math.floor(
              Math.random() *
                friendData.genreTracks[friendData.topGenres[index]].length
            )
          : Math.floor(
              Math.random() * myData.genreTracks[myData.topGenres[index]].length
            )
      );
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold">
        Your favourite genres
      </div>
      <div className="w-full flex justify-evenly gap-x-1 animate-fadeInUp">
        <UserIndicator
          url={comparisonData.friend.profileImage}
          name={comparisonData.friend.name}
          playing={userIndex === 0}
        />
        <UserIndicator
          url={comparisonData.me.profileImage}
          name={"You"}
          playing={userIndex === 1}
        />
      </div>
      <div className="grid grid-cols-[1fr_auto_1fr] gap-x-1 gap-y-4">
        {friendData.topGenres.map((genre, index) => {
          return (
            <div
              key={index}
              className={`col-start-1 col-end-2 cursor-pointer transition-all animate-fadeInRight delay-entrance border-b-4 ${
                userIndex === 0 && genreIndex === index
                  ? "border-primary text-primary"
                  : "border-transparent text-white/75 md:hover:text-white"
              } flex flex-col justify-center`}
              onClick={() => clickGenre(0, index)}
              style={{
                animationDelay: `${index * 0.1}s`,
                gridRowStart: index + 1,
                gridRowEnd: index + 2,
              }}
            >
              <div
                className={`text-xl md:text-2xl lg:text-3xl font-bold line-clamp-2`}
              >
                {genre}
              </div>
              <div
                className={`animate-fadeInUp ${
                  genreIndex === index ? "flex justify-center" : "hidden"
                }`}
              >
                {friendData.genreArtists[genre]?.slice(0, 3).map((artist) => {
                  return (
                    <div key={artist.id} className="w-1/3">
                      <div
                        onClick={() =>
                          userIndex === 0 ? setArtistSelected(artist) : null
                        }
                      >
                        <ArtistImage artist={artist} />
                      </div>
                      <div
                        className={`text-xs md:text-sm text-white line-clamp-2`}
                      >
                        {artist.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {myData.topGenres.map((track, index) => {
          return (
            <div
              key={index}
              className={`col-start-2 col-end-3 text-2xl md:text-3xl font-bold transition-all my-auto ${
                genreIndex === index ? `text-primary` : "text-white"
              }`}
              style={{
                gridRowStart: index + 1,
                gridRowEnd: index + 2,
              }}
            >
              #{index + 1}
            </div>
          );
        })}
        {myData.topGenres.map((genre, index) => {
          return (
            <div
              key={index}
              className={`col-start-3 col-end-4 cursor-pointer transition-all animate-fadeInLeft delay-entrance border-b-4 ${
                userIndex === 1 && genreIndex === index
                  ? "border-primary text-primary"
                  : "border-transparent text-white/75 md:hover:text-white"
              } flex flex-col justify-center`}
              onClick={() => clickGenre(1, index)}
              style={{
                animationDelay: `${index * 0.1}s`,
                gridRowStart: index + 1,
                gridRowEnd: index + 2,
              }}
            >
              <div
                className={`text-xl md:text-2xl lg:text-3xl font-bold line-clamp-2`}
              >
                {genre}
              </div>
              <div
                className={`animate-fadeInUp ${
                  genreIndex === index ? "flex justify-center" : "hidden"
                }`}
              >
                {myData.genreArtists[genre]?.slice(0, 3).map((artist) => {
                  return (
                    <div key={artist.id} className="w-1/3">
                      <div
                        onClick={() =>
                          userIndex === 1 ? setArtistSelected(artist) : null
                        }
                      >
                        <ArtistImage artist={artist} />
                      </div>
                      <div
                        className={`text-xs md:text-sm text-white line-clamp-2`}
                      >
                        {artist.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <TrackPlayer
        track={
          userIndex === 0
            ? friendData.genreTracks[friendData.topGenres[genreIndex]]?.at(
                trackIndex
              )
            : myData.genreTracks[myData.topGenres[genreIndex]]?.at(trackIndex)
        }
      />
      <Artist
        open={artistSelected ? true : false}
        onClose={() => setArtistSelected(undefined)}
        artist={artistSelected}
      />
    </div>
  );
}
