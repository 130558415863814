import React, { useEffect, useState } from "react";

export default function InAppBrowserWarning() {
  const [isInAppBrowser, setIsInAppBrowser] = useState<boolean>(false);

  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    if (
      userAgent.includes("FBAN") ||
      userAgent.includes("FBAV") ||
      userAgent.includes("Instagram")
    ) {
      setIsInAppBrowser(true);
    }
  }, []);

  return (
    isInAppBrowser && (
      <div className="absolute bottom-0 p-3 z-50 bg-alert">
        Please open this link in your default browser for the best experience.
      </div>
    )
  );
}
