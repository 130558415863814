import React, { useEffect, useState } from "react";
import { addFriend, searchUsers } from "../helpers/friends";
import { ApiResponse } from "../../types/Data";
import ProfileImage from "./ProfileImage";
import Flag from "./Flag";

export default function SearchBar({ userId }: { userId: string }) {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [results, setResults] = useState<ApiResponse.Friend[]>([]);
  const [error, setError] = useState<Error | undefined>();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const onFollow = async (friendId: string) => {
    setLoadingIds((loadingIds) => [...(loadingIds ?? []), friendId]);
    try {
      await addFriend(userId, friendId);
      setResults((results) =>
        results?.map((result) =>
          result.id === friendId ? { ...result, followedByUser: true } : result
        )
      );
    } catch (err) {
      setError(err as Error);
    }
    setLoadingIds((loadingIds) => loadingIds?.filter((id) => id !== friendId));
  };

  useEffect(() => {
    // Search for users with debouncing
    const delayDebounceFn = setTimeout(() => {
      try {
        if (query) {
          setIsLoading(true);
          searchUsers(userId, query, pageNumber).then((data) => {
            setIsLoading(false);
            setNumPages(data.numPages);
            setResults(
              data.results.sort((a, b) => {
                const aValue = a.followedByUser ? 1 : 0;
                const bValue = b.followedByUser ? 1 : 0;
                return aValue - bValue;
              })
            );
          });
        } else {
          setResults([]);
        }
      } catch (err) {
        console.error(err);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [query, pageNumber]);

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setPageNumber(0);
  };

  return (
    <>
      <input
        type="text"
        value={query}
        onChange={handleOnInputChange}
        placeholder="Type to search..."
        className="w-full p-2 border text-black border-gray-300 rounded-full"
      />
      <div className="mt-6">
        {isLoading ? (
          <div className="my-2 text-base md:text-lg bold">Searching...</div>
        ) : results.length ? (
          <div className="bg-background-100 rounded-lg p-2 md:p-4">
            <div className="mb-1 text-base md:text-lg bold">Results:</div>
            {results.map((result, i) => {
              return (
                <div key={i} className="p-0.5 md:p-1 w-full">
                  <a href={`/user/${result.id}`}>
                    <div
                      className="p-1 flex items-center justify-between h-full animate-fadeInUp delay-entrance rounded-lg border border-background-300 bg-background-200 overflow-hidden"
                      style={{ animationDelay: `${i * 0.1}s` }}
                    >
                      <div className="grow flex items-center">
                        <div className="w-12">
                          <ProfileImage
                            url={result.profileImage}
                            round={true}
                          />
                        </div>
                        <div className="grow text-base md:text-lg line-clamp-1">
                          {`${result.name} `}
                          <Flag countryCode={result.country} />
                        </div>
                      </div>
                      {result.followedByUser ? (
                        <button
                          className="min-w-max px-2 py-3 transition-all bg-background-300 text-white text-sm md:text-base rounded-lg overflow-hidden"
                          disabled={true}
                        >
                          Following
                        </button>
                      ) : (
                        <>
                          {loadingIds.includes(result.id) ? (
                            <button
                              disabled={true}
                              className="min-w-max px-2 py-3 transition-all bg-background-300 text-sm md:text-base rounded-lg overflow-hidden"
                            >
                              ...
                            </button>
                          ) : (
                            <button
                              className="min-w-max px-2 py-3 transition-all bg-background-300 md:hover:bg-active text-primary text-sm md:text-base rounded-lg overflow-hidden"
                              onClick={(e) => {
                                e.preventDefault();
                                onFollow(result.id);
                              }}
                            >
                              Follow
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </a>
                </div>
              );
            })}
            {numPages > 1 && (
              <div className="flex justify-center flex-wrap mt-2">
                {Array.from({ length: numPages }, (_, i) => {
                  return (
                    <button
                      key={i}
                      className={`w-8 h-8 pb-0.5 mr-1 border border-background-300 rounded-full ${
                        pageNumber === i
                          ? "bg-primary"
                          : "hover:bg-background-200"
                      }`}
                      onClick={() => setPageNumber(i)}
                    >
                      {i + 1}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <div className="my-2 text-base md:text-lg bold">No results</div>
        )}
      </div>
    </>
  );
}
