import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCookie } from "../helpers/helper";
import { getSpotifyData } from "../helpers/fetchData";
import Error from "./Error";
import Loading from "../components/Loading";
import NavigationSideBar from "../components/NavigationSideBar";
import NavigationButtons from "../components/NavigationButtons";
import SummaryIntro from "../components/summary/SummaryIntro";
import SummaryTopTrack from "../components/summary/SummaryTopTrack";
import SummaryTopTracks1 from "../components/summary/SummaryTopTracks1";
import SummaryTopTracks2 from "../components/summary/SummaryTopTracks2";
import SummaryTopArtist from "../components/summary/SummaryTopArtist";
import SummaryTopArtists from "../components/summary/SummaryTopArtists";
import SummaryTopGenres from "../components/summary/SummaryTopGenres";
import SummaryGenres from "../components/summary/SummaryGenres";
import SummaryPopularity from "../components/summary/SummaryPopularity";
import SummaryRecommendations from "../components/summary/SummaryRecommendations";
import SummaryConclusion from "../components/summary/SummaryConclusion";
import SummaryTimeline from "../components/summary/SummaryTimeline";
import { ApiResponse } from "../../types/Data";

const Summary = () => {
  const { userId } = useParams();
  const [error, setError] = useState<Error>();

  const [pages, setPages] = useState<React.ComponentType<any>[]>([]);
  const [pageTitles, setPageTitles] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [summaryData, setSummaryData] = useState<ApiResponse.SummaryPageData>();
  const [searchParams] = useSearchParams();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const PageComponent = pages[currentPage];

  useEffect(() => {
    async function getData() {
      try {
        const timeRange = searchParams.get("time_range");
        const data = await getSpotifyData(
          getCookie("userId"),
          timeRange ?? "mediumTerm",
          userId
        );

        const pagesShown = data.processed.pagesShown;
        const pages = [
          pagesShown.intro ? SummaryIntro : null,
          pagesShown.topTrack ? SummaryTopTrack : null,
          pagesShown.topTracks1 ? SummaryTopTracks1 : null,
          pagesShown.topTracks2 ? SummaryTopTracks2 : null,
          pagesShown.topArtist ? SummaryTopArtist : null,
          pagesShown.topArtists ? SummaryTopArtists : null,
          pagesShown.topGenres ? SummaryTopGenres : null,
          pagesShown.genres ? SummaryGenres : null,
          pagesShown.popularity ? SummaryPopularity : null,
          pagesShown.recommendations ? SummaryRecommendations : null,
          pagesShown.timeline ? SummaryTimeline : null,
          pagesShown.conclusion ? SummaryConclusion : null,
        ].filter((page) => page != null);

        const pageTitles = [
          pagesShown.intro ? "Intro" : null,
          pagesShown.topTrack ? "Top song" : null,
          pagesShown.topTracks1 ? "Top songs I" : null,
          pagesShown.topTracks2 ? "Top songs II" : null,
          pagesShown.topArtist ? "Top artist" : null,
          pagesShown.topArtists ? "Other top artists" : null,
          pagesShown.topGenres ? "Top genres" : null,
          pagesShown.genres ? "All genres" : null,
          pagesShown.popularity ? "Obscurity" : null,
          pagesShown.recommendations ? "Recommendations" : null,
          pagesShown.timeline ? "Timeline" : null,
          pagesShown.conclusion ? "Summary" : null,
        ].filter((page) => page != null);

        setSummaryData(data);
        setPages(pages as React.ComponentType<any>[]);
        setPageTitles(pageTitles as string[]);
      } catch (err) {
        setError(err as Error);
      }
    }
    getData();
  }, []);

  if (error) {
    return <Error error={error} />;
  } else if (summaryData) {
    return (
      <div className="flex flex-col items-center">
        <NavigationSideBar
          pages={pageTitles}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
        <div className="fixed top-0 left-0 opacity-50 z-10 m-1 text-base md:text-lg">
          compile.fm
        </div>
        <PageComponent summaryData={summaryData} />
        <NavigationButtons
          exitPath={userId ? `/user/${userId}` : "/dashboard"}
          index={currentPage}
          setIndex={setCurrentPage}
          numPages={pages.length}
        />
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Summary;
