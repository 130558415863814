import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import QRCode from "react-qr-code";

export default function ShareModal({
  url,
  onClose,
}: {
  url?: string;
  onClose: () => void;
}) {
  const [linkCopied, setLinkCopied] = useState(false);

  const shareProfile = (url: string) => {
    navigator.clipboard.writeText(url);
    setLinkCopied(true);
  };

  if (url) {
    return (
      <Modal
        open={true}
        onClose={() => {
          setLinkCopied(false);
          onClose();
        }}
      >
        <>
          <div className="text-base md:text-lg font-bold">Share this link:</div>
          <div className="flex max-w-full">
            <input
              className="rounded-l-lg w-full text-base md:text-lg p-2 bg-background-100 text-white"
              value={url}
              readOnly
            ></input>
            <button
              className="bg-primary text-base md:text-lg text-white p-2 rounded-r-lg"
              onClick={() => shareProfile(url)}
            >
              Copy
            </button>
          </div>
          <div
            className={`transition-all text-primary text-base md:text-lg ${
              !linkCopied ? "opacity-0" : "opacity-1 my-2"
            }
      }`}
          >
            Link copied to clipboard!
          </div>
          <div className="text-base md:text-lg bold">Or scan this QR code:</div>
          <div>
            {url ? (
              <QRCode
                size={256}
                bgColor={"white"}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={url}
                viewBox={`0 0 256 256`}
              />
            ) : null}
          </div>
        </>
      </Modal>
    );
  } else {
    return <></>;
  }
}
