import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";
import AlbumCover from "../AlbumCover";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  const [yearIndex, setYearIndex] = useState(0);
  const [trackIndex, setTrackIndex] = useState(0);

  const timeline = summaryData.processed.timeline;

  const clickTrack = (year: number, track: number) => {
    if (year !== yearIndex || track !== trackIndex) {
      setYearIndex(year);
      setTrackIndex(track);
    }
  };

  const selectYear = (year: number) => {
    if (yearIndex !== year) {
      clickTrack(year, 0);
    }
  };

  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold">
        Your favourites from each era
      </div>

      <ol className="relative border-l border-white">
        {Object.keys(timeline)
          .sort()
          .map((year, i) => {
            return (
              <li key={i} onClick={() => selectYear(i)}>
                <div
                  className={`absolute rounded-full ${
                    yearIndex === i
                      ? "bg-primary w-3 h-3 mt-3 -left-1.5"
                      : "bg-white w-2 h-2 mt-3 -left-1"
                  }`}
                ></div>
                <div
                  className={`cursor-pointer transition-all animate-expand my-2 ${
                    yearIndex === i
                      ? "text-primary text-2xl md:text-3xl font-bold"
                      : "text-white/75 md:hover:text-white text-lg md:text-xl lg:text-2xl"
                  }`}
                >
                  {year}s
                </div>
                <div
                  className={`transition-all flex flex-wrap ${
                    yearIndex === i
                      ? "opacity-1 h-auto pointer-events-auto"
                      : "opacity-0 h-0 pointer-events-none"
                  }`}
                >
                  {timeline[year].map((track, j) => {
                    return (
                      <div
                        key={j}
                        onClick={() => clickTrack(i, j)}
                        className={`flex items-center gap-x-1 cursor-pointer p-2 rounded-lg ${
                          yearIndex === i && trackIndex === j
                            ? "bg-active md:animate-zoom"
                            : "md:hover:bg-background-200"
                        } w-1/2 md:w-1/3`}
                      >
                        <div className="w-1/2">
                          <AlbumCover track={track} />
                        </div>
                        <div className="w-1/2">
                          <div
                            className={`text-sm md:text-base font-bold line-clamp-2`}
                          >
                            {track.name}
                          </div>
                          <div
                            className={`text-xs md:text-sm font-thin line-clamp-1`}
                          >
                            {track.artists[0].name}
                          </div>
                          <div
                            className={`text-xs md:text-sm text-primary font-bold line-clamp-1`}
                          >
                            {track.album.release_date.split("-")[0]}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
      </ol>
      <TrackPlayer
        track={timeline[Object.keys(timeline)[yearIndex]][trackIndex]}
      />
    </div>
  );
}
