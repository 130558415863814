import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "../App.css";
import { getGroupData } from "../helpers/groups";
import { getCookie } from "../helpers/helper";
import ErrorPage from "./Error";
import Loading from "../components/Loading";
import NavigationSideBar from "../components/NavigationSideBar";
import NavigationButtons from "../components/NavigationButtons";
import GroupIntro from "../components/group/GroupIntro";
import GroupTopTrack from "../components/group/GroupTopTrack";
import GroupTopArtist from "../components/group/GroupTopArtist";
import GroupTopGenres from "../components/group/GroupTopGenres";
import GroupCommonTracks from "../components/group/GroupCommonTracks";
import GroupCommonArtists from "../components/group/GroupCommonArtists";
import GroupCommonGenres from "../components/group/GroupCommonGenres";
import GroupPopularity from "../components/group/GroupPopularity";
import GroupTimeline from "../components/group/GroupTimeline";
import GroupOldest from "../components/group/GroupOldest";
import GroupSimilarity from "../components/group/GroupSimilarity";
import GroupFollow from "../components/group/GroupFollow";
import { ApiResponse } from "../../types/Data";

const GroupInsight = () => {
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const [groupData, setGroupData] = useState<ApiResponse.GroupPageData>();
  const [error, setError] = useState<Error>();
  const [pages, setPages] = useState<React.ComponentType<any>[]>([]);
  const [pageTitles, setPageTitles] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const userId = getCookie("userId");
  const timeRange = searchParams.get("time_range") ?? "mediumTerm";

  useEffect(() => {
    async function getData() {
      try {
        if (!groupId) {
          throw new Error("Group ID not provided");
        }

        const groupData = await getGroupData(groupId, userId, timeRange);
        setGroupData(groupData);

        const pagesShown = groupData.processed.pagesShown;
        const pages = [
          pagesShown.intro ? GroupIntro : null,
          pagesShown.topTrack ? GroupTopTrack : null,
          pagesShown.topArtist ? GroupTopArtist : null,
          pagesShown.topGenres ? GroupTopGenres : null,
          pagesShown.commonTracks ? GroupCommonTracks : null,
          pagesShown.commonArtists ? GroupCommonArtists : null,
          pagesShown.commonGenres ? GroupCommonGenres : null,
          pagesShown.popularity ? GroupPopularity : null,
          pagesShown.timeline ? GroupTimeline : null,
          pagesShown.oldest ? GroupOldest : null,
          pagesShown.similarity ? GroupSimilarity : null,
          pagesShown.follow ? GroupFollow : null,
        ].filter((page) => page != null);
        const pageTitles = [
          pagesShown.intro ? "Intro" : null,
          pagesShown.topTrack ? "Top songs" : null,
          pagesShown.topArtist ? "Top artists" : null,
          pagesShown.topGenres ? "Top genres" : null,
          pagesShown.commonTracks ? "Songs in common" : null,
          pagesShown.commonArtists ? "Artists in common" : null,
          pagesShown.commonGenres ? "Genres in common" : null,
          pagesShown.popularity ? "Obscurity" : null,
          pagesShown.timeline ? "Timeline" : null,
          pagesShown.oldest ? "Release dates" : null,
          pagesShown.similarity ? "Similarity scores" : null,
          pagesShown.follow ? "Follow" : null,
        ].filter((title) => title != null);

        setPages(pages as React.ComponentType<any>[]);
        setPageTitles(pageTitles as string[]);
      } catch (err) {
        setError(err as Error);
      }
    }
    getData();
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const PageComponent = pages[currentPage];

  if (error) {
    return <ErrorPage error={error} />;
  } else if (!groupData) {
    return <Loading />;
  } else {
    return (
      <div className="flex flex-col items-center">
        <NavigationSideBar
          pages={pageTitles}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
        <div className="fixed top-0 left-0 opacity-50 z-10 m-1 text-base md:text-lg">
          compile.fm
        </div>
        <PageComponent groupData={groupData} userId={userId} />
        <NavigationButtons
          exitPath={`/groups`}
          index={currentPage}
          setIndex={setCurrentPage}
          numPages={pages.length}
        />
      </div>
    );
  }
};

export default GroupInsight;
