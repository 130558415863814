import React, { useEffect, useRef } from "react";
import "../../App.css";
import WordCloud from "../WordCloud";
import TrackPlayer from "../TrackPlayer";
import { ApiResponse } from "../../../types/Data";

export default function SummaryPage({
  summaryData,
}: {
  summaryData: ApiResponse.SummaryPageData;
}): JSX.Element {
  return (
    <div className="container md:max-w-screen-md px-2 mt-8 flex flex-col items-center">
      <div className="text-2xl md:text-3xl font-bold mb-4">
        Your genres at a glance
      </div>
      <div className="max-w-lg">
        <WordCloud genreScores={summaryData.processed.genreScores} />
      </div>
      <TrackPlayer track={summaryData.processed.randomTracks[3]} />
    </div>
  );
}
